import { LabelValueWithType } from "app/components/ReplyToConfiguration/types";
import {
    ExternalIntegrationType,
    GenericWebhook,
    IFynoIntegration,
    IMoEngageIntegration,
    IntegrationListType,
    IntegrationType,
    IntegrationTypeV2,
    IntegrationV2,
    IWebEngageIntegration,
    MiitelIntegration,
    PipedriveIntegration,
    ShipRocketIntegration,
    ShipwayIntegration,
    ZohoCRMIntegration,
} from "app/screens/Integration/types/integration";
import { WCAbandonedCart } from "app/screens/Integration/types/abandoncart";
import { CashFreeIntegration } from "app/screens/Integration/types/cashfree";
import { CleverTapIntegration } from "app/screens/Integration/types/cleverTap";
import { IFBLeadsIntegration } from "app/screens/Integration/types/fbLeads";
import { LeadSquaredIntegration } from "app/screens/Integration/types/leadsquared";
import { IRazorPay } from "app/screens/Integration/types/razorPay";
import { IShopifyIntegration } from "app/screens/Integration/types/shopify";
import { IWooCommerceIntegration } from "app/screens/Integration/types/woocommerce";
import { WCCartFlow } from "app/screens/Integration/types/woocommerceCartFlow";
import { ZohoBooksIntegration } from "app/screens/Integration/types/zohoBooks";
import abandonedCartLogo from "assets/abandonedCart.png";
import fbImg from "assets/fblogo.svg";
import GoogleSheet from "assets/Google-Sheets.svg";
import Calendly from "assets/Integration/Calendly.svg";
import CashFree from "assets/Integration/Cashfree.svg";
import CleverTap from "assets/Integration/cleverTapLogo.png";
import zohoImg from "assets/Integration/CRM.svg";
import FynoLogo from "assets/Integration/fyno.svg";
import hubspotImg from "assets/Integration/Hubspot.svg";
import Kylas from "assets/Integration/kylas.svg";
import LeadSquared from "assets/Integration/leadsquared.svg";
import MiitelLogo from "assets/Integration/miitel_icon.svg";
import Pabbly from "assets/Integration/Pabbly.svg";
import pipedriveLogo from "assets/Integration/pipedriveLogo.svg";
import corefactorLogo from "assets/Integration/corefactors.svg";
import RazorPay from "assets/Integration/razorpay.svg";
import SangamCrmLogo from "assets/Integration/sangam_crmLogo.svg";
import ShopfloLogo from "assets/Integration/shopfloLogo.svg";
import StripeLogo from "assets/Integration/stripeLogo.svg";
import WebHook from "assets/Integration/webhooks.svg";
import MoEngageLogo from "assets/MoEngage.png";
import shiprocket from "assets/shipRocketIcon.png";
import ShipWay from "assets/Shipway.png";
import shopifyImg from "assets/shopifyIcon.svg";
import WebEngageLogo from "assets/WebEngageLogo.png";
import wooCommerceLogo from "assets/woocommerceLogo.svg";
import GBBotImg from "assets/gb-bot-img.svg";
import Zapier from "assets/Zapier.svg";
import ZohoBooks from "assets/zohoImg.png";
import { NODE_ENV } from "environment";
import { checkIfTestingAccount } from "app/utils/validation";
import { ICalendlyIntegration } from "./types/integration";
import { StripeIntegration } from "app/screens/Integration/types/stripe";
import { ShopfloIntegration } from "app/screens/Integration/types/shopFlo";
import LedsakLogo from "assets/Integration/ledsak.png";

export type ListIntegrationType = IntegrationType | ExternalIntegrationType;

export interface IntegrationListV2Type {
    image: string;
    title: string;
    tags: string[];
    isNew?: boolean;
    description: string;
    linkURL?: string;
    name?: IntegrationType;
    helpURL?: string;
    privacyPolicyURL?: string;
    paid?: string;
}

export const integrationList: Map<ListIntegrationType, IntegrationListType> = new Map([
    [
        "fbleads",
        {
            image: fbImg,
            title: "Facebook Leads",
        },
    ],
    [
        "shipway",
        {
            image: ShipWay,
            title: "ShipWay",
        },
    ],
    [
        "razorpay",
        {
            image: RazorPay,
            title: "RazorPay",
        },
    ],
    [
        "zoho_widget",
        {
            image: zohoImg,
            title: "Zoho",
            linkURL: "https://marketplace.zoho.com/app/crm/whatsapp-business-messaging-for-zoho-crm",
        },
    ],
    [
        "shopify",
        {
            image: shopifyImg,
            title: "Shopify",
        },
    ],
    [
        "woocommerce",
        {
            image: wooCommerceLogo,
            title: "WooCommerce",
        },
    ],
    ["shiprocket", { image: shiprocket, title: "ShipRocket" }],
    [
        "zapier",
        {
            image: Zapier,
            title: "Zapier",
            linkURL: "https://zapier.com/developer/public-invite/167332/0eb4442cc73e284133df2be20df48882/",
        },
    ],
    [
        "pabbly",
        {
            image: Pabbly,
            title: "Pabbly",
            linkURL: "https://www.pabbly.com/connect/integrations/gallabox/",
        },
    ],
    [
        "hubspot",
        {
            image: hubspotImg,
            title: "HubSpot",
        },
    ],
    [
        "leadsquared",
        {
            image: LeadSquared,
            title: "LeadSquared",
        },
    ],

    [
        "webengage",
        {
            image: WebEngageLogo,
            title: "WebEngage",
        },
    ],
    [
        "moengage",
        {
            image: MoEngageLogo,
            title: "MoEngage",
        },
    ],
    [
        "kylas",
        {
            image: Kylas,
            linkURL: "https://app.kylas.io/marketplace/all-apps/app-details/30bab16d-70fc-43ce-a5bc-75a9183deb2",
            title: "Kylas",
        },
    ],
    [
        "gsheet",
        {
            image: GoogleSheet,
            linkURL: "https://docs.google.com/spreadsheets/d/1K9EyGb0gWPy8E1GGVXsN82jWOYJ19epqv-jP_PyvyVc/copy",
            title: "Google Sheets",
        },
    ],
    [
        "sangam_crm",
        {
            image: SangamCrmLogo,
            linkURL: "https://sangamcrm.com/crm-whatsapp-integration/",
            title: "Sangam CRM",
        },
    ],
    [
        "genericWebhook",
        {
            image: WebHook,
            title: "Generic Webhook",
        },
    ],
    [
        "cashfree",
        {
            image: CashFree,
            title: "Cashfree",
        },
    ],
    // [
    //     "woocommerce_cartflow",
    //     {
    //         image: abandonedCartLogo,
    //         title: "Cartflow",
    //     },
    // ],
    // [
    //     "woocommerce_abandoned_cart",
    //     {
    //         image: abandonedCartLogo,
    //         title: "Abandoned Cart",
    //     },
    // ],
    [
        "cleverTap",
        {
            image: CleverTap,
            title: "CleverTap",
        },
    ],
    [
        "stripe",
        {
            image: StripeLogo,
            title: "Stripe",
        },
    ],
    [
        "shopflo",
        {
            image: ShopfloLogo,
            title: "Shopflo",
        },
    ],
]);

export const integrationListV2: IntegrationListV2Type[] = [
    {
        image: fbImg,
        title: "Facebook Leads",
        description: "Engage with your leads on WhatsApp and send automated follow-up messages.",
        tags: ["Automation", "CRM"],
        name: "fbleads",
        helpURL: "https://docs.gallabox.com/integration/marketing-and-engagement-platforms/facebook-leads",
        privacyPolicyURL: "https://gallabox.com/privacy-policy/#third-party-user-data-use",
    },
    {
        image: ShipWay,
        title: "Shipway",
        description: "Send Order updates to your customer on WhatsApp for better experience.",
        tags: ["E-Commerce", "Logistics"],
        name: "shipway",
        helpURL: "https://docs.gallabox.com/integration/shipping-and-logistics/shipway",
    },
    {
        image: Calendly,
        title: "Calendly",
        description: "Engage with your leads on WhatsApp and send automated follow-up messages.",
        tags: ["Automation", "CRM"],
        name: "calendly",
        helpURL: "https://docs.gallabox.com/integration/other-integrations/calendly",
    },

    {
        image: CashFree,
        title: "Cashfree",
        description: "Send Payment notifications and subscription updates to drive quick payments",
        tags: ["Payment"],
        name: "cashfree",
        helpURL: "https://docs.gallabox.com/integration/payment-gateways/cashfree",
    },

    {
        image: WebHook,
        title: "Generic Webhook",
        description: "Trigger WhatsApp messages from an external system using webhook.",
        tags: ["Automation", "CRM"],
        name: "genericWebhook",
        helpURL: "https://docs.gallabox.com/integration/other-integrations/generic-webhooks",
    },

    {
        image: GoogleSheet,
        linkURL: "https://docs.google.com/spreadsheets/d/1K9EyGb0gWPy8E1GGVXsN82jWOYJ19epqv-jP_PyvyVc/copy",
        title: "Google Sheets",
        description: "Send promotional messages to contacts on your Google Sheets.",
        tags: ["Automation"],
        helpURL: "https://docs.gallabox.com/integration/other-integrations/google-sheets",
    },

    {
        image: hubspotImg,
        title: "HubSpot",
        description: "Engage with your leads and customers on WhatsApp through Hubspot CRM seamlessly",
        tags: ["Automation", "CRM"],
        name: "hubspot",
        helpURL: "https://docs.gallabox.com/integration/customer-relationship-management-crm-systems/hubspot",
    },

    {
        image: Kylas,
        linkURL: "https://app.kylas.io/marketplace/all-apps/app-details/30bab16d-70fc-43ce-a5bc-75a9183deb2",
        title: "Kylas",
        description: "Engage with your leads and customers on WhatsApp through Kylas CRM seamlessly",
        tags: ["Automation", "CRM"],
        helpURL: "https://docs.gallabox.com/integration/customer-relationship-management-crm-systems/kylas",
    },
    {
        image: SangamCrmLogo,
        linkURL: "https://sangamcrm.com/crm-whatsapp-integration/",
        title: "Sangam CRM",
        description: "Engage with your leads and customers on WhatsApp through Sangam CRM seamlessly",
        tags: ["Automation", "CRM"],
    },

    {
        image: LeadSquared,
        title: "LeadSquared",
        description: "Engage with your leads and customers on WhatsApp through LeadSquare CRM seamlessly",
        tags: ["Automation", "CRM"],
        name: "leadsquared",
        helpURL: "https://docs.gallabox.com/integration/customer-relationship-management-crm-systems/leadsquared",
    },

    {
        image: Pabbly,
        title: "Pabbly",
        linkURL: "https://www.pabbly.com/connect/integrations/gallabox/",
        description: "Connect your WhatsApp number with thousands of applications through Zapier",
        tags: ["Automation"],
        helpURL: "https://docs.gallabox.com/integration/other-integrations/pabbly",
    },

    {
        image: RazorPay,
        title: "Razorpay",
        description: "Send Payment notifications and subscription updates to drive quick payments",
        tags: ["Payment"],
        name: "razorpay",
        helpURL: "https://docs.gallabox.com/integration/payment-gateways/razorpay",
    },
    {
        image: MoEngageLogo,
        title: "MoEngage",
        description: "Engage your MoEngage contacts by sending personalized WhatsApp messages",
        tags: ["Automation"],
        name: "moengage",
        helpURL: "https://docs.gallabox.com/integration/marketing-and-engagement-platforms/moengage",
    },

    {
        image: shiprocket,
        title: "Shiprocket",
        description: "Send Order updates to your customer on WhatsApp for better experience",
        tags: ["E-Commerce", "Logistics"],
        name: "shiprocket",
        helpURL: "https://docs.gallabox.com/integration/shipping-and-logistics/shiprocket",
    },

    {
        image: shopifyImg,
        title: "Shopify",
        description: "Send Order notifications to your customers and also boost cart recovery",
        tags: ["E-Commerce"],
        name: "shopify",
        helpURL: "https://docs.gallabox.com/integration/e-commerce-platforms/shopify",
        paid: "$5/month",
    },

    {
        image: WebEngageLogo,
        title: "WebEngage",
        description: "Engage with your leads and customers on WhatsApp through WebEngage CRM seamlessly",
        tags: ["Automation", "CRM"],
        name: "webengage",
        helpURL: "https://docs.gallabox.com/integration/marketing-and-engagement-platforms/webengage",
    },

    {
        image: wooCommerceLogo,
        title: "WooCommerce",
        description: "Engage your customers on WhatsApp for notifications, promotions and cart recovery",
        tags: ["E-Commerce"],
        name: "woocommerce",
        helpURL: "https://docs.gallabox.com/integration/e-commerce-platforms/woocommerce",
    },

    {
        image: Zapier,
        title: "Zapier",
        linkURL: "https://zapier.com/developer/public-invite/167332/0eb4442cc73e284133df2be20df48882/",
        description: "Connect your WhatsApp number with thousands of applications through Zapier",
        tags: ["Automation"],
        helpURL: "https://docs.gallabox.com/integration/other-integrations/zapier",
    },

    // {
    //     image: zohoImg,
    //     title: "Zoho CRM",
    //     linkURL: "https://marketplace.zoho.com/app/crm/whatsapp-business-messaging-for-zoho-crm",
    //     description: "Engage with your leads and customers on whatsapp through ZOHO CRM seamlessly",
    //     tags: ["Automation", "CRM"],
    //     helpURL: "https://docs.gallabox.com/integration/customer-relationship-management-crm-systems/zoho",
    // },
    {
        image: abandonedCartLogo,
        title: "CartFlow",
        description: "Revive abandoned shopping carts with WhatsApp nudges – re-engage customers effortlessly.",
        tags: ["E-Commerce"],
        helpURL: "https://docs.gallabox.com/integration/e-commerce-platforms/cart-flow",
        name: "woocommerce_cartflow",
    },
    {
        image: zohoImg,
        title: "Zoho CRM & Signals",
        description: "Receive a notification right in Zoho CRM and never miss important WhatsApp messages again.",
        tags: ["Automation", "CRM"],
        helpURL: "https://docs.gallabox.com/integration/customer-relationship-management-crm-systems/zoho",
        name: "zohoCRM",
    },
    {
        image: ShopfloLogo,
        title: "Shopflo",
        description: "Integrate Shopflo with your WhatsApp Business number and recover abandoned carts quicker!",
        tags: ["Automation", "CRM"],
        // helpURL: "https://docs.gallabox.com/integration/other-integrations/zoho-signals",
        name: "shopflo",
    },
    {
        image: abandonedCartLogo,
        title: "Abandoned Cart",
        description: "Revive abandoned shopping carts with WhatsApp nudges – re-engage customers effortlessly.",
        tags: ["E-Commerce"],
        helpURL: "https://docs.gallabox.com/integration/e-commerce-platforms/cart-flow",
        name: "woocommerce_abandoned_cart",
    },
    {
        image: ZohoBooks,
        title: "Zoho Books",
        helpURL: "https://docs.gallabox.com/integrations/zoho-books",
        description: "Engage with your leads and customers on whatsapp through ZOHO CRM seamlessly",
        tags: ["Automation", "CRM"],
        name: "zohobooks",
    },
    {
        image: CleverTap,
        title: "CleverTap",
        description: "Engage with your leads and customers on WhatsApp through CleverTap seamlessly",
        tags: ["Automation", "CRM"],
        name: "cleverTap",
        helpURL: "https://docs.gallabox.com/integration/marketing-and-engagement-platforms/clevertap",
    },
    {
        image: StripeLogo,
        title: "Stripe",
        description: "Engage with your leads and customers on WhatsApp through Stripe seamlessly",
        tags: ["Automation", "CRM"],
        name: "stripe",
        helpURL: "https://docs.gallabox.com/integration/payment-gateways/stripe",
    },
    {
        image: MiitelLogo,
        title: "Miitel",
        description:
            "Integrating MiiTel with WhatsApp allows voice calls to customers, ensuring calls are logged, recorded, and analyzed for better engagement.",
        tags: ["Automation", "CRM"],
        name: "miitel",
        helpURL: "https://docs.gallabox.com/integration/other-integrations/miitel",
    },
    {
        image: FynoLogo,
        title: "Fyno",
        description: "Integrate Fyno with your WhatsApp Business and send customized template messages.",
        tags: ["Automation"],
        name: "fyno",
        helpURL:
            "https://docs.gallabox.com/integration/marketing-and-engagement-platforms/fyno#connect-fyno-in-gallabox",
    },
    {
        image: pipedriveLogo,
        title: "Pipedrive",
        description: "Engage with your deals, leads and customers on WhatsApp through Pipedrive CRM seamlessly",
        tags: ["Automation", "CRM"],
        name: "pipedrive",
        helpURL: "https://docs.gallabox.com/integration/customer-relationship-management-crm-systems/pipedrive",
    },
    {
        image: corefactorLogo,
        title: "Corefactors",
        description: "Efficiently manage conversations with prospects and customers directly from the Corefactors CRM.",
        linkURL: "https://www.corefactors.in/integrations/gallabox/",
        tags: ["CRM"],
        helpURL: "https://www.corefactors.in/blog/how-to-integrate-whatsapp-with-corefactors-crm/",
    },
    {
        image: GBBotImg,
        title: "Chat widget",
        description: "Generate a WhatsApp Chat Widget for your website and landing pages.",
        name: "chat-widget",
        tags: [],
        helpURL: "https://docs.gallabox.com/whatsapp-channel/widget-for-your-website",
    },
    {
        image: LedsakLogo,
        title: "LEDSAK",
        linkURL: "https://account.ledsak.ai/signup?source=Gallabox",
        description:
            "Engage, nurture, and convert your leads effortlessly on WhatsApp through LEDSAK's powerful automation",
        tags: ["CRM"],
        helpURL: "https://ledsak.gitbook.io/docs-for-ledsak/integrations/gallabox",
    },
];

export const sortedIntegrationListV2 = integrationListV2.sort((a, b) => a.title.localeCompare(b.title));

export const integrationCategoryOptions = [
    "All",
    "Payment",
    "CRM",
    "Logistics",
    "E-Commerce",
    "Automation",
    "Analytics",
];

export const labelValueArray = (integrationItem: IntegrationV2[]): LabelValueWithType[] | undefined => {
    return integrationItem?.map((c: IntegrationV2) => {
        const integrationType = c.type as IntegrationTypeV2;
        const integrationDetails = c[integrationType];
        let brandName: string | undefined;
        let integrationId: string | undefined;

        switch (integrationType) {
            case IntegrationTypeV2.Shopify:
                brandName = (integrationDetails as IShopifyIntegration)?.brandName || "Shopify";
                integrationId = c.id;
                break;
            case IntegrationTypeV2.WooCommerce:
                brandName = (integrationDetails as IWooCommerceIntegration)?.brandName || "WooCommerce";
                integrationId = c.id;
            case IntegrationTypeV2.LeadSquared:
                brandName = (integrationDetails as LeadSquaredIntegration)?.brandName || "LeadSquared Integration";
                integrationId = c.id;
                break;
            case IntegrationTypeV2.CleverTap:
                brandName = (integrationDetails as CleverTapIntegration)?.integrationName || "CleverTap Integration";
                integrationId = c.id;
                break;
            case IntegrationTypeV2.WebEngage:
                brandName = (integrationDetails as IWebEngageIntegration)?.name;
                integrationId = c.id;
                break;
            case IntegrationTypeV2.HubSpot:
                brandName = "HubSpot";
                integrationId = c.id;
                break;
            case IntegrationTypeV2.FBLeads:
                brandName = (integrationDetails as IFBLeadsIntegration)?.brandName;
                integrationId = c.id;
                break;
            case IntegrationTypeV2.CashFree:
                brandName = (integrationDetails as CashFreeIntegration)?.brandName;
                integrationId = c.id;
                break;
            case IntegrationTypeV2.RazorPay:
                brandName = (integrationDetails as IRazorPay)?.brandName;
                integrationId = c.id;
                break;
            case IntegrationTypeV2.ShipRocket:
                brandName = (integrationDetails as ShipRocketIntegration)?.brandName;
                integrationId = c.id;
                break;
            case IntegrationTypeV2.GenericWebhook:
                brandName = (integrationDetails as GenericWebhook)?.brandName;
                integrationId = c.id;
                break;
            case IntegrationTypeV2.Shipway:
                brandName = (integrationDetails as ShipwayIntegration)?.brandName;
                integrationId = c.id;
                break;

            case IntegrationTypeV2.zohoBooks:
                brandName = (integrationDetails as ZohoBooksIntegration)?.integrationName || "Zoho Books Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.Calendly:
                brandName = (integrationDetails as ICalendlyIntegration)?.integrationName;
                integrationId = c.id;
                break;

            case IntegrationTypeV2.woocommerce_abandoned_cart:
                brandName = (integrationDetails as WCAbandonedCart)?.integrationName || "Unknown Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.woocommerce_cartflow:
                brandName = (integrationDetails as WCCartFlow)?.brandName || "Unknown Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.Stripe:
                brandName = (integrationDetails as StripeIntegration)?.integrationName || "Stripe Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.Moengage:
                brandName = (integrationDetails as IMoEngageIntegration)?.brandName || "Moengage Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.Fyno:
                brandName = (integrationDetails as IFynoIntegration)?.brandName || "Fyno Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.Shopflo:
                brandName = (integrationDetails as ShopfloIntegration)?.integrationName || "Shopflo Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.zohoCRM:
                brandName = (integrationDetails as ZohoCRMIntegration)?.integrationName || "Zoho signal Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.Miitel:
                brandName = (integrationDetails as MiitelIntegration)?.company || "Miitel Integration";
                break;

            case IntegrationTypeV2.pipedrive:
                brandName = (integrationDetails as PipedriveIntegration)?.name || "Pipedrive Integration";
                integrationId = c.id;
                break;

            default:
                brandName = "Unknown Integration";
                integrationId = c.id;
                break;
        }

        return { label: brandName as string, value: integrationId as string, type: integrationType };
    });
};

export const filterIntegrationListForProd = (accountId: string) => {
    const isTestingAccount = checkIfTestingAccount(accountId);
    const isProduction = NODE_ENV === "production";
    if (isProduction && !isTestingAccount) {
        return sortedIntegrationListV2.filter((integration: IntegrationListV2Type) => integration.name !== "pipedrive");
    }
    return sortedIntegrationListV2;
};
