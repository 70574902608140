import {
    Box,
    ButtonProps,
    Divider,
    HStack,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    UseDisclosureProps,
    VStack,
} from "@chakra-ui/react";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { callFnsInSequence } from "app/utils/common";
import React from "react";
import { NavigationItem } from "../types";
import { appLayoutStyles } from "../utils";
import NavigationBadge from "./NavigationBadge";
import NavigationButton from "./NavigationButton";

const buttonStyle: ButtonProps = {
    size: "sm",
    variant: "ghost",
    w: "full",
    maxW: "full",
    fontSize: "2xs",
    _hover: { bg: "none" },
    _active: { bg: "none" },
    fontWeight: "normal",
    rounded: "none",
    color: "gray.600",
};

interface PopoverNavigationItemProps {
    navigationItem: NavigationItem;
    isExpanded: boolean;
    onPopoverItemClick?: () => void;
    children: (props: Partial<UseDisclosureProps>) => JSX.Element;
}
const PopoverNavigationItem: React.FC<PopoverNavigationItemProps> = (props) => {
    const { navigationItem, isExpanded, onPopoverItemClick } = props;

    if (!props.children) return null;

    if (navigationItem.type !== "sub-navigation" || navigationItem.subNavigation.type !== "popover" || !isExpanded) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return props.children({}) as React.ReactElement<any, any>;
    }

    const subNav = navigationItem.subNavigation.children;

    return (
        <Popover trigger="click" placement="right" isLazy>
            {(popoverProps) => {
                const { onClose } = popoverProps;
                return (
                    <>
                        <PopoverTrigger>
                            <Box w="full">{props.children(popoverProps)}</Box>
                        </PopoverTrigger>
                        <PopoverContent maxW="min-content" pos="relative" left="-4px">
                            <PopoverArrow />
                            <PopoverBody boxShadow="base" p="2">
                                <VStack spacing={0} minW="28" w="full">
                                    <Text fontSize="0.625rem" color="gray.400" alignSelf="start" pl={2}>
                                        {navigationItem?.title}
                                    </Text>
                                    {subNav?.map((group, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {group.children.map((subNavigation, index) => {
                                                    if (subNavigation.type === "divider") {
                                                        return (
                                                            <Divider
                                                                alignSelf="center"
                                                                key={`divider-${index}`}
                                                                borderColor="gray.300"
                                                            />
                                                        );
                                                    }
                                                    return (
                                                        <HStack
                                                            key={index}
                                                            w="full"
                                                            _hover={appLayoutStyles.hoverItem}
                                                            pr="1.5"
                                                            onClick={() => {
                                                                onClose();
                                                                if (!subNavigation.eventName) return;

                                                                trackEvent({
                                                                    event: subNavigation.eventName,
                                                                });
                                                            }}
                                                        >
                                                            <NavigationButton
                                                                justifyContent="start"
                                                                py={0}
                                                                px={2}
                                                                {...subNavigation}
                                                                {...(buttonStyle as Omit<
                                                                    ButtonProps,
                                                                    "title" | "type" | "onClick"
                                                                >)}
                                                                {...subNavigation.buttonProps}
                                                                onClick={callFnsInSequence(
                                                                    subNavigation.onClick,
                                                                    onPopoverItemClick
                                                                )}
                                                                color="gray.600"
                                                            />
                                                            <NavigationBadge
                                                                isExpanded={isExpanded}
                                                                navigationItem={subNavigation}
                                                            />
                                                        </HStack>
                                                    );
                                                })}
                                            </React.Fragment>
                                        );
                                    })}
                                </VStack>
                            </PopoverBody>
                        </PopoverContent>
                    </>
                );
            }}
        </Popover>
    );
};

export default React.memo(PopoverNavigationItem);
