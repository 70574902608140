import { useDisclosure, useEventListener } from "@chakra-ui/react";
import React from "react";
import { PopUpNavigator } from "../PopUpNavigator/PopUpNavigator";
import { checkAndTogglePopUpNavigation } from "../PopUpNavigator/utils";
import useFlattenedNavigationItem from "../hooks/useFlattenedNavigationItem";

const QuickSearch: React.FC = () => {
    const { isOpen: popUpNavigatorIsOpen, onOpen: openPopUpNavigator, onClose: closePopUpNavigator } = useDisclosure();

    const { flattendNavigationItems } = useFlattenedNavigationItem();

    function handler(e: KeyboardEvent) {
        checkAndTogglePopUpNavigation(e, openPopUpNavigator, closePopUpNavigator, popUpNavigatorIsOpen);
    }

    useEventListener("keydown", handler);

    if (!popUpNavigatorIsOpen) return null;
    return (
        <PopUpNavigator isOpen={popUpNavigatorIsOpen} onClose={closePopUpNavigator} options={flattendNavigationItems} />
    );
};

export default QuickSearch;
