import { Box, Text, useDisclosure, useToast } from "@chakra-ui/react";
import AlertDialog from "app/components/AlertDialog/AlertDialogNoTrigger";
import { useGetIntegration } from "app/screens/Integration/fetchHooks/integration";
import AccountsMenu from "app/layouts/Menu/AccountsMenu";
import { useQueryParam } from "app/utils/history";
import { useAccountId } from "app/utils/react-helpers";

import { default as React, FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CalendlyConnectForm } from "./CalendlyConnectForm";
import { CalendlyTabs } from "./CalendlyTabs";
import IntegrationDetailBreadCrumb from "app/screens/Integration/components/IntegrationDetailBreadCrumbs";

export const CalendlyHome: FC = () => {
    const { integrationId } = useParams<{ integrationId: string }>();
    const accountId = useAccountId();
    const { data, isLoading } = useGetIntegration({ accountId, integrationId });
    const integrationName = data?.calendly?.integrationName;
    const history = useHistory();
    const isError = history.location.pathname.includes("error");
    const query = useQueryParam();
    const errorMsg = query.get("message");
    const isAuthed = query.get("isAuth");
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleClose = () => {
        if (isError) {
            history.push("/integration");
        }
        onClose();
    };

    React.useEffect(() => {
        if (isError) {
            onOpen();
        }
    }, [errorMsg, isError, onOpen, toast]);

    React.useEffect(() => {
        if (isAuthed) {
            toast({
                title: "Calendly Connected successfully",
                status: "success",
                description: "Successfully Connected Calendly",
                id: "isAuthed",
            });
        }
    }, [isAuthed, toast]);

    return (
        <AccountsMenu>
            <IntegrationDetailBreadCrumb currentpath={integrationName ?? "Calendly"} />
            <Box pb={4} px={5} w="full">
                <CalendlyConnectForm integrationId={integrationId} lastUpdatedAt={data?.updatedAt} />
            </Box>
            <CalendlyTabs data={data} isLoading={isLoading} />
            <AlertDialog
                title={"Integration failed"}
                body={<Text>{errorMsg}</Text>}
                primaryButtonText="Close"
                primaryButtonProps={{ colorScheme: "red" }}
                isOpen={isOpen}
                onClose={handleClose}
            />
        </AccountsMenu>
    );
};
