import { HStack, IconProps, Spacer, Text } from "@chakra-ui/react";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { callFnsInSequence } from "app/utils/common";
import React from "react";
import { useHistory } from "react-router-dom";
import { useGetUserNavItems } from "../hooks/useGetUserNavItems";
import { NavigationItem as NavigationItemType, SubNavigationItemType } from "../types";
import { appLayoutStyles, getNavigationItemState, getSanitizedNavigationButtonProps } from "../utils";
import NavigationBadge from "./NavigationBadge";
import NavigationButton from "./NavigationButton";
import PopoverNavigationItem from "./PopoverNavigationItem";

export interface NavigationItemProps {
    navigationItem: NavigationItemType;
    collapseNavigation: () => void;
    setSubNav: (subNav: SubNavigationItemType | null) => void;
    isExpanded: boolean;
}

const NavigationItem: React.FC<NavigationItemProps> = (props) => {
    const { setSubNav, collapseNavigation, isExpanded } = props;

    const [isActive, setActive] = React.useState(false);
    const history = useHistory();
    const { getUserNavItems } = useGetUserNavItems();

    const userNavItems = React.useMemo(() => {
        return getUserNavItems(props.navigationItem);
    }, [getUserNavItems, props.navigationItem]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { buttonProps, iconProps, ...navigationButtonProps } = userNavItems ?? ({} as NavigationItemType);

    const itemStyle = isActive ? appLayoutStyles.activeItem : appLayoutStyles.item;

    const validateAndSetActiveItem = React.useCallback(() => {
        if (!userNavItems) {
            setActive(false);
            return;
        }

        const navigationItemState = getNavigationItemState({
            navigationItem: userNavItems,
        });

        const isActive = navigationItemState.state == "active";

        setActive(isActive);

        if (userNavItems?.type !== "sub-navigation") {
            setSubNav(null);
        } else if (userNavItems?.subNavigation?.type === "in-app" && isActive) {
            setSubNav(userNavItems);
        }
    }, [setSubNav, userNavItems]);

    React.useEffect(() => {
        validateAndSetActiveItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const unlisten = history.listen(() => {
            validateAndSetActiveItem();
        });
        return () => {
            unlisten();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userNavItems]);

    const firstSubNavigation = React.useMemo(() => {
        if (userNavItems?.type !== "sub-navigation" || userNavItems.subNavigation.type !== "in-app") return;

        const firstChildren = userNavItems.subNavigation.children?.[0].children[0];

        if (firstChildren.type !== "internal-link") return;

        return firstChildren;
    }, [userNavItems]);

    const onClick = React.useCallback(() => {
        if (!userNavItems) return;

        if (userNavItems.eventName) trackEvent({ event: userNavItems.eventName });

        if (userNavItems?.type !== "sub-navigation") {
            setSubNav(null);
            return;
        }

        if (userNavItems.subNavigation.type !== "in-app") return;

        history.push(firstSubNavigation?.to);
        setSubNav(userNavItems);
    }, [firstSubNavigation?.to, history, setSubNav, userNavItems]);

    const onPopoverItemClick = React.useCallback(() => {
        collapseNavigation();
        setSubNav(null);
    }, [setSubNav, collapseNavigation]);

    if (!userNavItems) {
        return null;
    }
    return (
        <PopoverNavigationItem
            navigationItem={userNavItems}
            isExpanded={Boolean(isExpanded)}
            onPopoverItemClick={onPopoverItemClick}
        >
            {({ isOpen }) => {
                return (
                    <HStack w="full" maxW="full" pos="relative">
                        <NavigationButton
                            {...getSanitizedNavigationButtonProps(userNavItems, {
                                isActive,
                                isOpen: Boolean(isOpen),
                            })}
                            iconProps={{ ...(itemStyle as IconProps), ...(iconProps ?? {}) }}
                            isNavigationExpanded={isExpanded}
                            px={2}
                            justifyContent={isExpanded ? "start" : "center"}
                            onClick={callFnsInSequence(onClick, userNavItems?.onClick)}
                            flexShrink={0}
                            titleComp={
                                <HStack w="full">
                                    <Text>{navigationButtonProps.title}</Text>
                                    <Spacer />
                                    <NavigationBadge isExpanded={Boolean(isExpanded)} navigationItem={userNavItems} />
                                </HStack>
                            }
                        />
                        {!isExpanded && (
                            <NavigationBadge isExpanded={Boolean(isExpanded)} navigationItem={userNavItems} />
                        )}
                    </HStack>
                );
            }}
        </PopoverNavigationItem>
    );
};

export default React.memo(NavigationItem);
