export const notificationConversationPrefs = ["followup", "movesToUnassigned", "assignedToMe"] as const;
type NotificationConversationPrefs = (typeof notificationConversationPrefs)[number];

export const notificationMessagePrefs = [
    "unassignedMessages",
    "mineMessages",
    "mentionedMessages",
    "failedMessages",
] as const;
type NotificationMessagePrefs = (typeof notificationMessagePrefs)[number];

export type NotificationPrefs = NotificationMessagePrefs | NotificationConversationPrefs;

export type NotificationMessagePreference = Record<NotificationMessagePrefs, boolean | undefined>;

export type NotificationConversationPreference = Record<NotificationConversationPrefs, boolean | undefined>;

export const notificationPreferenceModes = ["all", "custom", "none"] as const;
type NotificationPreferenceMode = (typeof notificationPreferenceModes)[number];

export interface NotificationAllPreference {
    mode: Extract<NotificationPreferenceMode, "all">;
    sendToMobileOnlyIfInActive?: boolean;
}

export interface NotificationNonePreference {
    mode: Extract<NotificationPreferenceMode, "none">;
}

export interface NotificationCustomPreference {
    mode: Extract<NotificationPreferenceMode, "custom">;
    custom: {
        message?: NotificationMessagePreference;
        conversation?: NotificationConversationPreference;
    };
    sendToMobileOnlyIfInActive?: boolean;
}

export type NotificationPreference =
    | NotificationAllPreference
    | NotificationCustomPreference
    | NotificationNonePreference;
