import { StackProps } from "@chakra-ui/react";
import React, { useContext } from "react";
import TableContext from "../TableContext/context";
import { TableContextType } from "../TableContext/type";
import { PaginationState, RowData } from "@tanstack/react-table";
import PaginationFooterControlled, {
    PaginationFooterControlledWithTotalCountProps,
    PaginationFooterControlledWithoutTotalCountProps,
} from "./PaginationFooterControlled";

type SanitizePaginatorFooterProps<
    T extends PaginationFooterControlledWithTotalCountProps | PaginationFooterControlledWithoutTotalCountProps,
> = Omit<
    T,
    "pageSize" | "onPageSizeChange" | "canPreviousPage" | "canNextPage" | "pageIndex" | "onPreviousPage" | "onNextPage"
>;

interface PaginationFooterCommonProps {
    isSticky?: boolean;
    onPageChange: (pageIndex: number, pagination: PaginationState) => void;
    onPageSizeChange: (pageSize: number, pagination: PaginationState) => void;
    initialPageSize?: number;
}

export type PaginationFooterProps = PaginationFooterCommonProps &
    (
        | SanitizePaginatorFooterProps<PaginationFooterControlledWithTotalCountProps>
        | SanitizePaginatorFooterProps<PaginationFooterControlledWithoutTotalCountProps>
    );

const PaginationFooter = <T extends RowData = RowData>(props: PaginationFooterProps): JSX.Element | null => {
    const {
        onPageChange,
        onPageSizeChange,
        isSticky = true,
        initialPageSize,
        ...paginationFooterOriginalProps
    } = props;
    const { table, setPageCount, data } = useContext<TableContextType<T>>(TableContext);
    const stickyProps = isSticky ? ({ pos: "sticky", bottom: 0, left: 0, right: 0 } as StackProps) : {};
    const { pagination } = table.getState();

    React.useEffect(() => {
        if (!paginationFooterOriginalProps.totalDataCount) {
            setPageCount(-1);
            return;
        }
        const calculatedPageCount =
            Math.ceil((paginationFooterOriginalProps.totalDataCount ?? 0) / Number(pagination.pageSize)) ?? -1;

        setPageCount(calculatedPageCount);
    }, [paginationFooterOriginalProps.totalDataCount, pagination.pageSize, setPageCount]);

    const canNextPage = table.getCanNextPage() && !(data.length < pagination.pageSize);
    const canPreviousPage = table.getCanPreviousPage();

    const onNextPage = React.useCallback(() => {
        const currentPagination = pagination;
        const currentPageIndex = currentPagination.pageIndex + 1;
        table.nextPage();
        onPageChange(currentPageIndex, { ...currentPagination, pageIndex: currentPageIndex });
    }, [onPageChange, pagination, table]);

    const onPreviousPage = React.useCallback(() => {
        const currentPagination = pagination;
        const currentPageIndex = currentPagination.pageIndex - 1;
        table.previousPage();
        onPageChange(currentPageIndex, { ...currentPagination, pageIndex: currentPageIndex });
    }, [onPageChange, pagination, table]);

    const onPageSizeChangeFn = React.useCallback(
        (currentPageSize: number) => {
            table.setPageSize(currentPageSize);
            table.setPageIndex(0);
            onPageChange(0, { pageSize: currentPageSize, pageIndex: 0 });
            onPageSizeChange(currentPageSize, { pageSize: currentPageSize, pageIndex: 0 });
        },
        [onPageChange, onPageSizeChange, table]
    );

    return (
        <PaginationFooterControlled
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
            pageIndex={pagination.pageIndex}
            pageSize={pagination.pageSize}
            onPageSizeChange={onPageSizeChangeFn}
            noOfDataInCurrentPage={data.length}
            {...stickyProps}
            {...paginationFooterOriginalProps}
        />
    );
};

PaginationFooter.displayName = "TablePaginationFooter";

export default PaginationFooter;
