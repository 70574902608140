import { RootState } from "app/modules";
import { handleNetworkError } from "app/utils/fetchUtils";
import React from "react";
import { useSelector } from "react-redux";

const zohoWidgetScriptId = "zoho-help-widget-script";

const ZohoHelpWidget: React.FC = () => {
    const user = useSelector((state: RootState) => state.authed.user);
    const waChannels = useSelector((state: RootState) => state.authed.waChannels);
    const WaNumber = waChannels?.[0]?.whatsapp?.whatsappNumber ?? "";
    const account = user.accounts?.[0]?.account;

    React.useEffect(() => {
        if (user && waChannels) {
            (window as any).ZohoHCAsapSettings = {
                hideLauncherIcon: true,
                ticketsSettings: {
                    preFillFields: {
                        email: {
                            defaultValue: user?.email ?? "",
                        },
                        contactId: {
                            defaultValue: user.name ?? "",
                        },
                        customFields: {
                            "Business Name": {
                                defaultValue: account?.name ?? "",
                            },
                            "Activated Number": {
                                defaultValue: WaNumber,
                            },
                        },
                    },
                },
            };

            fetch("https://desk.zoho.in/portal/api/web/inapp/98595000001414001?orgId=60019121503", {
                cache: "no-store",
            })
                .then((response) => response.text())
                .then((scriptText) => {
                    const script = document.createElement("script");
                    script.id = zohoWidgetScriptId;
                    script.textContent = scriptText;
                    document.head.appendChild(script);
                })
                .catch(handleNetworkError);

            return () => {
                const script = document.getElementById(zohoWidgetScriptId);

                if (!script || !script.textContent) return;

                script.remove();
                delete (window as any).ZohoHCAsapSettings;
            };
        }
    }, [WaNumber, account?.name, user, waChannels]);

    return null;
};

export default ZohoHelpWidget;
