import { VStack } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
interface NavigationItemWrapperProps {
    isExpanded: boolean;
}
const NavigationItemWrapper: React.FC<PropsWithChildren<NavigationItemWrapperProps>> = (props) => {
    const { isExpanded, children } = props;
    return (
        <VStack
            w="full"
            h="full"
            mb="1"
            overflowX="hidden"
            css={{
                scrollbarWidth: "none",
            }}
            align={isExpanded ? "start" : undefined}
            px={2}
            spacing={1}
        >
            {children}
        </VStack>
    );
};

export default NavigationItemWrapper;
