import { useDisclosure } from "@chakra-ui/react";
import NudgeModal from "app/components/NudgeModal";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { useNudge } from "app/hooks/useNudge";
import { ProtectedRouteProps } from "app/types";
import React, { useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { accountDisconnectPathsToAllow, ipRestrictionPathsToAllow } from "./constants";
import { useAccountMasterHook } from "app/hooks/useAccountMasterHook";

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    component: Component,
    componentProps = {},
    authed,
    ...rest
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { computedMatch: { path, url } = {}, name } = rest;
    const history = useHistory();
    const popupProps = useDisclosure();
    const account = authed?.user?.accounts?.[0]?.account;
    const [isUserNudged, setIsUserNudged] = useState(false);
    const { isFreeTrial, isSandboxChannel } = useAccountMasterHook();
    const canNudgeUser = isFreeTrial || isSandboxChannel;
    const nudgeUser = () => {
        if (!isUserNudged && canNudgeUser) {
            trackEvent({ event: "NUDGE_SNF_DISPLAY_POPUP" });
            popupProps.onOpen();
        }
        setIsUserNudged(true);
    };

    const extendNudgeExpire = useNudge({
        timeOutConfig: {
            delay: 120,
            timeOutCallback: nudgeUser,
        },
        option: 4,
        outOffWindowCallback: nudgeUser,
        enabled: canNudgeUser,
    });

    const disconnected =
        account?.status === "disconnected" &&
        accountDisconnectPathsToAllow.every((path) => !history.location.pathname.includes(path));

    const allowCurrentPage = ipRestrictionPathsToAllow.some((path) => history.location.pathname.includes(path));

    return (
        <>
            <Route
                {...rest}
                render={(props) => {
                    if (authed?.isIPRestricted && !allowCurrentPage) {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/logout",
                                }}
                            />
                        );
                    }
                    if (authed.accessToken) {
                        if (disconnected) {
                            return (
                                <Redirect
                                    to={{
                                        pathname: "/account-disconnect",
                                        state: { from: props.location },
                                    }}
                                />
                            );
                        }
                        return (
                            <>
                                <Component {...componentProps} />
                                <NudgeModal {...popupProps} extendNudgeExpire={extendNudgeExpire} />
                            </>
                        );
                    }
                    return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
                }}
            />
        </>
    );
};

export default ProtectedRoute;
