import { useToast } from "@chakra-ui/react";
import { UpdateIntegrationInput } from "app/screens/Integration/fetchHooks/integration";
import { CalendlyConnectForm } from "app/screens/Integration/Calendly/CalendlyConnectForm";
import { WAITemplateMessage, QueryKey } from "app/types";
import { Integration, CalendlyWorkflow } from "app/screens/Integration/types/integration";
import { CalendlyEventList } from "app/screens/Integration/types/calendly";
import { IntegrationField } from "app/screens/Integration/types/integrationField";
import { postJSON, patchJSON, fetcher, deleteJSON, mapQueryParams } from "app/utils/fetchUtils";
import { UseMutationOptions, UseMutationResult, MutationFunction, useMutation, useQueryClient, QueryFunction, useQuery, UseQueryOptions } from "react-query";

//Connect Calendly

interface UseConnectCalendlyProps extends UseMutationOptions<string, Error, CalendlyConnectForm> {
    accountId: string;
    onSuccess?: (data: string) => void;
}

type CalendlyResponse = UseMutationResult<string, Error, CalendlyConnectForm>;

export const useConnectCalendly = (props: UseConnectCalendlyProps): CalendlyResponse => {
    const { accountId, onSuccess, ...rest } = props;
    const toast = useToast();

    const connectCalendly: MutationFunction<string, CalendlyConnectForm> = (data: CalendlyConnectForm) => {
        return postJSON(`/api/accounts/${accountId}/integrations/calendly/connect`, data);
    };

    const connectionResult = useMutation<string, Error, CalendlyConnectForm>(connectCalendly, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess,
        ...rest,
    });

    return connectionResult;
};

//create workFlow Calendly

export interface UseCreateCalendlyWorkFlowProps extends UseMutationOptions<Integration, Error, CalendlyWorkFlowInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: (data: Integration) => void;
}

export interface CalendlyWorkFlowInput
    extends Omit<
        CalendlyWorkflow,
        "id" | "templateValues" | "events" | "eventTypeUri" | "duration" | "remind" | "useCase" | "recipient"
    > {
    templateValues: Pick<WAITemplateMessage, "bodyValues" | "headerValues" | "buttonValues">;
    type: string;
    calendly?: Pick<CalendlyWorkflow, "events" | "eventTypeUri" | "duration" | "remind" | "useCase" | "recipient">;
}

type CalendlyWorkFlowResponse = UseMutationResult<Integration, Error, CalendlyWorkFlowInput>;

export const useCreateCalendlyWorkFlow = (props: UseCreateCalendlyWorkFlowProps): CalendlyWorkFlowResponse => {
    const { accountId, integrationId, onSuccess, ...rest } = props;
    const toast = useToast();
    const queryKey = [QueryKey.Integration, { accountId, integrationId }];
    const queryClient = useQueryClient();
    const createWorkflow: MutationFunction<Integration, CalendlyWorkFlowInput> = (data: CalendlyWorkFlowInput) => {
        return postJSON(`/api/accounts/${accountId}/integrations/${integrationId}/calendly/workflow`, {
            ...data,
            accountId,
            integrationId,
        });
    };

    const response = useMutation<Integration, Error, CalendlyWorkFlowInput>(createWorkflow, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (data) => {
            queryClient.setQueryData<Integration>(queryKey, data);
            toast({
                title: "Workflow Created successfully",
                status: "success",
                description: "Successfully Created",
            });
            onSuccess?.(data);
        },
        ...rest,
    });

    return response;
};

export interface UseUpdateCalendlyWorkFlowProps
    extends UseMutationOptions<Integration, Error, Partial<CalendlyWorkflow>> {
    accountId: string;
    integrationId: string;
}

type UpdateCalendlyWorkFlowResponse = UseMutationResult<Integration, Error, Partial<CalendlyWorkflow>>;

export const useUpdateCalendlyWorkFlow = (props: UseUpdateCalendlyWorkFlowProps): UpdateCalendlyWorkFlowResponse => {
    const { accountId, integrationId, ...rest } = props;
    const toast = useToast();
    const queryKey = [QueryKey.Integration, { accountId, integrationId }];
    const queryClient = useQueryClient();

    const updateCalendlyWorkFlow: MutationFunction<Integration, Partial<CalendlyWorkflow>> = (
        data: Partial<CalendlyWorkflow>
    ) => {
        return patchJSON(`/api/accounts/${accountId}/integrations/${integrationId}/calendly/workflow/${data.id}`, data);
    };

    const mutationResult = useMutation<Integration, Error, Partial<CalendlyWorkflow>>(updateCalendlyWorkFlow, {
        onSuccess: () => {
            toast({
                title: "Workflow Updated successfully",
                status: "success",
                description: "Successfully Updated",
            });
        },
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
        ...rest,
    });
    return mutationResult;
};

//get Calendly Events

interface UseCalendlyEventsProps {
    accountId: string;
    integrationId: string;
}

export const useGetCalendlyEvents = (props: UseCalendlyEventsProps) => {
    const { accountId, integrationId } = props;

    const fetchCalendlyEvents: QueryFunction<CalendlyEventList> = () => {
        return fetcher(`/api/accounts/${accountId}/integrations/${integrationId}/calendly/events
        `);
    };

    return useQuery<CalendlyEventList, Error>(
        [QueryKey.CalendlyEventsList, { accountId, integrationId }],
        fetchCalendlyEvents,
        {
            enabled: Boolean(accountId) && Boolean(integrationId),
        }
    );
};

//delete Workflow

interface useDeleteCalendlyWorkFlowProps extends UseMutationOptions<Integration, Error, void> {
    accountId: string;
    integrationId: string;
    workflowId: string;
}

export const useDeleteCalendlyWorkFlow = (
    props: useDeleteCalendlyWorkFlowProps
): UseMutationResult<Integration, Error, void> => {
    const { accountId, integrationId, workflowId } = props;
    const toast = useToast();
    const queryKey = [QueryKey.Integration, { accountId, integrationId }];
    const queryClient = useQueryClient();

    const deleteWorkFlow: MutationFunction<Integration, void> = () => {
        return deleteJSON(
            `/api/accounts/${accountId}/integrations/${integrationId}/calendly/workflow/${workflowId}/delete`
        );
    };

    const res = useMutation<Integration, Error, void>(deleteWorkFlow, {
        onError: (err) => {
            toast({
                status: "error",
                title: "Error",
                description: err.message,
            });
        },
        onSuccess: (data) => {
            queryClient.setQueryData(queryKey, data);
            toast({ title: "Workflow deleted successfully", status: "success", description: "Successfully Deleted" });
        },
    });

    return res;
};

//delete Calendly Integration

interface UseDeleteCalendlyIntegrationProps extends UseMutationOptions<boolean, Error, void> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

type DeleteIntegrationResult = UseMutationResult<boolean, Error, void>;

export const useDeleteCalendlyIntegration = (props: UseDeleteCalendlyIntegrationProps): DeleteIntegrationResult => {
    const { accountId, integrationId, onSuccess, ...rest } = props;
    const toast = useToast();

    const deleteIntegration: MutationFunction<boolean, void> = () => {
        return deleteJSON(`/api/accounts/${accountId}/integrations/${integrationId}/calendly`);
    };

    const deleteResult = useMutation<boolean, Error, void>(deleteIntegration, {
        onError: (err) => {
            toast({
                status: "error",
                title: "Error",
                description: err.message,
            });
        },
        onSuccess: () => {
            toast({
                title: "Integration deleted successfully",
                status: "success",
                description: "Successfully Deleted",
            });
            onSuccess?.();
        },
        ...rest,
    });

    return deleteResult;
};

interface UseGetCalendlyVariablesProps extends UseQueryOptions<IntegrationField[], Error> {
    accountId: string;
    integrationId: string;
    eventUri: string;
    isPhone?: boolean;
}

export const useGetCalendlyVariables = (props: UseGetCalendlyVariablesProps) => {
    const { accountId, integrationId, eventUri, isPhone, ...options } = props;
    const queryKey = [QueryKey.CalendlyIntegrationVariablesList, { accountId, integrationId, eventUri, isPhone }];

    const fetchIntegrationField = () => {
        const queryParams = mapQueryParams({ eventUri, isPhone });
        return fetcher(`/api/accounts/${accountId}/integrations/${integrationId}/calendly/variable?${queryParams}`);
    };

    const variablesList = useQuery<IntegrationField[], Error>(queryKey, fetchIntegrationField, { ...options });
    return variablesList;
};

interface UseUpdateCalendlyProps extends UseMutationOptions<Integration, Error, UpdateIntegrationInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: (field: Integration) => void;
    showSuccessMessage?: boolean;
}
type UpdateCalendlyIntegration = UseMutationResult<Integration, Error, UpdateIntegrationInput>;

export const useUpdateCalendlyIntegration = (props: UseUpdateCalendlyProps): UpdateCalendlyIntegration => {
    const { accountId, integrationId, onSuccess, showSuccessMessage = true } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateCalendly: MutationFunction<Integration, UpdateIntegrationInput> = (data: UpdateIntegrationInput) => {
        return patchJSON(`/api/accounts/${accountId}/integrations/${integrationId}/calendly`, data);
    };

    const mutationResult = useMutation<Integration, Error, UpdateIntegrationInput>(updateCalendly, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (field) => {
            if (showSuccessMessage) {
                toast({
                    title: "Integration Updated",
                    status: "success",
                });
            }
            onSuccess?.(field);
        },

        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
}

