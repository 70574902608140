import { FormControl, FormErrorMessage, FormLabel, FormLabelProps } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactChannelSelect from ".";
import { default as ReactSelectInput, OptionTypeBase } from "react-select";
import { scrollInToView } from "app/screens/Sequence/CreateSequence/util";

interface ControlledChannelStyleSelectProps {
    defaultValue?: string;
    error?: string;
    control: ReturnType<typeof useForm>["control"];
    name: string;
    label?: string;
    formLabelProps?: FormLabelProps;
    isRequired?: boolean;
    isDisabled?: boolean;
    menuPosition?: string;
}

const ControlledChannelStyleSelect: React.FC<ControlledChannelStyleSelectProps> = (props) => {
    const {
        defaultValue,
        error,
        control,
        name,
        label,
        formLabelProps = {},
        isRequired,
        isDisabled,
        menuPosition = "absolute",
    } = props;
    const selectRef = React.useRef<ReactSelectInput<OptionTypeBase>>(null);

    useEffect(() => {
        if (Boolean(error)) {
            scrollInToView("channelSelect");
        }
    }, [error]);

    return (
        <FormControl isReadOnly={Boolean(defaultValue)} isInvalid={Boolean(error)} isRequired={isRequired} mb={5}>
            <FormLabel {...formLabelProps}>{label}</FormLabel>
            <Controller
                defaultValue={defaultValue}
                render={({ onChange, value }) => {
                    return (
                        <ReactChannelSelect
                            id="channelSelect"
                            menuPosition={menuPosition}
                            onChange={onChange}
                            ref={selectRef}
                            isDisabled={isDisabled ?? Boolean(defaultValue)}
                            placeholder="Select Channel"
                            value={value}
                        />
                    );
                }}
                name={name}
                control={control}
            />
            <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
    );
};

export default ControlledChannelStyleSelect;
