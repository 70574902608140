import { HStack, Button, Text } from "@chakra-ui/react";
import GBTooltip from "app/components/GBTooltip";
import React from "react";
import { deSanitizeConditionsData, WorkflowConditions } from "app/screens/Integration/helper";

interface WorkFlowInformationChipsProps {
    condition: WorkflowConditions["condition"];
}

const WorkFlowInformationChips: React.FC<WorkFlowInformationChipsProps> = (props) => {
    const { condition } = props;
    const { workflowConditions } = deSanitizeConditionsData(condition, "shopify");

    return (
        <HStack width="full">
            {workflowConditions?.map((c) => {
                const toolTipValue = typeof c?.value != "string" ? c?.selectValue?.value : c?.value;
                return (
                    <Button width="full" overflow="hidden" size="xs" key={c?.operator?.label}>
                        <GBTooltip label={c?.path?.label}>
                            <Text isTruncated fontWeight="normal" px="2px" as="span" color="cyan.500">
                                {c?.path?.label}
                            </Text>
                        </GBTooltip>
                        <GBTooltip label={c?.operator?.label}>
                            <Text isTruncated px="1" as="span" bg="purple.100" mx="1" rounded="sm">
                                {c?.operator?.label}
                            </Text>
                        </GBTooltip>
                        <GBTooltip label={toolTipValue}>
                            <Text
                                fontWeight="medium"
                                isTruncated
                                px="2px"
                                as="span"
                                textTransform="uppercase"
                                color="gray.900"
                            >
                                {toolTipValue}
                            </Text>
                        </GBTooltip>
                    </Button>
                );
            })}
        </HStack>
    );
};

export default WorkFlowInformationChips;
