import { Button, ButtonProps, Icon, IconButton, Link, Spacer } from "@chakra-ui/react";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { UnionOmit } from "app/types";
import { callFnsInSequence, getValidHref } from "app/utils/common";
import { LucideExternalLink } from "lucide-react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { NavigationItem } from "../types";

export type NavigationButtonProps = UnionOmit<NavigationItem, "buttonProps" | "title" | "subNavigation"> &
    Omit<ButtonProps, "onClick" | "icon" | "to" | "type" | "title"> & {
        isNavigationExpanded?: boolean;
        showTooltip?: boolean;
        title: string;
        titleComp?: JSX.Element;
        showExternalLinkBtnLeftIcon?: boolean;
    };

const NavigationButton: React.FC<NavigationButtonProps> = (props) => {
    const {
        title,
        icon: LeftIcon,
        onClick,
        isNavigationExpanded = true,
        hide,
        iconProps = {},
        badge,
        eventName,
        action,
        subject,
        showTooltip = true,
        type,
        titleComp,
        showExternalLinkBtnLeftIcon = false,
        ...buttonProps
    } = props;

    const isCollapsed = !isNavigationExpanded;

    const icon = React.useMemo(() => {
        if (!LeftIcon) return undefined;
        return <Icon as={LeftIcon} fontSize={"1rem"} size="xs" strokeWidth="1.4px" {...iconProps} />;
    }, [LeftIcon, iconProps]);

    const track = React.useCallback(() => {
        if (!eventName) return;
        trackEvent({ event: eventName });
    }, [eventName]);

    if (isCollapsed) {
        return (
            <IconButton
                aria-label={title}
                data-cy={`navigation-icon-${title}`}
                {...buttonProps}
                minW="32px"
                w="32px"
                h="32px"
                maxW="32px"
                icon={icon}
            />
        );
    }

    if (type === "external-link") {
        return (
            <Button
                _hover={{ bg: "gray.100" }}
                {...buttonProps}
                as={Link}
                href={getValidHref(props.href)}
                target={"_blank"}
                rel={"noopener noreferrer"}
                isExternal={true}
                onClick={callFnsInSequence(onClick, track)}
                leftIcon={showExternalLinkBtnLeftIcon ? icon : undefined}
                justifyItems="start"
                data-cy={`navigation-button-${title}`}
            >
                {titleComp ?? title}
                <>
                    <Spacer />
                    <Icon as={LucideExternalLink} color="gray.600" strokeWidth="1px" fontSize="1rem" />
                </>
            </Button>
        );
    }

    if (props.type === "internal-link") {
        return (
            <Button
                _hover={{ bg: "gray.100" }}
                px={3}
                {...buttonProps}
                as={RouterLink}
                to={props.to}
                onClick={callFnsInSequence(onClick, track)}
                leftIcon={icon}
                data-cy={`navigation-button-${title}`}
            >
                {titleComp ?? title}
            </Button>
        );
    }

    return (
        <Button
            _hover={{ bg: "gray.100" }}
            {...buttonProps}
            onClick={onClick}
            leftIcon={icon}
            data-cy={`navigation-button-${title}`}
        >
            {titleComp ?? title}
        </Button>
    );
};
export default React.memo(NavigationButton);
