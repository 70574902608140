import { HStack, Icon, IconButton, Image, Skeleton, Spacer } from "@chakra-ui/react";
import { GB_ICON_LOGO } from "app/contants";
import GBBrandName from "assets/gb-brand-name.svg";
import React from "react";
import { LuPin } from "react-icons/lu";
interface NavigationHeaderProps {
    isExpanded: boolean;
    togglePin: () => void;
    isPinned: boolean;
}
const NavigationHeader: React.FC<NavigationHeaderProps> = (props) => {
    const { isExpanded, togglePin, isPinned } = props;
    return (
        <HStack w="full" bg="white" h="56px" spacing={2} alignItems="center" p={isExpanded ? 3 : 3}>
            <Image
                h="24px"
                w="24px"
                maxW="24px"
                maxH="24px"
                boxSize={6}
                src={GB_ICON_LOGO}
                flexShrink={0}
                flexGrow={0}
                fallback={<Skeleton h="24px" w="24px" boxSize={6} />}
            />
            {isExpanded && (
                <>
                    <Image src={GBBrandName} w="85" h="20px" fallback={<Skeleton w="85px" h="20px" mt="1" />} />
                    <Spacer />
                    <IconButton
                        aria-label="pin"
                        size="xs"
                        variant="unstyled"
                        pt={1.5}
                        icon={
                            <Icon
                                as={LuPin}
                                fontSize="xs"
                                color="gray.500"
                                transform="rotate(45deg)"
                                fill={isPinned ? "gray.500" : undefined}
                            />
                        }
                        onClick={togglePin}
                        data-cy="pin-navigation"
                    />
                </>
            )}
        </HStack>
    );
};

export default React.memo(NavigationHeader);
