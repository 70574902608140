import { Grid } from "@chakra-ui/react";
import { GBPickWaTemplateMsgWrap } from "app/components/TemplateMsgPreview/GBWaTemplateMsgWrap";
import { useMessageGalleryList } from "app/fetchHooks/message-gallery-template";
import { WaScreenProps } from "app/types";
import { useAccountId, useIntersectionObserver } from "app/utils/react-helpers";
import React, { useState } from "react";
import WATemplatePreview from "app/screens/Account/WATemplatesV2/component/WATemplatePreview";
import InfiniteLoaderComponent from "./InfiniteLoaderComponent";

interface PickFromGalleryProps {
    handleSoftTemplateCreation?: (id: string) => Promise<void>;
    tags?: string[];
    enableAuthenticationTemplate?: boolean;
}

const PickFromGallery: React.FC<PickFromGalleryProps> = (props) => {
    const { handleSoftTemplateCreation, tags, enableAuthenticationTemplate } = props;
    const [isLoading, setIsLoading] = useState<string | undefined | null>(null);
    const accountId = useAccountId();
    const [showSelectTick, setShowSelectTick] = useState<boolean>(true);

    const handleSelectTick = React.useCallback((value: boolean) => setShowSelectTick(value), []);

    const {
        data: galleryData,
        status: valueStatus,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useMessageGalleryList({ accountId, tagNames: tags?.join(",") });
    const WaGalleryList = galleryData?.pages.flat();

    const handleTemplate = async (template: WaScreenProps) => {
        if (template) {
            setIsLoading(template.id);
            await handleSoftTemplateCreation?.(template.id as string);
            setIsLoading(null);
        }
    };

    const target = React.useRef<HTMLDivElement>(null);

    useIntersectionObserver({
        onIntersect: () => {
            if (!isFetchingNextPage) {
                fetchNextPage();
            }
        },
        target,
        enabled: valueStatus === "success" && hasNextPage,
        threshold: 1,
    });

    return (
        <>
            <Grid w="full" templateColumns="repeat(auto-fill, minmax(272px, 1fr))" gap={4} py={4}>
                {WaGalleryList?.map((template, index) => {
                    const { name, category, language, createdAt, id, components } = template ?? {};

                    return (
                        <GBPickWaTemplateMsgWrap
                            key={id}
                            data-cy={`toolbar-popover-item-whatsapp-template-${index}`}
                            templateName={name ?? ""}
                            category={category}
                            language={language}
                            createdAt={createdAt as string}
                            onSelect={() => handleTemplate(template)}
                            showSelectTick={showSelectTick}
                            setShowSelectTick={handleSelectTick}
                            enableAuthenticationTemplate={enableAuthenticationTemplate}
                        >
                            <WATemplatePreview
                                components={components ?? []}
                                language={language}
                                showBodyVariable={true}
                            />
                        </GBPickWaTemplateMsgWrap>
                    );
                })}
            </Grid>
            {hasNextPage && (
                <InfiniteLoaderComponent
                    hasNextPage={hasNextPage}
                    targetRef={target}
                    onClick={() => fetchNextPage()}
                    isFetchingNextPage={isFetchingNextPage}
                />
            )}
        </>
    );
};

export default PickFromGallery;
