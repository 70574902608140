import { useToast } from "@chakra-ui/react";
import { QueryKey, TQueryKey } from "app/types/common";
import { NotificationPreference } from "app/types/notifications";
import { User, UserAccount, UserPref, UserUpdate } from "app/types/user";
import { fetcher, patchJSON, mapQueryParams, postJSON } from "app/utils/fetchUtils";
import {
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationResult,
    useQueryClient,
    UseInfiniteQueryResult,
    QueryFunction,
    InfiniteData,
    useQuery,
    UseQueryResult,
    UseQueryOptions,
    UseMutationOptions,
} from "react-query";

const PAGE_SIZE = 10;

interface UserQueryContext {
    previousUser?: UserUpdate;
}

type UserPatchUser<InputType> = UseMutationResult<UserUpdate, Error, InputType, UserQueryContext>;

interface UsePatchUserProps {
    accountId: string;
    onSuccess?: () => void;
    showSuccessMessage?: boolean;
}

export const useUpdateUser = <InputType extends Partial<UserUpdate>>(
    props: UsePatchUserProps
): UserPatchUser<InputType> => {
    const { accountId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.UpdateUser, { accountId }];

    const updateUser = (user: InputType) => {
        return patchJSON<UserUpdate>(`/api/accounts/${accountId}/users/${user.id}`, user);
    };

    const mutationResult = useMutation<UserUpdate, Error, InputType, UserQueryContext>(updateUser, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedUser, context) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: err.message,
            });
            if (context?.previousUser) {
                queryClient.setQueryData<UserUpdate>(queryKey, context.previousUser);
            }
        },
        onSuccess: () => {
            if (showSuccessMessage) {
                toast({
                    title: "User Updated!",
                    status: "success",
                    description: "User updated successfully!",
                });
            }
            queryClient.invalidateQueries(QueryKey.TeamUsers);
            onSuccess?.();
        },
        // When mutate is called:
        onMutate: async (updatedUser) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);

            // Snapshot the previous value
            const previousUser = queryClient.getQueryData<UserUpdate>(queryKey);

            if (!previousUser) return {};

            const updated = { ...previousUser, ...updatedUser };

            queryClient.setQueryData<UserUpdate & InputType>(queryKey, updated);

            // Optionally return a context containing data to use when for example rolling back
            return { previousUser };
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface UseUserListProps
    extends UseInfiniteQueryOptions<UserListResultProps, Error, UserListResultProps, UserListResultProps, TQueryKey> {
    accountId: string;
    search?: string;
    getCount?: boolean;
    populatePaths?: ("account" | "role")[];
}

export interface UserListWithCountResultProps {
    data: UserAccount[];
    count: number;
}
export type UserListResultProps = UserListWithCountResultProps;

export const isUserWithCount = (
    getCount: boolean,
    props: UserListResultProps
): props is UserListWithCountResultProps => {
    return getCount;
};

export const isUserWithCountInfiniteQuery = (
    getCount: boolean,
    props: InfiniteData<UserListResultProps>
): props is InfiniteData<UserListWithCountResultProps> => {
    return getCount;
};
export const useUserList = (props: UseUserListProps): UseInfiniteQueryResult<UserListResultProps, Error> => {
    const { accountId, getCount = false, populatePaths = [], search, ...options } = props;
    const fetchUserList: QueryFunction<UserListResultProps, TQueryKey> = ({ pageParam, queryKey }) => {
        const [, { accountId, search }] = queryKey;
        const queryParams = mapQueryParams({
            page: pageParam ?? 1,
            limit: PAGE_SIZE,
            search,
            getCount,
            populatePaths: ["user", ...populatePaths],
        });
        return fetcher(`/api/accounts/${accountId}/account-users?${queryParams}`);
    };

    const infiniteQueryResult = useInfiniteQuery<UserListResultProps, Error, UserListResultProps, TQueryKey>(
        [QueryKey.UserList, { accountId, search, populatePaths }],
        fetchUserList,
        {
            ...options,
            keepPreviousData: true,
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage?.data?.length) return undefined;
                return lastPage.data.length === PAGE_SIZE ? pages.length + 1 : undefined;
            },
        }
    );
    return infiniteQueryResult;
};

interface UseAccountUsersListProps
    extends UseInfiniteQueryOptions<
        AccountUsersListResult,
        Error,
        AccountUsersListResult,
        AccountUsersListResult,
        TQueryKey
    > {
    accountId: string;
    search?: string;
    getCount?: boolean;
}

export interface AccountUsersListWithCount {
    data: UserAccount[];
    count: number;
}
export type AccountUsersListResult = AccountUsersListWithCount;

export const useAccountUsersList = (
    props: UseAccountUsersListProps
): UseInfiniteQueryResult<AccountUsersListResult, Error> => {
    const { accountId, getCount = false, search, ...options } = props;

    const infiniteQueryResult = useInfiniteQuery<AccountUsersListResult, Error, AccountUsersListResult, TQueryKey>(
        [QueryKey.AccountUsersList, { accountId, search }],
        ({ pageParam, queryKey }) => {
            const [, { accountId, search }] = queryKey;
            const queryParams = mapQueryParams({
                page: pageParam ?? 1,
                limit: PAGE_SIZE,
                search,
                getCount,
            });
            return fetcher(`/api/accounts/${accountId}/account-users?${queryParams}`);
        },
        {
            ...options,
            keepPreviousData: true,
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage?.data?.length) return undefined;
                return lastPage.data.length === PAGE_SIZE ? pages.length + 1 : undefined;
            },
        }
    );
    return infiniteQueryResult;
};

interface UserPrefProps {
    accountId: string;
}

type UseGetUserPrefProps = UserPrefProps & UseQueryOptions<UserPref, Error>;

export const useGetUserPref = (props: UseGetUserPrefProps): UseQueryResult<UserPref, Error> => {
    const { accountId, ...options } = props;
    const query = useQuery<UserPref, Error>(
        [QueryKey.UserPref, { accountId }],
        () => {
            const url = `/api/accounts/${accountId}/user-preference`;
            return fetcher(url);
        },
        options
    );

    return query;
};

type ColumnPrefType = "contactColumnPref" | "companyColumnPref";

interface UpdateUserPrefParams {
    type: ColumnPrefType;
    fieldIds: string[];
}

interface UseUpdateUserPrefProps extends UseMutationOptions<UserPref, Error, UpdateUserPrefParams> {
    accountId: string;
}

export const useUpdateUserPref = (
    props: UseUpdateUserPrefProps
): UseMutationResult<UserPref, Error, UpdateUserPrefParams> => {
    const { accountId, ...options } = props;
    const queryClient = useQueryClient();

    const mutationResult = useMutation<UserPref, Error, UpdateUserPrefParams>(
        [QueryKey.UserPref, { accountId }],
        ({ fieldIds, type }) => {
            const url = `/api/accounts/${accountId}/user-preference`;
            return postJSON(url, { [type]: { fieldIds } });
        },
        {
            ...options,
            onSuccess: (...args) => {
                queryClient.invalidateQueries([QueryKey.UserPref, { accountId }]);
                options.onSuccess?.(...args);
            },
        }
    );

    return mutationResult;
};

interface UseUpdateNotificationPrefProps extends UseMutationOptions<UserAccount, Error, NotificationPreference> {
    accountId: string;
}

export const useUpdateNotificationPref = (
    props: UseUpdateNotificationPrefProps
): UseMutationResult<UserAccount, Error, NotificationPreference> => {
    const { accountId, ...options } = props;
    const queryClient = useQueryClient();

    const mutationResult = useMutation<UserAccount, Error, NotificationPreference>(
        [QueryKey.NotificationPreference, { accountId }],
        (pref) => {
            const url = `/api/accounts/${accountId}/me/notification-preference`;
            return patchJSON(url, pref);
        },
        {
            ...options,
            onSuccess: (...args) => {
                queryClient.invalidateQueries([QueryKey.UserPref, { accountId }]);
                options.onSuccess?.(...args);
            },
        }
    );

    return mutationResult;
};
