import { useToast } from "@chakra-ui/react";
import { IntegrationProps, Oauth2ResultData, RegisterQueryContext, SessionParams, ShopifyPayment, UpdateIntegrationInputWithShopUrl, UsePatchIntegration, UseUpdateIntegrationProps } from "app/screens/Integration/fetchHooks/integration";
import { QueryKey } from "app/types";
import { PromptAction } from "app/types/bot";
import { Integration } from "app/screens/Integration/types/integration";
import { postJSON, fetcher, mapQueryParams, patchJSON } from "app/utils/fetchUtils";
import { UseMutationResult, useMutation, useQueryClient, UseMutationOptions } from "react-query";
import { useHistory } from "react-router-dom";
import { WorkflowConditions } from "app/screens/Integration/helper";
import { BodyFormValues } from "app/screens/Integration/components/MappingIntegrationTemplate";


interface UseInitiateShopifyAuthProps {
    accountId: string;
    onSuccess?: (res: Oauth2ResultData) => void;
    onError?: () => void;
}

type UsePostOauth2Credential<InputType> = UseMutationResult<Oauth2ResultData, Error, InputType>;

export const useInitiateShopifyAuth = (props: UseInitiateShopifyAuthProps): UsePostOauth2Credential<SessionParams> => {
    const { accountId, onError, onSuccess } = props;
    const toast = useToast();
    const history = useHistory();

    const fetchOAuth2URI = (data: SessionParams) => {
        return postJSON(`/api/accounts/${accountId}/integrations/shopify/create`, data);
    };

    const mutationResult = useMutation<Oauth2ResultData, Error, SessionParams>(fetchOAuth2URI, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            onError?.();
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
            history.replace(`/integration/shopify`);
        },

        onSuccess: (i) => {
            onSuccess?.(i);
        },
    });
    return mutationResult;
};

type UseCheckPaymentReturn = UseMutationResult<ShopifyPayment, Error, void, unknown>;

export const useCheckPaymentShopify = (props: IntegrationProps): UseCheckPaymentReturn => {
    const { accountId, integrationId, onSuccess } = props;
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];
    const queryClient = useQueryClient();

    const checkPayment = () => {
        return fetcher(
            `/api/accounts/${accountId}/integrations/shopify/${integrationId}/checkPayment?processPayment=true`
        );
    };

    const mutationResult = useMutation<ShopifyPayment, Error>(checkPayment, {
        onSuccess: (res) => {
            if (res?.confirmationUrl) {
                window.open(res.confirmationUrl, "_self");
                return;
            }
            queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

interface ShopifyRegisterProps {
    topic: string;
    name: string;
    templateId?: string;
    isCustom?: boolean;
    workflowId?: string;
    sendAfter?: number;
    condition?: WorkflowConditions["condition"];
    isActive?: boolean;
    templateValues?: BodyFormValues;
    workflowActions?: PromptAction[];
}

interface ShopifyRegisterFlow {
    accountId: string;
    integrationId: string;
    workflowId?: string;
    onSuccess?: () => void;
    showSuccessMessage?: boolean;
    isCustom: boolean;
    isNewWorkFlow: boolean;
}

type UseUpdateTemplateResult<InputType> = UseMutationResult<Integration, Error, InputType, RegisterQueryContext>;

export const useShopifyRegister = <InputType extends Record<string, any> = ShopifyRegisterProps>(
    props: ShopifyRegisterFlow
): UseUpdateTemplateResult<InputType> => {
    const {
        accountId,
        integrationId,
        workflowId,
        showSuccessMessage = true,
        isCustom,
        isNewWorkFlow,
        onSuccess,
    } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const registerTemplate = (templateDetails: InputType) => {
        if (isNewWorkFlow) {
            const queryParams = mapQueryParams({
                isCustom,
            });

            return postJSON<Integration>(
                `/api/accounts/${accountId}/integrations/shopify/${integrationId}/workflow?${queryParams}`,
                templateDetails as any
            );
        }
        const queryParams = mapQueryParams({
            isCustom: templateDetails?.isCustom,
        });
        const modifiedWorkflowId = workflowId ?? templateDetails?.workflowId;

        return patchJSON<Integration>(
            `/api/accounts/${accountId}/integrations/shopify/${integrationId}/workflow/${modifiedWorkflowId}?${queryParams}`,
            templateDetails
        );
    };

    const mutationResult = useMutation<Integration, Error, InputType, RegisterQueryContext>(registerTemplate, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedChannel, context) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: err.message,
            });
            if (context?.previousIntegration) {
                queryClient.setQueryData<Integration>(queryKey, context.previousIntegration);
            }
        },
        onSuccess: () => {
            if (showSuccessMessage) {
                toast({
                    title: `${isNewWorkFlow ? "Create" : "Update"} workflow!`,
                    status: "success",
                    description: `${isNewWorkFlow ? "Create" : "Update"} workflow successfully!`,
                });
            }
            onSuccess?.();
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface CreateShopifyInput {
    shop: string;
}

interface CreateShopifyIntegrationResult {
    location: string;
}

type CreateShopifyIntegration = UseMutationResult<CreateShopifyIntegrationResult, Error, CreateShopifyInput>;

interface CreateShopifyProps extends UseMutationOptions<CreateShopifyIntegrationResult, Error, CreateShopifyInput> {
    accountId: string;
    onSuccess?: (data: CreateShopifyIntegrationResult) => void;
}

type ShopifyReAuthenticateIntegration = UseMutationResult<Integration, Error, void>;

interface ShopifyReAuthenticateProps extends UseMutationOptions<Integration, Error, void> {
    accountId: string;
    integrationId: string;
    onSuccess?: (data: Integration) => void;
}

export const useShopifyReAuthenticate = (props: ShopifyReAuthenticateProps): ShopifyReAuthenticateIntegration => {
    const { accountId, integrationId, onSuccess, ...options } = props;
    const toast = useToast();

    const shopifyReAuthenticate = () => {
        return fetcher(`/api/accounts/${accountId}/integrations/shopify/${integrationId}/reconnect`);
    };

    const response = useMutation<Integration, Error, void>(shopifyReAuthenticate, {
        onError: (err) => {
            toast({
                status: "error",
                title: "Integration Re-Authenticate Failed",
                description: err?.message ?? "Integration Re-Authenticate Failed",
            });
        },
        onSuccess: (data) => {
            toast({
                status: "success",
                title: "Integration Re-Authenticated",
            });
            onSuccess?.(data);
        },

        ...options,
    });

    return response;
};

export const useShopifyIntegration = <InputType extends UpdateIntegrationInputWithShopUrl>(
    props: UseUpdateIntegrationProps
): UsePatchIntegration<InputType> => {
    const { accountId, integrationId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const integrationUpdate = (data: InputType): Promise<Integration> => {
        return patchJSON<Integration>(`/api/accounts/${accountId}/integrations/shopify/${integrationId}/config`, {
            ...data,
        } as unknown as any);
    };

    const mutationResult = useMutation<Integration, Error, InputType>(integrationUpdate, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (field) => {
            if (showSuccessMessage) {
                toast({
                    title: "Integration Updated",
                    status: "success",
                });
            }
            onSuccess?.(field);
        },

        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};
