import { Box, Grid, HStack, Icon, Image, Input, InputGroup, InputLeftElement, Text, VStack } from "@chakra-ui/react";
import { IntegrationFieldApp } from "app/screens/Integration/fetchHooks/integration";
import { StylesObject } from "app/types/common";
import { IntegrationField } from "app/screens/Integration/types/integrationField";
import abandonedCartLogo from "assets/abandonedCart.png";
import CashFree from "assets/BotTemplate/IntegrationLogo/Cashfree.png";
import ShipRocket from "assets/BotTemplate/IntegrationLogo/Shiprocket.png";
import fbImg from "assets/fblogo.svg";
import RazorPayIcon from "assets/razorpay.svg";
import ShipWay from "assets/Shipway.png";
import ShopifyIcon from "assets/shopifyIcon.svg";
import WebHook from "assets/webhook.svg";
import WooCommerceIcon from "assets/woocommerceLogo.svg";
import React, { FC } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import GallaboxLoadingState from "../GBLoadingState";
import zohoImg from "assets/Integration/books.svg";
import Calendly from "assets/Integration/Calendly.svg";
import ShopfloLogo from "assets/Integration/shopfloLogo.svg";

const styles: StylesObject = {
    heading: {
        px: 2,
        py: 1,
        color: "gray.400",
        fontSize: "2xs",
        fontWeight: "normal",
    },
    item: {
        px: 2,
        py: 1,
        w: "full",
        _hover: { bgColor: "brand.50" },
        cursor: "pointer",
    },
    itemText: {
        maxW: "full",
        color: "gray.800",
        fontSize: "sm",
        fontWeight: "normal",
    },
};
const iconSource = new Map<IntegrationFieldApp, string>([
    ["Shopify", ShopifyIcon],
    ["WooCommerce", WooCommerceIcon],
    ["Razorpay", RazorPayIcon],
    ["FbLeads", fbImg],
    ["genericWebhook", WebHook],
    ["Shiprocket", ShipRocket],
    ["Cashfree", CashFree],
    ["WoocommerceCartflow", abandonedCartLogo],
    ["Shipway", ShipWay],
    ["ZohoBooks", zohoImg],
    ["Calendly", Calendly],
    ["Shopflo", ShopfloLogo],
]);

interface VariableListItemProps {
    onSelect: (expression: string) => void;
    variables?: Partial<IntegrationField>[];
    isLoading?: boolean;
}

export const VariableListItem: FC<VariableListItemProps> = (props) => {
    const { variables, onSelect, isLoading } = props;
    const [searchValue, setSearchValue] = React.useState<string>("");
    const appName = variables?.[0]?.app as IntegrationFieldApp;

    const handleSelect = (value: string) => {
        onSelect(value);
        setSearchValue("");
    };

    const filteredVariableList = React.useMemo(() => {
        if (!searchValue) return variables;
        if (searchValue) {
            return variables?.filter((v) => v.label?.toLowerCase().includes(searchValue.toLowerCase()));
        }
    }, [searchValue, variables]);

    return (
        <VStack w="full" align="flex-start" position="relative">
            <VStack w="full" align="flex-start">
                <Box bg="white" position="sticky" top={0} w="full" p={1}>
                    <InputGroup size="sm">
                        <InputLeftElement pointerEvents="none">
                            <Icon as={HiOutlineSearch} color="gray.500" />
                        </InputLeftElement>
                        <Input
                            placeholder="Search a variable"
                            autoComplete="off"
                            bgColor="white"
                            borderRadius="6px"
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                            autoFocus
                            type="search"
                            required={false}
                        />
                    </InputGroup>
                </Box>
                <VStack w="full" align="flex-start" spacing={1}>
                    {/* <Text sx={styles.heading}>{`${appName} variables`}</Text> */}
                    {filteredVariableList?.map((v) => (
                        <HStack key={v.id} sx={styles.item} onClick={() => handleSelect(v.name as string)}>
                            <Image height="15px" src={iconSource.get(appName)} />
                            <Text sx={styles.itemText} noOfLines={1}>
                                {v.label}
                            </Text>
                        </HStack>
                    ))}
                    {filteredVariableList?.length === 0 ? <Text sx={styles.heading}>No option found</Text> : null}
                </VStack>
            </VStack>
            {isLoading && (
                <Grid bg="white" w="full" h="full" top={0} left={0} placeItems="center" position="absolute">
                    <GallaboxLoadingState />
                </Grid>
            )}
        </VStack>
    );
};

export default VariableListItem;
