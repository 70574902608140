import { Helmet } from "react-helmet";
import React, { useEffect, useMemo } from "react";
import { useAccountId } from "app/utils/react-helpers";
import { Integration } from "../Integration/types/integration";
import { WindowObjWithMiitel } from "./types";
import { useGetMiitelIntegration } from "../Integration/fetchHooks/integration";
import { SocketEvents, useSocket } from "app/utils/use-socketio";
import { useQueryClient } from "react-query";
import { QueryKey } from "app/types";
import useMiitelWidget from "./useMiitelWidget";
import UserAvatar from "app/components/CustomAvatar";
import {
    Button,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { ModalBody } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { setIsIntegrationEnabled } from "app/modules/miitel";
import { removeElementById } from "app/utils/common";

const MiitelWidget = () => {
    const accountId = useAccountId();
    const dispatch = useDispatch();

    const { data } = useGetMiitelIntegration({
        accountId,
        staleTime: 1000 * 60 * 60,
        enabled: Boolean(accountId),
    });
    const queryClient = useQueryClient();
    const history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [integration] = (data ?? []) as Integration[];
    const { isBusy, incomingCallInfo, isIncoming } = useMiitelWidget();
    const { accessToken, companyId } = integration?.miitel ?? {};

    useEffect(() => {
        if (!(companyId && accessToken)) return;
        dispatch(setIsIntegrationEnabled(true));
        const iframe = document.getElementById("miitelPhoneIframe") as HTMLIFrameElement;
        if (iframe?.src) {
            iframe.src = iframe?.src;
        }
        const miitelWindow = window as WindowObjWithMiitel;
        miitelWindow.miitelWidget =
            miitelWindow.miitelWidget ||
            ((k, v) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                miitelWindow.miitelWidget!.conf = miitelWindow.miitelWidget!.conf || {};
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                miitelWindow.miitelWidget!.conf[k] = v;
            });

        miitelWindow.miitelWidget("company_id", companyId);
        miitelWindow.miitelWidget("access_key", accessToken);

        return () => {
            removeElementById("miitelPhoneIFrameButton");
            removeElementById("miitelPhoneIFrameOuter");
            removeElementById("miitelScript");
        };
    }, [companyId, accessToken]);

    useEffect(() => {
        if (isBusy && isIncoming) {
            onOpen();
        } else {
            onClose();
        }
    }, [isBusy, onClose, onOpen, isIncoming]);

    useSocket(SocketEvents.MiitelIntegrationAction, (value) => {
        if (value.action === "create" || value.action === "update") {
            queryClient.setQueryData([QueryKey.MiitelIntegration, { accountId }], [value.integration]);
            dispatch(setIsIntegrationEnabled(true));
            return;
        }
        if (value.action === "delete") {
            queryClient.removeQueries([QueryKey.MiitelIntegration, { accountId }]);

            dispatch(setIsIntegrationEnabled(false));
            removeElementById("miitelPhoneIFrameButton");
            removeElementById("miitelPhoneIFrameOuter");
            removeElementById("miitelScript");
        }
    });

    const channelId = useMemo(() => {
        return integration?.miitel?.config.find((a) => a.circuitNumber === incomingCallInfo.circuitNumber)?.channelId;
    }, [integration, incomingCallInfo]);

    if (!integration?.miitel) {
        return null;
    }
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader borderBottomWidth="1px" p="4" borderColor="gray.200">
                        Incoming Call - MiiTel
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody as={VStack} spacing="4">
                        <UserAvatar
                            size="md"
                            name={incomingCallInfo.userName}
                            colorKey={incomingCallInfo.phoneNumber || "unknown"}
                        />
                        <VStack spacing="1">
                            <Text fontSize="sm" fontWeight="500" color="gray.700">
                                {incomingCallInfo.userName}
                            </Text>
                            <Text fontSize="xs" fontWeight="400" color="gray.700">
                                {incomingCallInfo.phoneNumber}
                            </Text>
                        </VStack>
                    </ModalBody>
                    <ModalFooter borderTopWidth="1px" p="3" borderColor="gray.200" as={VStack}>
                        <Button
                            colorScheme="brand"
                            onClick={() => {
                                history.push(
                                    `/conversations/phone/${incomingCallInfo.phoneNumber}?channelId=${channelId}`
                                );
                                onClose();
                            }}
                        >
                            Go to conversation
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {accessToken && companyId ? (
                <Helmet key={`${accessToken} - ${companyId}`}>
                    <script async src="https://api.miitel.jp/static/widget/v1.js" id="miitelScript"></script>
                </Helmet>
            ) : null}
        </>
    );
};

export default MiitelWidget;
