import { useCheckAbility } from "app/hooks/useCheckAbility";
import React from "react";
import { NavigationItem, SubNavigationGroup } from "../types";

interface UseGetUserNavItemType {
    getUserNavItems: (navigationItem: NavigationItem) => NavigationItem | undefined;
}

export const useGetUserNavItems = (): UseGetUserNavItemType => {
    const { checkAbility } = useCheckAbility();

    const getAccessableNavigationItem = React.useCallback(
        (navigationItem: NavigationItem): NavigationItem | undefined => {
            const { action, subject, hide } = navigationItem;
            const isSubNav = navigationItem.type === "sub-navigation";
            if (hide) return undefined;
            if (isSubNav || !action || !subject) return navigationItem;

            return checkAbility(action, subject) ? navigationItem : undefined;
        },
        [checkAbility]
    );

    const getSubNavItems = React.useCallback(
        (navigationItem: NavigationItem) => {
            if (navigationItem.type !== "sub-navigation") return;

            const subNav = navigationItem.subNavigation.children;

            const accessableSubNavChildren = subNav?.reduce((acc, curr) => {
                const accessableChildren: SubNavigationGroup["children"] = curr.children.reduce(
                    (childrenAcc, currChild) => {
                        if (currChild.hide) return childrenAcc;

                        if (currChild.subject && currChild.action) {
                            return checkAbility(currChild.action, currChild.subject)
                                ? [...childrenAcc, currChild]
                                : childrenAcc;
                        }
                        if (childrenAcc.length < 1 && currChild.type === "divider") return childrenAcc;

                        return [...childrenAcc, currChild];
                    },
                    [] as SubNavigationGroup["children"]
                );

                if (curr.children.length > 0 && accessableChildren.length > 0) {
                    const validatedSubNav: SubNavigationGroup = {
                        ...curr,
                        children: accessableChildren,
                    };

                    return [...acc, validatedSubNav];
                }
                return acc;
            }, [] as SubNavigationGroup[]);

            const isOnlyDividers = accessableSubNavChildren.every((child) => {
                return child.children.every((subChild) => subChild.type === "divider");
            });

            if (accessableSubNavChildren.length < 1 || isOnlyDividers) return;

            return {
                subNavigation: { ...navigationItem.subNavigation, children: accessableSubNavChildren },
            };
        },
        [checkAbility]
    );

    const getUserNavItems = React.useCallback(
        (navigationItem: NavigationItem): NavigationItem | undefined => {
            const accessableNavigationItem = getAccessableNavigationItem(navigationItem);
            if (!accessableNavigationItem) return;
            if (accessableNavigationItem.type !== "sub-navigation") return accessableNavigationItem;

            const subNavItems = getSubNavItems(navigationItem);
            if (subNavItems) {
                return {
                    ...accessableNavigationItem,
                    subNavigation: subNavItems.subNavigation,
                } as NavigationItem;
            }
            return;
        },
        [getAccessableNavigationItem, getSubNavItems]
    );

    return {
        getUserNavItems,
    };
};
