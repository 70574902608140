import {
    AvatarBadge,
    Box,
    Button,
    Divider,
    HStack,
    Icon,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverTrigger,
    Spacer,
    Switch,
    Text,
    VStack,
} from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import InfoTooltip from "app/components/InfoTooltip";
import LoadingState from "app/components/LoadingState";
import ProfileCard from "app/components/ProfileCard";
import PushNotificationLink from "app/components/PushNotification/PushNotificationLink";
import { useUserAvailability } from "app/fetchHooks/user-availability";
import { AvailabilityType } from "app/types/common";
import { LoggedInUser } from "app/types/user";
import { useAccount } from "app/utils/react-helpers";
import "driver.js/dist/driver.min.css";
import { ChevronRight, LucideEclipse } from "lucide-react";
import React, { useEffect, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
import NavigationButton, { NavigationButtonProps } from "./components/NavigationButton";
import { NavigationItemProps } from "./components/NavigationItem";
import { useGetUserNavItems } from "./hooks/useGetUserNavItems";
import { useNavigationList } from "./hooks/useNavigationList";
import { NavigationItem, RoutableNavigationItem } from "./types";
import { LuBellRing } from "react-icons/lu";
import { handleNetworkError } from "app/utils/fetchUtils";

const popoverNavBtnProps: Partial<NavigationButtonProps> = {
    size: "sm",
    fontWeight: "normal",
    fontSize: "2xs",
    rounded: "none",
    justifyContent: "start",
    bg: "transparent",
    w: "full",
    color: "gray.600",
    iconProps: { color: "gray.700" },
};

const activeUser = {
    bg: "green.500",
    rounded: "full",
    boxSize: "2",
};
interface ProfilePopoverProps extends Omit<NavigationItemProps, "navigationItem"> {
    user: LoggedInUser;
    isExpanded: boolean;
}
const ProfilePopover: React.FC<ProfilePopoverProps> = (props) => {
    const { user, isExpanded, setSubNav } = props;

    const [isActive, setActive] = useState<boolean>(user?.accounts?.[0]?.availability === "Available");
    const [isAlertOpen, setAlertOpen] = useState(false);
    const account = useAccount();
    const { getUserNavItems } = useGetUserNavItems();
    const { navigationItems } = useNavigationList();

    const getSanitizedNavBtnProps = React.useCallback(
        (navItem: NavigationItem) => {
            const accessableItems = getUserNavItems(navItem);
            if (!accessableItems) return;
            const { buttonProps, ...otherNavItemProps } = accessableItems;
            return {
                ...otherNavItemProps,
                ...(buttonProps ?? {}),
            };
        },
        [getUserNavItems]
    );

    const {
        accountNavItem,
        messageCreditNavItem,
        profileNavItem,
        subscriptionNavItem,
        userNavItem,
        notificationSettings,
        logoutNavItem,
    } = React.useMemo(() => {
        return {
            accountNavItem: getSanitizedNavBtnProps(navigationItems.account),
            subscriptionNavItem: getSanitizedNavBtnProps(navigationItems.subscription),
            messageCreditNavItem: getSanitizedNavBtnProps(navigationItems["messsage-credit"]),
            userNavItem: getSanitizedNavBtnProps(navigationItems.user),
            profileNavItem: getSanitizedNavBtnProps(navigationItems.profile),
            logoutNavItem: getSanitizedNavBtnProps(navigationItems.logout),
            notificationSettings: getSanitizedNavBtnProps(navigationItems["notification-settings"]),
        } as Record<string, RoutableNavigationItem>;
    }, [getSanitizedNavBtnProps, navigationItems]);

    const onCloseRef = React.useRef<(() => void) | null>(null);

    useEffect(() => {
        setActive(user?.accounts?.[0]?.availability === "Available");
    }, [user]);

    const onProfileItemClick = React.useCallback(() => {
        setSubNav(null);
        onCloseRef?.current?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSuccess = () => {
        setActive((prev) => !prev);
    };

    React.useEffect(() => {
        if (isExpanded) return;
        onCloseRef?.current?.();
    }, [isExpanded]);

    const onAlertClose = () => {
        setAlertOpen(false);
    };
    useEffect(() => {
        setAlertOpen(user?.accounts?.[0]?.availability === "Away");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { mutateAsync: updateUserAvailability, isLoading } = useUserAvailability({
        accountId: account?.id as string,
        userId: user?.id,
        onSuccess,
    });

    const onAvailabilityChange = React.useCallback(async (statusToUpdate: AvailabilityType) => {
        await updateUserAvailability({ availability: statusToUpdate }).catch(handleNetworkError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const alertAvailability = React.useMemo(() => {
        return (
            <Popover isOpen={isAlertOpen} autoFocus={false} placement="bottom-start">
                {isAlertOpen ? (
                    <Box
                        pos="fixed"
                        bgColor={"blackAlpha.600"}
                        top="0"
                        bottom="0"
                        left="-1"
                        right="0"
                        onMouseEnter={(e) => e.stopPropagation()}
                        onMouseLeave={(e) => e.stopPropagation()}
                    />
                ) : null}
                <PopoverTrigger>
                    <Box h={1} pos="absolute" bottom="50px" left="20px" />
                </PopoverTrigger>
                <PopoverContent zIndex="1000">
                    <PopoverArrow />
                    <PopoverBody>
                        <Text fontSize={"sm"} color="gray.700">
                            <Trans>Did you forget to turn on your Availability status?</Trans>
                        </Text>
                    </PopoverBody>
                    <PopoverFooter border={"none"} pt="0" pb="4">
                        <HStack spacing={2}>
                            <Button
                                onClick={async () => {
                                    await onAvailabilityChange("Available");
                                    onAlertClose();
                                }}
                                isLoading={isLoading}
                                borderRadius="md"
                                colorScheme="brand"
                                variant="solid"
                                size="xs"
                            >
                                <Trans>Turn On Now</Trans>
                            </Button>
                            <Button onClick={onAlertClose} borderRadius="md" variant="outline" size="xs">
                                <Trans>Not Now</Trans>
                            </Button>
                        </HStack>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
        );
    }, [isAlertOpen, isLoading, onAvailabilityChange]);

    return (
        <>
            <Popover placement="right" size="xs">
                {({ onClose }) => {
                    if (!onCloseRef.current) onCloseRef.current = onClose;
                    return (
                        <>
                            <PopoverTrigger>
                                <Button
                                    variant="unstyled"
                                    pl={"3"}
                                    pr={5}
                                    size="xl"
                                    h="46px"
                                    minH="46px"
                                    py="1.5"
                                    w="full"
                                    _hover={{ bg: "gray.100" }}
                                    cursor="pointer"
                                    data-cy="navigation-profile-menu"
                                    borderTopWidth="1px"
                                    borderTopColor="gray.200"
                                    borderTopStyle="solid"
                                    rounded="none"
                                >
                                    <ProfileCard
                                        name={user?.name}
                                        src={account?.logoUrl}
                                        colorKey={account?.id}
                                        value1={isExpanded ? account?.name : undefined}
                                        value2={
                                            isExpanded ? (
                                                <Text
                                                    title={user?.name}
                                                    fontSize="0.625rem"
                                                    fontWeight="light"
                                                    maxW="full"
                                                    isTruncated
                                                >
                                                    {user?.name}
                                                </Text>
                                            ) : undefined
                                        }
                                        size={"xs"}
                                    >
                                        {isActive ? (
                                            <AvatarBadge boxSize="3" bg="white" rounded="full">
                                                <Box w="2" h="2" bg="green.600" rounded="full" />
                                            </AvatarBadge>
                                        ) : (
                                            <AvatarBadge boxSize="3" bg="white">
                                                <Icon as={IoCloseCircle} w="3" h="3" />
                                            </AvatarBadge>
                                        )}
                                    </ProfileCard>
                                </Button>
                            </PopoverTrigger>

                            {!isAlertOpen && isExpanded ? (
                                <PopoverContent rounded={"none"} mb={2} ml={"-10px"}>
                                    <PopoverBody boxShadow="lg" p={1}>
                                        <VStack w="full" justify="start" spacing={0}>
                                            <VStack w="full" justify="start" spacing={0} pb="2">
                                                <Box px="2.5" py="3" w="full">
                                                    <ProfileCard
                                                        name={account?.name}
                                                        src={account?.logoUrl}
                                                        colorKey={account?.id}
                                                        value1={account?.name}
                                                        value2={account?.website}
                                                        value2Type="link"
                                                    />
                                                </Box>
                                                {accountNavItem && (
                                                    <NavigationButton
                                                        {...popoverNavBtnProps}
                                                        {...accountNavItem}
                                                        onClick={onProfileItemClick}
                                                    />
                                                )}

                                                {subscriptionNavItem && (
                                                    <NavigationButton
                                                        {...popoverNavBtnProps}
                                                        {...subscriptionNavItem}
                                                        onClick={onProfileItemClick}
                                                    />
                                                )}
                                                {messageCreditNavItem && (
                                                    <NavigationButton
                                                        {...popoverNavBtnProps}
                                                        {...messageCreditNavItem}
                                                        onClick={onProfileItemClick}
                                                    />
                                                )}
                                                {userNavItem && (
                                                    <NavigationButton
                                                        {...popoverNavBtnProps}
                                                        {...userNavItem}
                                                        onClick={onProfileItemClick}
                                                    />
                                                )}
                                            </VStack>
                                            <Divider />
                                            <Box px="2.5" py="3" w="full">
                                                <ProfileCard
                                                    name={user.name}
                                                    src={user.picture}
                                                    colorKey={user?.id}
                                                    value1={user.name}
                                                    value2={user.email}
                                                    badgeContent={user.accounts?.[0]?.role?.name}
                                                >
                                                    {!isActive ? (
                                                        <AvatarBadge boxSize="4" bg="white">
                                                            <Icon as={IoCloseCircle} w="4" h="4" />
                                                        </AvatarBadge>
                                                    ) : null}
                                                </ProfileCard>
                                            </Box>
                                            {profileNavItem && (
                                                <NavigationButton
                                                    {...popoverNavBtnProps}
                                                    {...profileNavItem}
                                                    onClick={onProfileItemClick}
                                                />
                                            )}
                                            <HStack w="full" pl={isActive ? 0.5 : 0} px={3} align="start" spacing={2}>
                                                <NavigationButton
                                                    title={isActive ? "Active" : "Inactive"}
                                                    icon={isActive ? LucideEclipse : MdOutlineCancel}
                                                    {...popoverNavBtnProps}
                                                    type="none"
                                                    w="max-content"
                                                    _hover={{ bg: "none" }}
                                                    _active={{ bg: "none" }}
                                                    fontSize="sm"
                                                    iconProps={{
                                                        strokeWidth: "0px",
                                                        ...(isActive ? activeUser : {}),
                                                    }}
                                                    iconSpacing={3}
                                                    pr="0"
                                                    pl={0.5}
                                                />
                                                <Box alignSelf="center" mt={1}>
                                                    <InfoTooltip
                                                        text={
                                                            <Text>
                                                                <Trans>
                                                                    You will not receive notifications and team members
                                                                    will not be able to assign conversations to you.{" "}
                                                                    <br />
                                                                    Note: This is a manual switch and can only be turned
                                                                    on by the user.
                                                                </Trans>
                                                            </Text>
                                                        }
                                                        placement="top"
                                                        infoIconProps={{ color: "gray.500", fontSize: "md" }}
                                                    />
                                                </Box>
                                                <Spacer />
                                                {isLoading ? (
                                                    <LoadingState />
                                                ) : (
                                                    <Switch
                                                        size="sm"
                                                        mt={1.5}
                                                        id="switch-action"
                                                        onChange={() =>
                                                            onAvailabilityChange(isActive ? "Away" : "Available")
                                                        }
                                                        isChecked={isActive}
                                                    />
                                                )}
                                            </HStack>
                                            <Box w="full" pr="3" pl="3">
                                                <PushNotificationLink />
                                            </Box>

                                            <NavigationButton
                                                {...popoverNavBtnProps}
                                                {...notificationSettings}
                                                icon={undefined}
                                                onClick={onProfileItemClick}
                                                titleComp={
                                                    <HStack w="full" justifyContent={"space-between"}>
                                                        <HStack spacing={2}>
                                                            <Box color={"gray.600"} mt={1.5}>
                                                                <Icon
                                                                    as={LuBellRing}
                                                                    fontSize="md"
                                                                    strokeWidth="1.4px"
                                                                    color="gray.600"
                                                                />
                                                            </Box>
                                                            <Text fontSize="sm" color={"gray.600"}>
                                                                <Trans>Notification Settings</Trans>
                                                            </Text>
                                                        </HStack>
                                                        <Icon
                                                            as={ChevronRight}
                                                            fontSize="md"
                                                            strokeWidth="1.4px"
                                                            color="gray.600"
                                                        />
                                                    </HStack>
                                                }
                                            />

                                            <Divider />

                                            {logoutNavItem && (
                                                <NavigationButton
                                                    {...popoverNavBtnProps}
                                                    {...logoutNavItem}
                                                    color="red.400"
                                                    onClick={onProfileItemClick}
                                                />
                                            )}
                                        </VStack>
                                    </PopoverBody>
                                </PopoverContent>
                            ) : null}
                        </>
                    );
                }}
            </Popover>
            {alertAvailability}
        </>
    );
};

export default ProfilePopover;
