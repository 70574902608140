import { Box, Grid, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import { SearchBox } from "app/components/Button/CustomButton";
import EmptyStateCentered from "app/components/EmptyStateCentered";
import GallaboxLoadingState from "app/components/GBLoadingState";
import { GBPickWaTemplateMsgWrap } from "app/components/TemplateMsgPreview/GBWaTemplateMsgWrap";
import { useWATemplateList } from "app/fetchHooks/whatsapp-template";
import { WATemplate } from "app/types";
import { useAccountId, useIntersectionObserver } from "app/utils/react-helpers";
import React, { useRef, useState } from "react";
import WATemplatePreview from "app/screens/Account/WATemplatesV2/component/WATemplatePreview";
import InfiniteLoaderComponent from "./InfiniteLoaderComponent";
import InfoLabel from "app/components/InfoLabel";
import { Trans } from "@lingui/macro";

interface PickFromExistingTemplatesProps {
    channelId?: string;
    handleTemplate: (template: WATemplate) => void;
    enableAuthenticationTemplate?: boolean;
}

const PickFromExistingTemplates: React.FC<PickFromExistingTemplatesProps> = (props) => {
    const [value, setValue] = useState<string>("");
    const { channelId, handleTemplate, enableAuthenticationTemplate } = props;
    const accountId = useAccountId();
    const [showSelectTick, setShowSelectTick] = useState<boolean>(true);

    const handleSelectTick = React.useCallback((value: boolean) => setShowSelectTick(value), []);

    const {
        data: WaTemplateList,
        isLoading: TemplateLoading,
        isFetchingNextPage,
        fetchNextPage,
        status,
        hasNextPage,
    } = useWATemplateList({
        accountId,
        channelId,
        search: value,
        ignoreSampleTemplates: false,
        status: "approved",
    });
    const templates = (WaTemplateList?.pages?.flat() as WATemplate[] | undefined)?.filter((c) => c);
    const isNoAvailableTemplates = templates && templates?.length === 0;
    const target = useRef<HTMLDivElement>(null);
    useIntersectionObserver({
        onIntersect: () => {
            if (!isFetchingNextPage) {
                fetchNextPage();
            }
        },
        target,
        enabled: status === "success" && hasNextPage,
        threshold: 1,
    });

    const templateContent = React.useMemo(() => {
        if (TemplateLoading)
            return (
                <Grid placeItems="center" bg="white" w="full" h="full" maxH="full" maxW="full">
                    <GallaboxLoadingState title="Loading..." spinnerSize={30} />
                </Grid>
            );

        if (isNoAvailableTemplates) {
            return (
                <EmptyStateCentered
                    title={value ? `There is No data available for "${value}"` : "There is No data available"}
                    titleProps={{ fontSize: "md" }}
                />
            );
        }
    }, [TemplateLoading, isNoAvailableTemplates, value]);

    return (
        <VStack py="15px" width="full">
            <HStack w="full">
                <Box w="350px">
                    <Text w="full" fontSize="18px" fontWeight="bold">
                        WhatsApp Templates
                    </Text>
                </Box>
                <Spacer />
                <Box w="350px">
                    <SearchBox
                        inputProps={{ borderRadius: "6px", borderColor: "gray.400" }}
                        value={value}
                        onChange={setValue}
                        delay={500}
                        placeholder="Search by Templates"
                    />
                </Box>
            </HStack>
            <InfoLabel
                containerStyle={{ alignSelf: "flex-start" }}
                content={
                    <Trans>Carousel and Limited Time Offer templates are currently not supported in integrations</Trans>
                }
            />

            {templateContent}
            <>
                <Grid w="full" templateColumns="repeat(auto-fill, minmax(272px, 1fr))" gap={4}>
                    {templates?.map((template, index) => {
                        const { name, category, language, status, createdAt, id, components } = template ?? {};
                        const filteredComponents = components.filter(
                            (component) => component.type === "CAROUSEL" || component.type === "LIMITED_TIME_OFFER"
                        );
                        // if (filteredComponents.length) return null;
                        return (
                            <GBPickWaTemplateMsgWrap
                                templateData={template}
                                key={id}
                                data-cy={`toolbar-popover-item-whatsapp-template-${index}`}
                                templateName={name ?? ""}
                                category={category}
                                language={language}
                                status={status}
                                createdAt={createdAt as string}
                                onSelect={() => handleTemplate(template)}
                                showSelectTick={showSelectTick}
                                setShowSelectTick={handleSelectTick}
                                enableAuthenticationTemplate={enableAuthenticationTemplate}
                            >
                                <WATemplatePreview
                                    components={components ?? []}
                                    language={language}
                                    showBodyVariable={true}
                                />
                            </GBPickWaTemplateMsgWrap>
                        );
                    })}
                </Grid>
                {hasNextPage && (
                    <InfiniteLoaderComponent
                        hasNextPage={hasNextPage}
                        targetRef={target}
                        onClick={() => fetchNextPage()}
                        isFetchingNextPage={isFetchingNextPage}
                    />
                )}
            </>
        </VStack>
    );
};

export default PickFromExistingTemplates;
