import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/react";
import { useArrayPagination } from "app/hooks/useArrayPagination";
import useLocalStorage from "app/hooks/useLocalStorage";
import { useAccountId } from "app/utils/react-helpers";
import { useSteps } from "chakra-ui-steps";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import QandA from "./QandA";
import { OnboardingQAType, OnboardingQuestionType, questions } from "./QandA/type";
import { OnboardingPageType, onboardingPages } from "./types";
import ValidateConnect from "./ValidateConnect";

export const canIConnect = (ans: OnboardingQAType | undefined) => {
    if (!ans) return false;
    const userAnsArray = (ans && (Object.entries(ans) as Array<[OnboardingQuestionType, boolean]>)) ?? [];
    const canIConnect = userAnsArray.length === 3 && userAnsArray.every(([key, val]) => val);
    return canIConnect;
};

interface NewFreeTrialModalProps {
    isOpen: boolean;
    onClose: () => void;
}
const NewFreeTrialModal: React.FC<NewFreeTrialModalProps> = (props) => {
    const { isOpen, onClose } = props;
    const history = useHistory();
    const location = useLocation();
    const accountId = useAccountId();

    const [onboardingQALocalStorage, setOnboardingQALocalStorage] = useLocalStorage<OnboardingQAType | undefined>(
        `onboarding-question-ans-${accountId}`,
        undefined,
        {}
    );

    const [isSandboxQusAnswered, setSandboxQusAnswered] = useLocalStorage<boolean>(
        `sandbox-ques-answered-${accountId}`,
        canIConnect(onboardingQALocalStorage),
        {}
    );

    const {
        currentData: step,
        goToNext,
        goToPrevious,
    } = useArrayPagination<OnboardingPageType>({
        data: onboardingPages.filter((pages) => pages !== "intro"),
        type: "no-loop",
        defaultIndex: 0,
    });

    const userAnswerRef = React.useRef<OnboardingQAType | undefined>(onboardingQALocalStorage);

    React.useEffect(() => {
        if (!userAnswerRef.current) return;
        setOnboardingQALocalStorage(userAnswerRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAnswerRef.current]);

    React.useEffect(() => {
        const unlisten = history.listen(() => {
            onClose();
            if (step === "connect") userAnswerRef.current = undefined;
        });
        return () => {
            unlisten();
        };
    }, [history, onClose, step]);

    const stepProps = useSteps({
        initialStep: 0,
    });

    const setAnswer = React.useCallback(
        (qusKey: keyof OnboardingQAType, val: boolean) => {
            const newData: OnboardingQAType = { [qusKey]: val } as OnboardingQAType;
            const updatedAns = { ...(userAnswerRef.current ?? {}), ...newData };
            userAnswerRef.current = updatedAns;

            if (qusKey === "fb-manager-access") {
                setSandboxQusAnswered(true);
                if (!canIConnect(updatedAns)) {
                    goToNext();
                    return;
                }
                history.push("/channels/whatsapp/list");
            }
        },
        [goToNext, history, setSandboxQusAnswered]
    );

    const onQuesBack = React.useCallback(() => {
        const activeQuestion = questions[stepProps.activeStep];
        const { [activeQuestion]: qus, ...rest } = userAnswerRef.current ?? {};
        const updatedData = Object.keys(rest).length > 0 ? rest : undefined;

        userAnswerRef.current = updatedData;

        stepProps.prevStep();
    }, [stepProps]);

    if (
        isOpen &&
        isSandboxQusAnswered &&
        (!userAnswerRef.current || (userAnswerRef.current && canIConnect(userAnswerRef.current)))
    ) {
        onClose();
        if (location.pathname !== "/channels/whatsapp/list") history.push("/channels/whatsapp/list");
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                if (stepProps.activeStep < 2) stepProps.reset();
                if (step === "connect") userAnswerRef.current = undefined;
                onClose();
            }}
            size="2xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody p={10}>
                    {step === "questions" && (
                        <QandA
                            setAnswer={setAnswer}
                            onBack={onClose}
                            containerProps={{ pt: 5 }}
                            backButtonProps={{ top: "-46px", left: "8px" }}
                            {...stepProps}
                        />
                    )}
                    {step === "connect" && userAnswerRef.current && (
                        <ValidateConnect userAnswer={userAnswerRef.current} type="modal" onBack={goToPrevious} />
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default NewFreeTrialModal;
