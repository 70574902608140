import { VStack, Tabs, TabList, Tab, TabPanels, TabPanel, Grid, TabProps } from "@chakra-ui/react";
import GallaboxLoadingState from "app/components/GBLoadingState";
import { useHistory } from "react-router-dom";
import { IntegrationTabStyles } from "app/screens/Integration/styles";
import React from "react";

interface IntegrationTabProps {
    tabData: { label: string; Component: React.JSX.Element; tabProps?: TabProps }[];
    isLoading: boolean;
    activeTabIndex: number;
    isError?: boolean;
}

export const IntegrationTab = ({ tabData, isLoading, isError, activeTabIndex }: IntegrationTabProps) => {
    const [tabIndex, setTabIndex] = React.useState<number>(0);
    const history = useHistory();

    const handleTabIndexChange = React.useCallback<React.Dispatch<React.SetStateAction<number>>>(
        (index) => {
            history.push({ pathname: history.location.pathname, search: `?tab=${index}` });
            setTabIndex(index);
        },
        [history]
    );

    React.useEffect(() => {
        if (!isNaN(Number(activeTabIndex))) {
            setTabIndex(Number(activeTabIndex));
        }
    }, [activeTabIndex]);

    if (isLoading)
        return (
            <VStack width="full" height="full" align="center" justify="center">
                <GallaboxLoadingState title="Loading..." spinnerSize={20} />
            </VStack>
        );

    if (isError)
        return (
            <Grid placeItems="center" color="red.500">
                Something went wrong! Please reload the page or try again after sometime.
            </Grid>
        );

    return (
        <Tabs index={tabIndex} onChange={handleTabIndexChange} isLazy w="100%" lazyBehavior="keepMounted">
            <TabList sx={IntegrationTabStyles.tabList}>
                {tabData.map((each) => {
                    return (
                        <Tab
                            key={each.label}
                            _selected={IntegrationTabStyles.tabSelected}
                            fontSize="md"
                            fontWeight="semibold"
                            {...each.tabProps}
                        >
                            {each.label as string}
                        </Tab>
                    );
                })}
            </TabList>
            <TabPanels>
                {tabData.map((each) => {
                    return <TabPanel key={each.label} sx={IntegrationTabStyles.tabPanel}>{each.Component}</TabPanel>;
                })}
            </TabPanels>
        </Tabs>
    );
};
