import {
    Box,
    Grid,
    GridItem,
    HStack,
    Image,
    Skeleton,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
} from "@chakra-ui/react";
import { Integration, IntegrationTabsLabel } from "app/screens/Integration/types/integration";
import { useQueryParam } from "app/utils/history";
import Icon1 from "assets/Icon-1.svg";
import Icon2 from "assets/Icon-2.svg";
import Icon4 from "assets/Icon-4.svg";
import Icon5 from "assets/Icon-5.svg";
import Icon3 from "assets/icon1.png";
import React, { FC } from "react";
import LogTable from "app/screens/Integration/components/LogTable";
import { CalendlyConfiguration } from "./CalendlyConfiguration";
import CalendlyUseCase from "./CalendlyUseCase";
import { Overview } from "app/screens/Integration/components/Overview";
import { IntegrationTab } from "app/screens/Integration/components/IntegrationTab";
interface CalendlyTabsProps {
    data: Integration | undefined;
    isLoading: boolean;
}

export const calendlyDetails = [
    {
        icon: Icon1,
        text: "Send Event Confirmation Messages",
    },
    { icon: Icon2, text: "Send Event Reminder Messages" },
    { icon: Icon3, text: "Ask Feedback for the event" },
    { icon: Icon4, text: "Send WhatsApp Broadcast to Targeted Customers" },
    { icon: Icon5, text: "Send a Sequence of Follow-up Messages post event" },
];

export const CalendlyTabs: FC<CalendlyTabsProps> = (props) => {
    const { data, isLoading } = props;
    const [tabIndex, setTabIndex] = React.useState<number>(0);
    const { id: integrationId, calendly, accountId } = data ?? {};
    const { channelId } = calendly ?? {};
    const params = useQueryParam();
    const selectedTabIndex = params.get("tab") ?? undefined;


    React.useEffect(() => {
        if (integrationId) setTabIndex(1);
        if (channelId && integrationId) setTabIndex(2);
        if (!isNaN(Number(selectedTabIndex))) {
            setTabIndex(Number(selectedTabIndex));
        }
    }, [channelId, integrationId, selectedTabIndex]);

    const overviewDescription = `Gallabox helps you to integrate your Official WhatsApp Business Account with Calendly
                                and send automated schedule-related notifications to your customers.`

    const tabData:any = React.useMemo(() => {
        return [
            {
                label: IntegrationTabsLabel.OVERVIEW,
                Component: <Overview description={overviewDescription} details={calendlyDetails} />
            },
            {
                label: IntegrationTabsLabel.CONFIGURATION,
                Component: <CalendlyConfiguration data={data} isLoading={isLoading} />,
                tabProps: {hidden: !integrationId}
            },
            {
                label: IntegrationTabsLabel.WORKFLOW,
                Component: <CalendlyUseCase data={data} isLoading={isLoading} />,
                tabProps: {hidden: !integrationId || !channelId}
            },
            {
                label: IntegrationTabsLabel.LOGS,
                Component: <LogTable accountId={accountId} integrationId={integrationId} integrationType="calendly" />,
                tabProps: {hidden: !integrationId || !channelId}
            }
        ]
    }, [data])

    return (
        <Box px={6}>
            <IntegrationTab
                tabData={tabData}
                activeTabIndex={tabIndex}
                isLoading={isLoading}
            />
        </Box>
    );
};
