import { Divider } from "@chakra-ui/react";
import React from "react";
import { useGetUserNavItems } from "../hooks/useGetUserNavItems";
import { useNavigationList } from "../hooks/useNavigationList";
import { NavigationMenuList, SubNavigationItemType } from "../types";
import NavigationItem from "./NavigationItem";

export interface NavigationItemsProps {
    collapseNavigation: () => void;
    setSubNav: (subNav: SubNavigationItemType | null) => void;
    isExpanded: boolean;
}

const NavigationItems: React.FC<NavigationItemsProps> = (props) => {
    const { navigationItems } = useNavigationList();
    const { getUserNavItems } = useGetUserNavItems();

    const menuGroups: NavigationMenuList[][] = [
        ["get-started", "conversations", "contacts"],
        ["broadcast", "sequence", "bots", "commerce", "flows"],
        ["integration", "partner", "analytics"],
    ];
    return (
        <>
            {menuGroups
                .map((group) =>
                    group
                        .map((item) => {
                            const navigationItem = navigationItems[item];
                            const hasAccess = getUserNavItems(navigationItem);
                            if (!hasAccess) return null;
                            return (
                                <NavigationItem {...props} navigationItem={navigationItem} key={navigationItem.title} />
                            );
                        })
                        .filter((item) => item != null)
                )
                .reduce((acc, curr, idx, arr) => {
                    acc.push(...curr);
                    if (arr[idx + 1]?.length) {
                        acc.push(<Divider key={`divider-${idx}`} alignSelf="center" borderColor="gray.300" />);
                    }
                    return acc;
                }, [] as React.ReactNode[])}
        </>
    );
};

export default React.memo(NavigationItems);
