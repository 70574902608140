import { FormControl, FormErrorMessage, useToast } from "@chakra-ui/react";
import { SelectOption } from "app/types/common";
import { mergeRefs, useAccountId } from "app/utils/react-helpers";
import React from "react";
import { OptionTypeBase, ValueType } from "react-select";
import { SelectedTag } from "./TagSelect";
import { useGetTagList } from "app/fetchHooks/contact";
import { FieldError } from "react-hook-form";
import { useCreateTag } from "app/fetchHooks/tag";
import debounce from "debounce-promise";
import AsyncCreatableSelectStyled from "../CustomizedReactSelect/AsyncCreatableSelectStyled";
import AsyncSelectStyled, { AsyncSelectStyledProps } from "../CustomizedReactSelect/AsyncSelectStyled";
import { handleNetworkError, handleNetworkErrorReturnArray } from "app/utils/fetchUtils";
import { useCheckAbility } from "app/hooks/useCheckAbility";
import { Trans } from "@lingui/macro";

export type TagSelectOption = SelectedTag & SelectOption;

interface TagMultiSelectProps extends Omit<AsyncSelectStyledProps<OptionTypeBase, true>, "loadOptions"> {
    onSelect: (value: SelectedTag[]) => void;
    defaultValue?: TagSelectOption[];
    error?: FieldError | undefined;
    isCreatable?: boolean;
    "data-cy"?: string;
}

// eslint-disable-next-line react/display-name
const TagMultiSelect: React.FC<TagMultiSelectProps> = React.forwardRef((props, ref) => {
    const { onSelect, error, isCreatable, defaultValue, ...asyncSelectProps } = props;

    const [value, setValue] = React.useState<TagSelectOption[]>(defaultValue ?? []);
    const initialFocusRef = React.useRef(null);
    const accountId = useAccountId();
    const { mutateAsync, isLoading } = useGetTagList({ accountId });

    const { canICreateTags } = useCheckAbility();
    const toast = useToast();

    const handleSelect = React.useCallback(
        (value: TagSelectOption[]) => {
            setValue(value);
            onSelect(value);
        },
        [onSelect]
    );

    const { mutateAsync: createTag, isLoading: isCreatingTag } = useCreateTag({
        accountId,
        onSuccess: (tag: SelectedTag) => {
            if (tag.name && tag.id) handleSelect([...value, { label: tag.name, value: tag.id, ...tag }]);
        },
    });

    const loadOptions = React.useCallback(async (inputValue: string) => {
        if (!inputValue) return null;
        const tags = await mutateAsync(inputValue).catch(handleNetworkErrorReturnArray);
        return tags.map((d) => {
            return { value: d.id ?? "unknown", label: d.name };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelection = React.useCallback(
        (val: ValueType<SelectOption, true>) => {
            if (!val) {
                handleSelect([]);
                return;
            }
            const selected = (val as unknown as SelectOption[]).map((v) => {
                return {
                    id: v.value,
                    name: v.label,
                    ...v,
                };
            });
            handleSelect(selected);
        },
        [handleSelect]
    );

    const SelectComp = React.useMemo(() => {
        return isCreatable ? AsyncCreatableSelectStyled : AsyncSelectStyled;
    }, [isCreatable]);

    return (
        <FormControl isInvalid={Boolean(error)}>
            <SelectComp<SelectOption, true>
                ref={mergeRefs(initialFocusRef, ref)}
                isClearable
                cacheOptions={false}
                value={value}
                loadOptions={debounce(loadOptions, 500)}
                isMulti
                onChange={onSelection}
                isLoading={isLoading || isCreatingTag}
                noOptionsMessage={({ inputValue }) => {
                    if (inputValue === "") {
                        return "Type to search tags";
                    }
                    return "No tag found";
                }}
                onCreateOption={(name: string) => {
                    if (canICreateTags) {
                        createTag({ name }).catch(handleNetworkError);
                        return;
                    }
                    toast({
                        status: "error",
                        title: <Trans>Unable to create tag</Trans>,
                        description: <Trans>You do not have permission to create a new tag</Trans>,
                    });
                }}
                {...asyncSelectProps}
            />
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
});

export default TagMultiSelect;
