import { AddIcon, ArrowBackIcon, DeleteIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    ButtonGroup,
    FormLabel,
    HStack,
    Icon,
    IconButton,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Skeleton,
    Spacer,
    Stack,
    Tag,
    TagCloseButton,
    Text,
    Tooltip,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledReactSelectStyled from "app/components/CustomizedReactSelect/ControlledReactSelectStyled";
import { useIntegrationFieldsList } from "app/screens/Integration/fetchHooks/integration";
import { UsePostCreateTemplate } from "app/fetchHooks/message-gallery-template";
import { useWATemplateMessage } from "app/fetchHooks/whatsapp-template";
import { LabelValue, WATemplate, WATemplateValues } from "app/types";
import { Integration } from "app/screens/Integration/types/integration";
import { CustomTriggersData, ShopiFyWorkFlowForm } from "app/screens/Integration/types/shopify";
import { Input } from "app/utils/formUtils";
import { useQueryParam } from "app/utils/history";
import { useAccountId, useIsShopifyV1Customers } from "app/utils/react-helpers";
import CustomIcon from "assets/custom.png";
import OrderIcon from "assets/ordericon.png";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { AiOutlineEdit } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import ActionInputComponent from "app/screens/Integration/components/ActionInput";
import {
    conditionalOperator,
    deSanitizeConditionsData,
    filterOptions,
    sanitizeBodyFormValues,
    sanitizeConditionsFormData,
} from "app/screens/Integration/helper";
import { BodyFormValues } from "app/screens/Integration/components/MappingIntegrationTemplate";
import SelectTemplateTabs from "app/screens/Integration/components/SelectTemplateTabs";
import { shopifyCustomWorkFlowSchema } from "./utils";
import { getWorkFlow } from "./WorkFlowInformation";
import { useShopifyRegister } from "app/screens/Integration/fetchHooks/shopify";
import MappingIntegrationCommonTemplate from "../components/MappingIntegrationTemplateCommon";

interface CreateWorkFlowProps {
    topic: string | undefined;
    integrationData: Integration | undefined;
    workflowId: string | undefined;
    isCustom: boolean;
}

const targetModule = new Map([
    ["orders/create", "Orders"],
    ["orders/fulfilled", "Orders"],
    ["orders/cancelled", "Orders"],
    ["fulfillments/update", "Orders"],
    ["checkouts/create", "Checkout"],
    ["refunds/create", "Refund"],
]);

export const financialStatusValues = [
    {
        label: "Pending",
        value: "pending",
    },
    {
        label: "Authorized",
        value: "authorized",
    },
    {
        label: "Partially Paid",
        value: "partially_paid",
    },
    {
        label: "Paid",
        value: "paid",
    },
    {
        label: "Partially Refunded",
        value: "partially_refunded",
    },
    {
        label: "Refunded",
        value: "refunded",
    },
    {
        label: "Voided",
        value: "voided",
    },
];

export const shipmentStatusValues = [
    {
        label: "Label Printed",
        value: "label_printed",
    },
    {
        label: "Label Purchased",
        value: "label_purchased",
    },
    {
        label: "Attempted Delivery",
        value: "attempted_delivery",
    },
    {
        label: "Ready for Pickup",
        value: "ready_for_pickup",
    },
    {
        label: "Confirmed",
        value: "confirmed",
    },
    {
        label: "In transit",
        value: "in_transit",
    },
    {
        label: "Out for Delivery",
        value: "out_for_delivery",
    },
    {
        label: "Delivered",
        value: "delivered",
    },
    {
        label: "Failure",
        value: "failure",
    },
];

const statusOptions: LabelValue[] = [
    {
        label: "Order Fulfilled",
        value: "orders/fulfilled",
    },
    {
        label: "Order Create",
        value: "orders/create",
    },
    {
        label: "Fulfillment Update",
        value: "fulfillments/update",
    },
];

const ordersOptions: LabelValue[] = [
    {
        label: "Total Price",
        value: "total_price",
    },
    {
        label: "Financial Status",
        value: "financial_status",
    },
    {
        label: "Shipment Status",
        value: "shipment_status",
    },
];

const defaultFormValues: ShopiFyWorkFlowForm = {
    customStatus: {
        label: "Select",
        value: "",
    },
    filter: {
        label: "ALL",
        value: "all",
    },
    workflowConditions: [
        {
            fact: "",
            operator: {
                label: "Select",
                value: "",
            },
            value: "",
            path: {
                label: "Select",
                value: "",
                type: "",
            },
        },
    ],
    actions: [],
};

const CreateWorkFlow: React.FC<CreateWorkFlowProps> = (props) => {
    const { topic, integrationData, workflowId, isCustom } = props;
    const [pickTemplate, setPickTemplate] = React.useState<WATemplate | null>(null);
    const [templateValues, setTemplateValues] = React.useState<WATemplateValues | null>(null);
    const [modalLoading, setModalLoading] = React.useState<boolean>(false);
    const query = useQueryParam();
    const title = query.get("title");
    const accountId = useAccountId();
    const history = useHistory();
    const isShopifyV1Customer = integrationData?.shopify && integrationData?.shopify?.version !== "v2";
    const integrationId = integrationData?.id ?? "";
    const channelId = integrationData?.shopify?.channelId ?? "";
    const currentWorkflow = integrationData?.shopify?.triggers?.find((c) => c?.id === workflowId);
    const sendAfter = currentWorkflow?.sendAfter;
    const [value, setValue] = React.useState<number>(sendAfter ?? 10);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: editTemplateOpen, onOpen: onTemplateOpen, onClose: onTemplateClose } = useDisclosure();
    const customWorkFlow = integrationData?.shopify?.customTriggers?.find(
        (v) => v.id === workflowId
    ) as CustomTriggersData;
    const templateId = currentWorkflow?.templateId ?? customWorkFlow?.templateId;
    const { data: templateData } = useWATemplateMessage({ accountId, templateId });
    const previousValues = currentWorkflow?.templateValues ?? customWorkFlow?.templateValues;

    const previousTemplateResponse: BodyFormValues = React.useMemo(() => {
        return {
            bodyValues: previousValues?.bodyValues ?? {},
            buttonValues: previousValues?.buttonValues ?? [],
            headerValues: previousValues?.headerValues ?? {},
        };
    }, [previousValues?.bodyValues, previousValues?.buttonValues, previousValues?.headerValues]);

    const isTemplateValuesAvailable = Object.keys(templateValues ?? {}).length > 0;

    const defaultValues: ShopiFyWorkFlowForm = React.useMemo(() => {
        const customStatus = statusOptions?.filter((v) => v.value === customWorkFlow?.topic);
        let formData: ShopiFyWorkFlowForm;
        if (customWorkFlow?.condition || currentWorkflow?.workflowActions) {
            const data = deSanitizeConditionsData(customWorkFlow?.condition, "shopify");
            formData = {
                filter: data.filter,
                workflowConditions: data.workflowConditions,
                customStatus: customStatus[0],
                actions: customWorkFlow?.workflowActions ?? currentWorkflow?.workflowActions,
            };
        } else {
            formData = defaultFormValues;
        }
        return formData;
    }, [customWorkFlow?.condition, customWorkFlow?.topic]);
    const {
        control,
        errors,
        register,
        watch,
        handleSubmit,
        reset,
        setValue: setFormValue,
    } = useForm<ShopiFyWorkFlowForm>({
        defaultValues,
        resolver: yupResolver<ShopiFyWorkFlowForm>(shopifyCustomWorkFlowSchema(isCustom)),
    });

    const isFilterSelected = watch("filter");
    const isCustomStatus = watch("customStatus");
    const customStatusValue = isCustomStatus?.value;
    const moduleTopic = topic ?? customStatusValue ?? "";
    const module = targetModule.get(moduleTopic);
    const isOrders = module === "Orders";

    const { fields, append, remove } = useFieldArray({
        control,
        name: "workflowConditions",
    });
    const workflowCondition = watch("workflowConditions");
    const galleryTags: string[] = ["Shopify", topic as string];

    const { mutate: shopifyRegister, isLoading: shopifyLoading } = useShopifyRegister({
        accountId,
        integrationId,
        workflowId,
        onSuccess: () => {
            setPickTemplate(null);
            setTemplateValues(null);
            history.goBack();
        },
        isCustom,
        isNewWorkFlow: !Boolean(templateId),
    });

    React.useEffect(() => {
        if (templateData && previousTemplateResponse) {
            setPickTemplate(templateData);
            setTemplateValues(previousTemplateResponse);
        }
    }, [previousTemplateResponse, templateData]);

    const { mutateAsync: createTemplate } = UsePostCreateTemplate({
        accountId,
        showSuccessMessage: false,
    });

    const { data } = useIntegrationFieldsList({ accountId, app: "Shopify", module });
    const variables = data?.pages?.flat() ?? [];

    const previousDefaultValues: WATemplateValues = React.useMemo(() => {
        return {
            bodyValues: templateValues?.bodyValues,
            buttonValues: templateValues?.buttonValues,
            headerValues: templateValues?.headerValues,
        };
    }, [templateValues]);

    const handleCreateTemplate = async (waTemplateGalleryId: string) => {
        try {
            const { template } = await createTemplate({
                accountId,
                channelId,
                waTemplateGalleryId,
            });
            setPickTemplate(template);
            onClose();
            onTemplateOpen();
        } catch (e) {}
    };

    const handleBackWorkFlow = () => {
        setPickTemplate(null);
        setTemplateValues(null);
        history.push({ pathname: history.location.pathname });
    };

    const handleTemplate = (data: WATemplate) => {
        setPickTemplate(data);
        onClose();
        onTemplateOpen();
    };

    const handleVariablesOnSubmit = async (data: BodyFormValues) => {
        setModalLoading(true);
        const templateValues = await sanitizeBodyFormValues(data);
        setTemplateValues({...templateValues, buttonValues: data.buttonValues});
        setModalLoading(false);
        onTemplateClose();
    };

    const handleTemplateClose = () => {
        setPickTemplate(null);
        setTemplateValues(null);
    };

    const handleReset = () => {
        if (templateData && previousTemplateResponse) {
            reset(defaultValues);
            setPickTemplate(templateData as WATemplate);
            setTemplateValues(previousTemplateResponse as WATemplateValues);
            setValue(sendAfter as number);
        }
        if (topic === "customs") {
            reset(defaultValues);
            setPickTemplate(null);
            setTemplateValues(null);
        }
    };

    const handleRemove = (index: number | number[]) => {
        remove(index);
    };

    const submitWorkFlow = (data: ShopiFyWorkFlowForm) => {
        const { customStatus, ...others } = data;
        const { condition, actions } = sanitizeConditionsFormData({ ...others }, "shopify");
        const cTopic = isCustom ? (customStatus?.value as string) : (topic as string);
        const sanitizedData = {
            topic: cTopic,
            isActive: true,
            isCustom: isCustom ? true : undefined,
            templateId: pickTemplate?.id ?? templateId,
            templateValues: templateValues as WATemplateValues,
            condition: isCustom ? condition : undefined,
            sendAfter: !isOrders ? value : undefined,
            workflowId: isCustom ? workflowId : undefined,
            workflowActions: actions ?? [],
            name: isCustom ? `Custom-${getWorkFlow(cTopic)}` : title ?? "",
        };
        shopifyRegister(sanitizedData);
    };

    const isFulfillmentsUpdate = isCustomStatus?.value === "fulfillments/update";

    const getValueInput = (
        index: number,
        value: string,
        operator?: LabelValue<string, string>,
        comparator?: LabelValue<string, string>
    ) => {
        if (
            operator?.value === "isEmpty" || operator?.value === "isNotEmpty"
                ? null
                : comparator?.value === "financial_status" || comparator?.value === "$.financial_status"
        ) {
            return (
                <ControlledReactSelectStyled
                    name={`workflowConditions[${index}].selectValue`}
                    label="Value"
                    width="200px"
                    getOptionLabel={(option) => option.label}
                    labelProps={{ mb: "1", color: "gray.500", fontSize: "sm" }}
                    defaultValue={value as unknown as LabelValue}
                    control={control}
                    size="sm"
                    borderRadius="6px"
                    options={financialStatusValues}
                    error={errors?.workflowConditions?.[index]?.selectValue?.value?.message}
                />
            );
        } else if (
            operator?.value === "isEmpty" || operator?.value === "isNotEmpty"
                ? null
                : comparator?.value === "shipment_status" || comparator?.value === "$.shipment_status"
        ) {
            return (
                <ControlledReactSelectStyled
                    name={`workflowConditions[${index}].selectValue`}
                    label="Value"
                    width="200px"
                    getOptionLabel={(option) => option.label}
                    labelProps={{ mb: "1", color: "gray.500", fontSize: "sm" }}
                    defaultValue={value as unknown as LabelValue}
                    control={control}
                    size="sm"
                    borderRadius="6px"
                    options={shipmentStatusValues}
                    error={errors?.workflowConditions?.[index]?.selectValue?.value?.message}
                />
            );
        }
        return (
            <Input
                name={`workflowConditions[${index}].value`}
                label="Value"
                width="200px"
                labelProps={{ mb: "1", color: "gray.500", fontSize: "sm" }}
                defaultValue={value}
                placeholder="Enter value"
                register={register}
                size="sm"
                borderRadius="6px"
                error={errors?.workflowConditions?.[index]?.value?.message}
            />
        );
    };
    return (
        <>
            <VStack as="form" onSubmit={handleSubmit(submitWorkFlow)} width="full" spacing={6} height="full">
                <HStack width="full">
                    <IconButton
                        size="sm"
                        aria-label="back-workflow-button"
                        icon={<ArrowBackIcon />}
                        onClick={handleBackWorkFlow}
                        bgColor="transparent"
                    />
                    <HStack>
                        <Image
                            boxSize={12}
                            src={isCustom ? CustomIcon : OrderIcon}
                            fallback={<Skeleton boxSize={12} />}
                        />
                        <VStack align="flex-start" spacing={0}>
                            <Text fontSize="md" color="black" fontWeight="semibold">
                                {title ?? `Custom-${getWorkFlow(customWorkFlow?.topic)}`}
                            </Text>
                        </VStack>
                    </HStack>
                    <Spacer />
                </HStack>
                {isCustom ? (
                    <>
                        <HStack width="full">
                            <VStack alignItems="flex-start" flex="0.3">
                                <FormLabel>
                                    Shopify Custom Event <span style={{ color: "red" }}>*</span>
                                </FormLabel>
                            </VStack>
                            <VStack alignItems="flex-start" flex="0.5">
                                <ControlledReactSelectStyled
                                    name="customStatus"
                                    width="200px"
                                    control={control}
                                    onChange={(value) => {
                                        const { customStatus, ...rest } = defaultFormValues;
                                        reset(rest);
                                        setPickTemplate(null);
                                        setTemplateValues(null);
                                    }}
                                    error={errors?.customStatus?.value?.message}
                                    options={statusOptions}
                                    placeholder="Select Status"
                                />
                            </VStack>
                        </HStack>
                        <HStack width="full">
                            <VStack width="full">
                                <HStack w="full" spacing={4}>
                                    <VStack flex="0.3" spacing={0} alignItems="flex-start">
                                        <Text fontSize="md" fontWeight="medium">
                                            Conditions <span style={{ color: "red" }}>*</span>
                                        </Text>
                                        <Text fontWeight="normal" fontSize="sm" color="gray.400">
                                            Max. 3 conditions
                                        </Text>
                                    </VStack>
                                    <HStack flex="0.4">
                                        <HStack spacing={4} w="full" alignItems="baseline">
                                            <Text color="gray.800" fontSize="md" fontWeight="bold">
                                                MATCHES
                                            </Text>
                                            <ControlledReactSelectStyled
                                                name="filter"
                                                width="200px"
                                                control={control}
                                                error={errors?.filter?.value?.message}
                                                options={filterOptions}
                                                placeholder="Select"
                                            />
                                        </HStack>
                                    </HStack>
                                </HStack>
                                <HStack w="full">
                                    <Spacer flex="0.3" />
                                    <VStack flex="0.7" alignItems="flex-start" spacing={4}>
                                        {fields.map((v, index) => {
                                            const operator = workflowCondition?.[index]?.operator;
                                            const comparator = workflowCondition?.[index]?.path;
                                            return (
                                                <HStack key={v?.id} alignItems="flex-start">
                                                    <ControlledReactSelectStyled
                                                        name={`workflowConditions[${index}].path`}
                                                        label="Comparator"
                                                        control={control}
                                                        defaultSelectValue={v.path}
                                                        labelProps={{ mb: "1" }}
                                                        width="200px"
                                                        options={ordersOptions}
                                                        error={
                                                            (errors?.workflowConditions?.[index]?.operator as any)
                                                                ?.message ||
                                                            errors?.workflowConditions?.[index]?.path?.value?.message
                                                        }
                                                        isOptionDisabled={(option) => {
                                                            if (
                                                                option.value === "shipment_status" &&
                                                                !isFulfillmentsUpdate
                                                            ) {
                                                                return true;
                                                            } else if (
                                                                option.value !== "shipment_status" &&
                                                                isFulfillmentsUpdate
                                                            ) {
                                                                return true;
                                                            }
                                                            return false;
                                                        }}
                                                    />
                                                    <ControlledReactSelectStyled
                                                        label="Operator"
                                                        width="200px"
                                                        name={`workflowConditions[${index}].operator`}
                                                        control={control}
                                                        defaultSelectValue={v?.operator}
                                                        labelProps={{ mb: "1" }}
                                                        options={conditionalOperator}
                                                        error={
                                                            (errors?.workflowConditions?.[index]?.operator as any)
                                                                ?.message ||
                                                            errors?.workflowConditions?.[index]?.operator?.value
                                                                ?.message
                                                        }
                                                    />
                                                    {getValueInput(index, v?.value, operator, comparator)}
                                                    <IconButton
                                                        size="sm"
                                                        top="25px"
                                                        rounded="full"
                                                        color="red.500"
                                                        aria-label="delete"
                                                        isDisabled={fields.length === 1}
                                                        icon={<DeleteIcon />}
                                                        onClick={() => handleRemove(index)}
                                                    />
                                                </HStack>
                                            );
                                        })}
                                        {Boolean(isFilterSelected?.value) && (
                                            <Box>
                                                <Button
                                                    aria-label="Add condition"
                                                    leftIcon={<AddIcon color="blue.500" />}
                                                    borderRadius="6px"
                                                    isDisabled={fields.length === 3}
                                                    colorScheme="brand"
                                                    size="sm"
                                                    variant="ghost"
                                                    onClick={() => append({ operator: "", value: "", path: "" })}
                                                >
                                                    Add condition
                                                </Button>
                                            </Box>
                                        )}
                                    </VStack>
                                </HStack>
                            </VStack>
                        </HStack>
                    </>
                ) : null}
                <HStack width="full">
                    <VStack alignItems="flex-start" flex="0.3">
                        <FormLabel>
                            Send Template Message <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                    </VStack>
                    <VStack alignItems="flex-start" flex="0.5">
                        {isTemplateValuesAvailable ? (
                            <HStack w="full">
                                <Tag bgColor="gray.200">
                                    <Text
                                        maxWidth="20ch"
                                        isTruncated
                                        fontWeight="medium"
                                        fontSize="13px"
                                        color="gray.800"
                                    >
                                        {pickTemplate?.name}
                                    </Text>
                                    <TagCloseButton onClick={handleTemplateClose} />
                                </Tag>
                                <IconButton
                                    aria-label="edit-template"
                                    onClick={onTemplateOpen}
                                    bgColor="transparent"
                                    icon={<Icon as={AiOutlineEdit} boxSize={4} />}
                                />
                            </HStack>
                        ) : (
                            <Button size="sm" fontSize="sm" variant="outline" onClick={onOpen}>
                                Pick Template
                            </Button>
                        )}
                    </VStack>
                </HStack>
                {currentWorkflow?.topic === "checkouts/create" ? (
                    <HStack width="full" alignItems="flex-start">
                        <VStack alignItems="flex-start" flex="0.3">
                            <FormLabel>Send After</FormLabel>
                        </VStack>
                        <VStack alignItems="flex-start" flex="0.5">
                            <RadioGroup onChange={(v) => setValue(parseInt(v))} value={`${value}`}>
                                <Stack direction="column">
                                    <Radio value="1">1 hour</Radio>
                                    <Radio value="6">6 hours</Radio>
                                    <Radio value="10">10 hours (Recommended)</Radio>
                                    <Radio value="24">24 hours</Radio>
                                </Stack>
                            </RadioGroup>
                        </VStack>
                    </HStack>
                ) : null}
                <ActionInputComponent control={control} setValue={setFormValue} />
                <ButtonGroup>
                    <Button borderRadius="6px" variant="outline" onClick={handleReset} colorScheme="gray" size="sm">
                        Cancel
                    </Button>
                    <Tooltip label={isShopifyV1Customer ? "Re-Authenticate" : null} hasArrow>
                        <Button
                            borderRadius="6px"
                            isDisabled={isShopifyV1Customer || !isTemplateValuesAvailable}
                            size="sm"
                            type="submit"
                            isLoading={shopifyLoading}
                            colorScheme="blue"
                        >
                            {Boolean(templateId) ? "Update WorkFlow" : "Create WorkFlow"}
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            </VStack>
            <Modal size="full" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody width="full" overflow="hidden">
                        <SelectTemplateTabs
                            CreateTemplateFromGallery={handleCreateTemplate}
                            PickTemplate={handleTemplate}
                            channelId={channelId}
                            galleryTags={galleryTags}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal size="3xl" isOpen={editTemplateOpen} onClose={onTemplateClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody minWidth="xl">
                        <MappingIntegrationCommonTemplate
                            handleVariables={handleVariablesOnSubmit}
                            templateId={pickTemplate?.id ?? ""}
                            variables={variables}
                            isLoading={modalLoading}
                            previousValue={isTemplateValuesAvailable ? previousDefaultValues : {}}
                            closeModal={onTemplateClose}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateWorkFlow;
