import { IntegrationType } from "app/screens/Integration/types/integration";
import Calendly from "assets/Integration/Calendly.svg";
import CashFree from "assets/BotTemplate/IntegrationLogo/Cashfree.png";
import MiitelIcon from "assets/Integration/miitel_icon.svg";
import FynoLogo from "assets/Integration/fyno.svg";
import { useDeleteIntegration } from "app/screens/Integration/fetchHooks/integration";
import { CashFreeConnect } from "app/screens/Integration/types/cashfree";
import { useConnectCalendly, useDeleteCalendlyIntegration } from "./fetchHooks/calendly";
import { useConnectCashFreeIntegration } from "./fetchHooks/cashFree";
import PaymentCaptured from "assets/patment_captured.svg";
import { ZohoBooksWorkFlowListProps } from "./helper";

export const integrationData = {
    calendly: {
        description:
            "Integrate Calendly with your Official WhatsApp Business Number and send event notifications to your customers.",
        logo: Calendly,
        useDeleteIntegrationFn: useDeleteCalendlyIntegration,
        useConnect: useConnectCalendly,
        connectUrl: (data: any) => "_self",
    },
    cashfree: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: CashFreeConnect) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    shopify: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    fbleads: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    woocommerce: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    webengage: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    moengage: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    razorpay: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    hubspot: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    leadsquared: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    genericWebhook: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    shiprocket: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    woocommerce_cartflow: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    shipway: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    zohoCRM: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    woocommerce_abandoned_cart: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    zohobooks: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    cleverTap: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    stripe: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    shopflo: {
        description:
            "Integrate Cashfree with your Official WhatsApp Business Number and share payment updates to your customers.",
        logo: CashFree,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: Record<string, string>) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    miitel: {
        description:
            "Integrating MiiTel with WhatsApp allows you to make voice calls to customers WhatsApp numbers through MiiTel, ensuring calls are logged, recorded, and analyzed for better customer engagement and streamlined communication.",
        logo: MiitelIcon,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: CashFreeConnect) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    fyno: {
        description: "Integrate Fyno with your WhatsApp Business and send customized template messages.",
        logo: FynoLogo,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: CashFreeConnect) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    pipedrive: {
        description: "Integrate Fyno with your WhatsApp Business and send customized template messages.",
        logo: FynoLogo,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: CashFreeConnect) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
    "chat-widget": {
        description: "Integrate Fyno with your WhatsApp Business and send customized template messages.",
        logo: FynoLogo,
        useDeleteIntegrationFn: useDeleteIntegration,
        useConnect: useConnectCashFreeIntegration,
        connectUrl: (data: CashFreeConnect) => `/integration/cashfree/edit/${data?.id}?tab=1`,
    },
};

export const statusOptions = [
    {
        label: "Pending",
        value: "pending",
    },
    {
        label: "Processing",
        value: "processing",
    },
    {
        label: "On-Hold",
        value: "on-hold",
    },
    {
        label: "Completed",
        value: "completed",
    },
    {
        label: "Cancelled",
        value: "cancelled",
    },
    {
        label: "Refunded",
        value: "refunded",
    },
    {
        label: "Failed",
        value: "failed",
    },
    {
        label: "Trash",
        value: "trash",
    },
];

export const zohoBooksWorkFlowList: ZohoBooksWorkFlowListProps[] = [
    {
        title: "Send Invoice Notification",
        topic: "send_invoice_notification",
        icon: PaymentCaptured,
        description: "Send Invoice notification to your customers through your official WhatsApp Number",
    },
    {
        title: "Invoice Payment Success",
        topic: "invoice_payment_success",
        icon: PaymentCaptured,
        description: "Invoice Payment Success",
    },
    {
        title: "Invoice Reminder 3 days Before Due Date",
        topic: "invoice_reminder_3_days_before",
        icon: PaymentCaptured,
        description:
            "Send Invoice Reminder notification to your customers 3 Days Before through your official WhatsApp Number",
    },
    {
        title: "Invoice Reminder 1 day Before Due Date",
        topic: "invoice_reminder_1_days_before",
        icon: PaymentCaptured,
        description:
            "Send Invoice Reminder notification to your customers 1 Days Before through your official WhatsApp Number",
    },
    {
        title: "Invoice Reminder 1 day After Due Date",
        topic: "invoice_reminder_1_days_after",
        icon: PaymentCaptured,
        description:
            "Send Invoice Reminder notification to your customers 1 Days After through your official WhatsApp Number",
    },
    {
        title: "Invoice Reminder 3 days After Due Date",
        topic: "invoice_reminder_3_days_after",
        icon: PaymentCaptured,
        description:
            "Send Invoice Reminder notification to your customers 3 Days After through your official WhatsApp Number",
    },
    {
        title: "Invoice Reminder 6 days After Due Date",
        topic: "invoice_reminder_6_days_after",
        icon: PaymentCaptured,
        description:
            "Send Invoice Reminder notification to your customers 6 Days After through your official WhatsApp Number",
    },
    {
        title: " Send Estimate",
        topic: "send_estimate",
        icon: PaymentCaptured,
        description: "Send Estimate  notification to your customers  through your official WhatsApp Number",
    },
    {
        title: "Estimate Reminder 3 days Before Expiry Date",
        topic: "estimate_reminder_3_days_before",
        icon: PaymentCaptured,
        description:
            "Send Estimate Reminder notification to your customers 3 Days Before through your official WhatsApp Number",
    },
    {
        title: "Estimate Reminder 1 day Before Expiry Date",
        topic: "estimate_reminder_1_day_before",
        icon: PaymentCaptured,
        description:
            "Send Estimate Reminder notification to your customers 1 Day Before through your official WhatsApp Number",
    },
    {
        title: "Estimate Reminder 1 day After Expiry Date",
        topic: "estimate_reminder_1_day_after",
        icon: PaymentCaptured,
        description:
            "Send Estimate Reminder notification to your customers 1 Day After through your official WhatsApp Number",
    },
    {
        title: "Estimate Reminder 3 days After Expiry Date",
        topic: "estimate_reminder_3_days_after",
        icon: PaymentCaptured,
        description:
            "Send Estimate Reminder notification to your customers 3 Days After through your official WhatsApp Number",
    },
    {
        title: "Estimate Reminder 6 days After Expiry Date",
        topic: "estimate_reminder_6_days_after",
        icon: PaymentCaptured,
        description:
            "Send Estimate Reminder notification to your customers 6 Days After through your official WhatsApp Number",
    },
];

export const getIntegrationDetails = (integrationType: IntegrationType) => {
    return integrationData[integrationType];
};
