import { HStack, Kbd, Text, VStack } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { isMacOS } from "app/utils/common";
import { useUser } from "app/utils/react-helpers";
import React from "react";
import { useNavigationList } from "../hooks/useNavigationList";
import ProfilePopover from "../ProfilePopover";
import NavigationItem, { NavigationItemProps } from "./NavigationItem";
import NavigationItemWrapper from "./NavigationItemWrapper";

const QuickSearchShortcutNote: React.FC<{ isExpanded: boolean }> = ({ isExpanded }) => {
    return (
        <HStack
            color="gray.600"
            w="160px"
            spacing="1"
            px="2"
            align="baseline"
            visibility={isExpanded ? "visible" : "hidden"}
        >
            <Text color="gray.400" fontWeight="light" fontSize="10px">
                <Trans>Quick Search</Trans>
            </Text>
            <span>
                <Kbd fontWeight="normal" fontSize="xs">
                    {isMacOS ? "⌘" : "Ctrl"}
                </Kbd>
                {" + "}
                <Kbd fontWeight="normal" fontSize="xs">
                    K
                </Kbd>
            </span>
        </HStack>
    );
};

type NavigationFooterProps = Omit<NavigationItemProps, "navigationItem">;
const NavigationFooter: React.FC<NavigationFooterProps> = (props) => {
    const user = useUser();
    const { isExpanded } = props;
    const { navigationItems } = useNavigationList();

    return (
        <VStack w="full" align="start" spacing={0.5}>
            <NavigationItemWrapper {...props}>
                <QuickSearchShortcutNote isExpanded={isExpanded} />
                <NavigationItem {...props} navigationItem={navigationItems.settings} />
                <NavigationItem {...props} navigationItem={navigationItems.help} />
            </NavigationItemWrapper>

            {user ? <ProfilePopover user={user} {...props} /> : null}
        </VStack>
    );
};

export default React.memo(NavigationFooter);
