import { Button, FormControl, FormLabel, HStack, Icon, Radio, Stack, VStack } from "@chakra-ui/react";
import ReactSelectStyled from "app/components/CustomizedReactSelect/ReactSelectStyled";
import { useGetActions } from "app/fetchHooks/action";
import { FieldByFactType } from "app/screens/BotFlowBuilder/PromptActions";
import { Action, Fact } from "app/types/action";
import { RadioGroup } from "app/utils/formUtils";
import { useAccountId } from "app/utils/react-helpers";
import React from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { MdAdd, MdClose } from "react-icons/md";
import { SelectFormValues } from "app/screens/Integration/Fbleads/FbLeadsDetails";

export interface ActionInputProps {
    control: ReturnType<typeof useForm>["control"];
    setValue: ReturnType<typeof useForm>["setValue"];
}

const ActionInputComponent: React.FC<ActionInputProps> = (props) => {
    const { control, setValue } = props;
    return (
        <HStack width="full" justify="flex-start">
            <VStack alignItems="flex-start" alignSelf="flex-start" flex="0.3" spacing={0} h="full">
                <FormLabel>Action</FormLabel>
            </VStack>
            <HStack flex="0.4" w="full">
                <ActionInput control={control} setValue={setValue} />
            </HStack>
        </HStack>
    );
};

export const ActionInput: React.FC<ActionInputProps> = ({ control, setValue }) => {
    const accountId = useAccountId();
    const { fields, append, remove } = useFieldArray({ control, name: "actions" });
    const value = useWatch<SelectFormValues>({ control });
    const { data: actions } = useGetActions({
        accountId,
        options: { requestModule: "INTEGRATION", modules: ["contact", "sequence"] },
    });

    return (
        <VStack w="full" align="flex-start">
            {fields?.map((a, index) => {
                const currentActionItem = value?.actions?.[index]?.actionItem;
                const fact = value?.actions?.[index]?.actionItem?.fact;

                return (
                    <VStack
                        align="flex-start"
                        w="full"
                        key={a.id}
                        border="1px solid"
                        borderColor="gray.100"
                        rounded="md"
                        p={4}
                        spacing={4}
                        position="relative"
                    >
                        <FormControl id="actionType" isRequired position="relative">
                            <FormLabel fontSize="xs" color="gray.700" textTransform="uppercase" fontWeight="bold">
                                Action Type
                            </FormLabel>
                            <Controller
                                name={`actions[${index}].actionItem`}
                                control={control}
                                render={(controllerProps) => (
                                    <ReactSelectStyled<Action>
                                        placeholder="Select or Search  Action"
                                        options={actions}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.name}
                                        {...controllerProps}
                                        onChange={(e) => {
                                            setValue(
                                                `actions[${index}].actionValues.operation`,
                                                currentActionItem?.operations?.[0],
                                                { shouldDirty: true }
                                            );

                                            setValue(`actions[${index}].actionValues.values`, "", {
                                                shouldDirty: true,
                                            });

                                            controllerProps.onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl
                            id={fact?.property}
                            isRequired
                            position="relative"
                            display={fact ? "block" : "none"}
                        >
                            <FormLabel fontSize="xs" color="gray.700" textTransform="uppercase" fontWeight="bold">
                                {fact?.label}
                            </FormLabel>
                            <Controller
                                name={`actions[${index}].actionValues.values`}
                                control={control}
                                render={(controllerProps) => {
                                    if (fact) return <FieldByFactType {...(fact as Fact)} {...controllerProps} />;
                                    return <></>;
                                }}
                            />
                        </FormControl>
                        <FormControl
                            id={`${currentActionItem?.fact?.property}-operation`}
                            isRequired
                            key={JSON.stringify(currentActionItem)}
                            display={
                                currentActionItem?.operations != null &&
                                currentActionItem?.operations.length > 0 &&
                                fact != null
                                    ? "block"
                                    : "none"
                            }
                        >
                            <FormLabel>Operation</FormLabel>
                            <RadioGroup name={`actions[${index}].actionValues.operation`} control={control} required>
                                <Stack direction="row">
                                    {currentActionItem?.operations?.map((o) => (
                                        <Radio key={`${currentActionItem?.fact?.property}-${o}`} value={o} size="sm">
                                            {o}
                                        </Radio>
                                    ))}
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                        <Button
                            size="xs"
                            variant="link"
                            colorScheme="red"
                            fontWeight="normal"
                            onClick={() => {
                                remove(index);
                            }}
                            position="absolute"
                            top={2}
                            right={2}
                            leftIcon={<Icon as={MdClose} />}
                        >
                            Remove action
                        </Button>
                    </VStack>
                );
            })}
            <Button
                size="sm"
                variant="outline"
                onClick={() => {
                    append({});
                }}
                leftIcon={<Icon as={MdAdd} />}
                isDisabled={fields?.length >= 5}
                data-cy="action-add"
            >
                {fields?.length > 0 ? "Add another action" : "Add action"}
            </Button>
        </VStack>
    );
};

export default ActionInputComponent;
