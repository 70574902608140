import { Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react";
import { WATemplate } from "app/types";
import React from "react";
import PickFromExistingTemplates from "./PickFromExistingTemplate";
import PickFromGallery from "./PickFromGallery";

interface SelectTemplateTabsProps {
    galleryTags?: string[];
    PickTemplate: (data: WATemplate) => void;
    channelId: string;
    CreateTemplateFromGallery?: (id: string) => Promise<void>;
    disablePickFromGallery?: boolean;
    enableAuthenticationTemplate?: boolean;
}

const SelectTemplateTabs: React.FC<SelectTemplateTabsProps> = (props) => {
    const {
        CreateTemplateFromGallery,
        PickTemplate,
        channelId,
        galleryTags,
        disablePickFromGallery,
        enableAuthenticationTemplate,
    } = props;
    return (
        <Tabs isLazy w="full" lazyBehavior="keepMounted">
            <TabList>
                {!disablePickFromGallery ? (
                    <Tab color="gray.600" fontSize="md">
                        Pick From Gallery
                    </Tab>
                ) : null}
                <Tab color="gray.600" fontSize="md">
                    Pick From Existing Templates
                </Tab>
            </TabList>
            <TabPanels>
                {!disablePickFromGallery ? (
                    <TabPanel p={0}>
                        <PickFromGallery
                            handleSoftTemplateCreation={CreateTemplateFromGallery}
                            tags={galleryTags}
                            enableAuthenticationTemplate={enableAuthenticationTemplate}
                        />
                    </TabPanel>
                ) : null}
                <VStack as={TabPanel} p={0}>
                    <PickFromExistingTemplates
                        handleTemplate={PickTemplate}
                        channelId={channelId}
                        enableAuthenticationTemplate={enableAuthenticationTemplate}
                    />
                </VStack>
            </TabPanels>
        </Tabs>
    );
};

export default SelectTemplateTabs;
