import { useToast } from "@chakra-ui/react";
import { dispatchUpdateUserAvailability } from "app/modules/authed";
import { AvailabilityType, QueryKey } from "app/types/common";
import { User, UserAccount } from "app/types/user";
import { patchJSON } from "app/utils/fetchUtils";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

interface UserAvailabilityProps {
    accountId: string;
    userId?: string;
    onSuccess?: (data: UserAccount) => void;
    onError?: (data?: User) => void;
}
interface UpdateUserAvailabilityProps {
    availability: AvailabilityType;
    userId?: string;
}

export type UserAvailabilityReturnType = UseMutationResult<UserAccount, Error, UpdateUserAvailabilityProps>;

export const useUserAvailability = (props: UserAvailabilityProps): UserAvailabilityReturnType => {
    const { accountId, userId, onSuccess, onError } = props;
    const queryClient = useQueryClient();

    const updateUserAvailability = (props: UpdateUserAvailabilityProps) => {
        const { availability, userId: uId } = props;
        const id = uId || userId;
        return patchJSON<UpdateUserAvailabilityProps, UserAccount>(
            `/api/accounts/${accountId}/users/${id}/availability?version=v2`,
            { availability }
        );
    };

    const toast = useToast();

    const mutation = useMutation<UserAccount, Error, UpdateUserAvailabilityProps>(updateUserAvailability, {
        onSuccess: (data) => {
            const { availability } = data;
            if (userId && data && availability) dispatchUpdateUserAvailability(userId, accountId, availability);
            queryClient.invalidateQueries([QueryKey.UserList, { accountId }]);
            toast({
                title: "User Availability Status is Updated!",
                status: "success",
            });
            onSuccess?.(data);
        },
        onError: () => {
            onError?.();
        },
    });
    return mutation;
};
