import { VStack } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { appLayoutStyles } from "../utils";

interface NavigationWrapperProps {
    isExpanded: boolean;
    onExpand: () => void;
    collapseNavigation: () => void;
    hideNavigation?: boolean;
    isPinned: boolean;
}
const NavigationWrapper: React.FC<PropsWithChildren<NavigationWrapperProps>> = (props) => {
    const { isExpanded, collapseNavigation, onExpand, hideNavigation, isPinned, children } = props;

    return (
        <VStack
            sx={appLayoutStyles.header}
            w={isExpanded ? "160px" : "48px"}
            align={isExpanded ? "start" : "center"}
            spacing={0}
            transition="all 0.2s"
            boxShadow="base"
            borderRightWidth="1px"
            borderRightColor="gray.500"
            borderRightStyle="solid"
            zIndex={1}
            maxH="full"
            overflow="visible"
            onMouseEnter={onExpand}
            onMouseLeave={collapseNavigation}
            pos={isPinned ? "relative" : "absolute"}
            left={0}
            top={0}
            bottom={0}
            display={hideNavigation ? "none" : "flex"}
        >
            {children}
        </VStack>
    );
};

export default NavigationWrapper;
