import React from "react";
import { NavigationItem } from "../types";
import StatusLabel, { getStatusColor } from "app/components/StatusLabel";
import { Box } from "@chakra-ui/react";
import { BoxProps } from "@chakra-ui/react";

interface NavigationBadgeProps {
    navigationItem: NavigationItem;
    isExpanded: boolean;
}
const NavigationBadge: React.FC<NavigationBadgeProps> = (props) => {
    const { navigationItem, isExpanded } = props;
    const { badge } = navigationItem;

    if (!badge) return null;

    if (isExpanded) return <StatusLabel status={badge} px="1" />;

    return (
        <Box {...(getStatusColor(badge) as BoxProps)} rounded="full" top="4px" right="4px" boxSize="2" pos="absolute" />
    );
};

export default NavigationBadge;
