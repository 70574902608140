import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useAccountMasterHook } from "app/hooks/useAccountMasterHook";
import { useCheckAbility } from "app/hooks/useCheckAbility";
import { useRestrictFeature } from "app/hooks/useRestrictFeature";
import useWhatsappSupportURL, { getWhatsAppURL } from "app/hooks/useWhatsappSupportURL";
import { openSupportTicketWidget } from "app/screens/Widget/utils";
import { useUser } from "app/utils/react-helpers";
import {
    Handshake,
    LucideBookOpenText,
    LucideChartArea,
    LucideGlobeLock,
    LucideMessageCircleQuestion,
    LucideMessageSquareMore,
    LucideMessageSquareWarning,
    LucideMousePointerClick,
    LucideRoute,
} from "lucide-react";
import React, { useMemo } from "react";
import { FiUsers } from "react-icons/fi";
import { GoDependabot } from "react-icons/go";
import {
    LuBell,
    LuBellRing,
    LuContact2,
    LuCreditCard,
    LuCrown,
    LuFileQuestion,
    LuFileText,
    LuFormInput,
    LuGlobe,
    LuHelpCircle,
    LuHistory,
    LuHome,
    LuKeyRound,
    LuLayoutDashboard,
    LuLayoutTemplate,
    LuLightbulb,
    LuListTree,
    LuLocateFixed,
    LuLogOut,
    LuMegaphone,
    LuMessageCircle,
    LuMessageSquare,
    LuPlug2,
    LuSettings,
    LuShoppingBag,
    LuShoppingCart,
    LuTag,
    LuTicket,
    LuUserCircle,
    LuUserCog,
    LuWallet2,
    LuWebhook,
    LuZap,
} from "react-icons/lu";
import { SiWhatsapp } from "react-icons/si";
import { NavigationItem, NavigationMenuList } from "../types";

export type NavItemProps = Record<NavigationMenuList, NavigationItem>;

interface UseNavigationListProps {
    navigationItems: NavItemProps;
}

export const useNavigationList = (): UseNavigationListProps => {
    const { _ } = useLingui();

    const { isSandboxChannel, isNewAccount, isFreeTrial, isSubAccount } = useAccountMasterHook();

    const user = useUser();
    const isPartnerUser = user.type === "partner";
    const hideSupportMenu = isSubAccount && !isPartnerUser;

    const { canIReadWebhook, canIReadSubscription } = useCheckAbility();
    const {
        data: { restrictPage: restrictIPConfigPage },
    } = useRestrictFeature("accounts_ip_restriction", "restrict");
    const {
        data: { restrictPage: hideConversationFields },
    } = useRestrictFeature("conversations_fields", "restrict");

    const hideRaiseTicket = isSandboxChannel || isNewAccount;
    const whatsappSupportURL = useWhatsappSupportURL();

    const chatWithUsURL = useMemo(() => {
        if (!isPartnerUser && (isFreeTrial || isSandboxChannel || isNewAccount)) {
            return getWhatsAppURL("917825877730", "Hi");
        }
        return whatsappSupportURL;
    }, [isFreeTrial, isNewAccount, isPartnerUser, isSandboxChannel, whatsappSupportURL]);

    const navigationItems = React.useMemo((): NavItemProps => {
        return {
            "get-started": {
                title: _(msg`Home`),
                icon: LuHome,
                to: "/getting-started",
                eventName: "LNAV_HOME",
                type: "internal-link",
            },
            conversations: {
                title: _(msg`Conversations`),
                icon: LuMessageSquare,
                to: "/conversations",
                eventName: "LNAV_CONV",
                type: "internal-link",
            },
            contacts: {
                title: _(msg`Contacts`),
                icon: LuContact2,
                to: "/contacts",
                eventName: "LNAV_CONT_CONT",
                type: "internal-link",
                subject: "Contact",
                action: "readMany",
                subPageConfig: [
                    {
                        to: "/companies",
                        comparisonMethod: "exact",
                    },
                    {
                        to: "/segments",
                        comparisonMethod: "exact",
                    },
                ],
            },

            bots: {
                title: _(msg`Bots`),
                icon: GoDependabot,
                to: "/bots",
                eventName: "LNAV_BOT_MYBO",
                type: "internal-link",
                iconProps: { strokeWidth: "0px" },
                subject: "Bot",
                action: "read",
                subPageConfig: [
                    {
                        to: "/bot-templates",
                        comparisonMethod: "exact",
                    },
                    {
                        to: "/bots/*",
                        comparisonMethod: "pattern-match",
                    },
                    {
                        to: "/botv2/*/flows/*/*",
                        comparisonMethod: "pattern-match",
                        hideNavigation: true,
                        hideSubNavigation: true,
                    },
                    {
                        to: "/botv2/*/flows/*/aiFlow/*",
                        comparisonMethod: "pattern-match",
                        hideNavigation: true,
                        hideSubNavigation: true,
                    },
                ],
            },
            broadcast: {
                title: _(msg`Broadcast`),
                icon: LuMegaphone,
                to: "/broadcast",
                eventName: "LNAV_BROD",
                type: "internal-link",
                subject: "Broadcast",
                action: "read",
            },
            sequence: {
                title: _(msg`Sequence`),
                icon: LucideRoute,
                to: "/sequence",
                eventName: "LNAV_SEQU",
                type: "internal-link",
                action: "update",
                subject: "Sequence",
            },
            commerce: {
                title: _(msg`Commerce`),
                icon: LuShoppingBag,
                type: "sub-navigation",
                subNavigation: {
                    type: "popover",
                    children: [
                        {
                            title: _(msg`Commerce`),
                            children: [
                                {
                                    title: _(msg`Catalogues`),
                                    to: "/facebook-catalogs",
                                    eventName: "LNAV_COMM_CATA",
                                    action: "create",
                                    subject: "Catalog",
                                    type: "internal-link",
                                    icon: LucideBookOpenText,
                                },
                                {
                                    title: _(msg`Orders`),
                                    to: "/whatsapp-order",
                                    action: "read",
                                    subject: "WhatsappOrders",
                                    eventName: "LNAV_COMM_ORDE",
                                    type: "internal-link",
                                    icon: LuShoppingCart,
                                },
                                {
                                    title: _(msg`Payments`),
                                    to: "/payments",
                                    badge: "new",
                                    eventName: "LNAV_COMM_PAY",
                                    action: "read",
                                    subject: "Payments",
                                    type: "internal-link",
                                    icon: LuCreditCard,
                                },
                            ],
                        },
                    ],
                },
            },
            flows: {
                title: _(msg`Flows`),
                to: "/whatsapp-flows",
                icon: LuListTree,
                eventName: "LNAV_WHAT_FLOWS",
                type: "internal-link",
                buttonProps: { px: "10px" },
                action: "read",
                subject: "WhatsappFlow",
            },

            integration: {
                title: _(msg`Integrations`),
                icon: LuPlug2,
                to: "/integration",
                eventName: "LNAV_SETT_INTE",
                action: "read",
                subject: "Integration",
                type: "internal-link",
                buttonProps: { px: "10px" },
            },

            partner: {
                title: _(msg`Accounts`),
                type: "internal-link",
                icon: Handshake,
                to: "/sub-accounts/dashboard",
                eventName: "LNAV_SETT_INTE",
                iconProps: { fontSize: "16px" },
                hide: !isPartnerUser,
            },

            settings: {
                title: _(msg`Settings`),
                icon: LuSettings,
                type: "sub-navigation",

                subNavigation: {
                    type: "in-app",
                    children: [
                        {
                            // title: _(msg`Settings`),
                            children: [
                                {
                                    title: _(msg`WhatsApp templates`),
                                    type: "internal-link",
                                    to: "/whatsapp-template-messagesv2",
                                    icon: LuLayoutTemplate,
                                    eventName: "LNAV_SETT_WATE",
                                    action: "create",
                                    subject: "WhatsappTemplate",
                                    subPageConfig: [
                                        {
                                            comparisonMethod: "pattern-match",
                                            to: "/whatsapp-template-messagesv2/*/*",
                                            hideSubNavigation: true,
                                        },
                                        {
                                            comparisonMethod: "pattern-match",
                                            to: "/whatsapp-template-messagesv2/*/*/*",
                                            hideSubNavigation: true,
                                        },
                                        {
                                            comparisonMethod: "pattern-match",
                                            to: "/whatsapp-template-messagesv2/*",
                                            hideSubNavigation: true,
                                        },
                                    ],
                                },
                                {
                                    title: _(msg`CTWA`),
                                    to: "/ctwa-leads",
                                    eventName: "LNAV_C2WA",
                                    action: "read",
                                    subject: "CTWA",
                                    icon: LucideMousePointerClick,
                                    type: "internal-link",
                                    subPageConfig: [
                                        {
                                            comparisonMethod: "pattern-match",
                                            to: "/ctwa/*/*",
                                            hideSubNavigation: true,
                                        },
                                    ],
                                },

                                {
                                    title: _(msg`Canned response`),
                                    to: "/cannedresponse",
                                    action: "create",
                                    subject: "CannedResponse",
                                    eventName: "LNAV_SETT_CANE",
                                    type: "internal-link",
                                    icon: LuZap,
                                    subPageConfig: [
                                        {
                                            comparisonMethod: "pattern-match",
                                            to: "/cannedresponse/edit/*",
                                            hideSubNavigation: true,
                                        },
                                        {
                                            comparisonMethod: "exact",
                                            to: "/cannedresponse/add",
                                            hideSubNavigation: true,
                                        },
                                    ],
                                },
                                {
                                    title: _(msg`Message tracker`),
                                    to: "/message-tracking",
                                    eventName: "LNAV_SETT_MESS",
                                    action: "read",
                                    subject: "MessageTracker",
                                    icon: LuLocateFixed,
                                    type: "internal-link",
                                },

                                { type: "divider" },

                                {
                                    title: _(msg`Contact Fields`),
                                    to: "/fields",
                                    eventName: "LNAV_SETT_FIEL",
                                    action: "create",
                                    subject: "Fields",
                                    type: "internal-link",
                                    icon: LuFormInput,
                                    quickSearchTerms: ["Field collection"],
                                },
                                {
                                    title: _(msg`Conversation Fields`),
                                    to: "/conversation-fields",
                                    eventName: "LNAV_SETT_FIEL",
                                    action: "create",
                                    subject: "ConversationField",
                                    type: "internal-link",
                                    icon: LucideMessageSquareMore,
                                    hide: hideConversationFields,
                                },
                                {
                                    title: _(msg`Tags`),
                                    to: "/tags",
                                    eventName: "LNAV_SETT_TAGS",
                                    action: "create",
                                    subject: "Tags",
                                    icon: LuTag,
                                    type: "internal-link",
                                },

                                { type: "divider" },

                                {
                                    title: _(msg`WhatsApp Channel`),
                                    to: "/channels/whatsapp",
                                    action: "update",
                                    subject: "Channel",
                                    eventName: "LNAV_SETT_WACH",
                                    type: "internal-link",
                                    icon: SiWhatsapp,
                                    iconProps: { strokeWidth: "0px" },
                                    subPageConfig: [
                                        {
                                            comparisonMethod: "pattern-match",
                                            to: "/channels/whatsapp/*",
                                            hideSubNavigation: true,
                                        },
                                        {
                                            comparisonMethod: "exact",
                                            to: "/channels/whatsapp/meta-tech-partner",
                                            hideSubNavigation: true,
                                        },
                                    ],
                                },
                                {
                                    title: _(msg`Web-Chat Channel`),
                                    to: "/channels/web",
                                    action: "update",
                                    icon: LuGlobe,
                                    subject: "Channel",
                                    eventName: "LNAV_SETT_WCCH",
                                    type: "internal-link",
                                    subPageConfig: [
                                        {
                                            to: "/channels/web/*/*",
                                            comparisonMethod: "pattern-match",
                                            hideSubNavigation: true,
                                        },
                                        {
                                            to: "/channels/web/add",
                                            comparisonMethod: "exact",
                                            hideSubNavigation: true,
                                        },
                                    ],
                                },

                                { type: "divider" },

                                {
                                    title: _(msg`Webhooks`),
                                    to: "/webhook",
                                    hide: !canIReadWebhook,
                                    eventName: "LNAV_SETT_WEBH",
                                    type: "internal-link",
                                    icon: LuWebhook,
                                },
                                {
                                    title: _(msg`API Keys`),
                                    to: "/apikey",
                                    action: "read",
                                    subject: "APIKey",
                                    eventName: "LNAV_SETT_APIK",
                                    icon: LuKeyRound,
                                    type: "internal-link",
                                },
                                {
                                    title: _(msg`Activity log`),
                                    to: "/activities",
                                    action: "read",
                                    subject: "ActivityLog",
                                    eventName: "LNAV_SETT_ACTV",
                                    icon: LuHistory,
                                    type: "internal-link",
                                },
                                {
                                    title: _(msg`Allowed IPs`),
                                    icon: LucideGlobeLock,
                                    to: "/allowed-ips",
                                    action: "update",
                                    subject: "Account",
                                    eventName: "LNAV_SETT_ACTV",
                                    hide: restrictIPConfigPage,
                                    type: "internal-link",
                                },
                                /* {
                            title: _(msg`Knowledge Base`),
                            to: "/knowledge-base",
                            action: "update",
                            subject: "Account",
                            eventName: "LNAV_SETT_ACTV",
                        }, */
                            ],
                        },
                    ],
                },
            },
            analytics: {
                title: _(msg`Analytics`),
                icon: LucideChartArea,
                type: "sub-navigation",
                subNavigation: {
                    type: "popover",
                    children: [
                        {
                            title: _(msg`Analytics`),
                            children: [
                                {
                                    title: _(msg`Dashboard`),
                                    to: "/dashboard",
                                    action: "read",
                                    subject: "Analytics",
                                    eventName: "LNAV_ANAL_DASH",
                                    type: "internal-link",
                                    icon: LuLayoutDashboard,
                                },
                                {
                                    title: _(msg`WA Failed message`),
                                    to: "/analytics/report/failed-message",
                                    action: "read",
                                    subject: "Analytics",
                                    eventName: "LNAV_ANAL_WAFAI",
                                    type: "internal-link",
                                    icon: LucideMessageSquareWarning,
                                },
                                {
                                    title: _(msg`WA Notification message`),
                                    to: "/analytics/report/notification-message",
                                    action: "read",
                                    subject: "Analytics",
                                    eventName: "LNAV_ANAL_NOTI",
                                    type: "internal-link",
                                    icon: LuBell,
                                },
                                {
                                    title: _(msg`Conversation report`),
                                    to: "/conversation-report",
                                    action: "readMany",
                                    subject: "Analytics",
                                    eventName: "LNAV_ANAL_CONV",
                                    type: "internal-link",
                                    icon: LuFileText,
                                },
                            ],
                        },
                    ],
                },
            },

            help: {
                type: "sub-navigation",
                title: _(msg`Help`),
                icon: LuHelpCircle,
                buttonProps: { px: 3 },
                iconProps: { fill: "blue.500", color: "white", rounded: "full" },
                subNavigation: {
                    type: "popover",
                    children: [
                        {
                            title: _(msg`Help`),
                            children: [
                                {
                                    title: _(msg`Raise a ticket`),
                                    type: "click-action",
                                    onClick: openSupportTicketWidget,
                                    eventName: "LNAV_HELP_RAIS",
                                    hide: hideRaiseTicket || hideSupportMenu,
                                    icon: LuTicket,
                                },
                                { type: "divider" },

                                {
                                    title: _(msg`Chat with us`),
                                    type: "external-link",
                                    href: chatWithUsURL,
                                    eventName: "LNAV_HELP_CHAT",
                                    icon: LuMessageCircle,
                                },

                                {
                                    title: _(msg`Help Docs`),
                                    type: "external-link",
                                    href: "https://docs.gallabox.com/",
                                    eventName: "LNAV_HELP_HELP",
                                    icon: LuFileQuestion,
                                },
                                {
                                    title: _(msg`FAQs`),
                                    type: "external-link",
                                    href: "https://docs.gallabox.com/frequently-asked-questions",
                                    eventName: "LNAV_HELP_FAQ",
                                    icon: LucideMessageCircleQuestion,
                                },
                            ],
                        },
                    ],
                },
            },

            account: {
                title: _(msg`Account Details`),
                to: "/account",
                icon: LuUserCircle,
                eventName: "LNAV_ACC_DETAILS",
                type: "internal-link",
            },
            subscription: {
                title: _(msg`Subscription`),
                to: "/plan-and-subscription",
                icon: LuCrown,
                type: "internal-link",
                eventName: "LNAV_SUBS",
                hide: isSandboxChannel || !canIReadSubscription,
            },
            "messsage-credit": {
                title: _(msg`Message credits`),
                to: "/message-credits",
                icon: LuWallet2,
                type: "internal-link",
                eventName: "LNAV_MSSG_CREDITS",
                hide: isSandboxChannel,
                action: "read",
                subject: "Wallet",
            },

            user: {
                title: _(msg`Users, Teams & Roles`),
                to: "/roles",
                icon: FiUsers,
                type: "internal-link",
                eventName: "LNAV_USER_ROLES",
                action: "read",
                subject: "UsersAndRoles",
            },
            profile: {
                title: _(msg`Profile`),
                to: "/profile",
                icon: LuUserCog,
                type: "internal-link",
                eventName: "LNAV_PROFILE",
            },
            "knowledge-base": {
                title: _(msg`Knowledge Base`),
                to: "/knowledge-base",
                action: "update",
                subject: "Account",
                eventName: "LNAV_SETT_ACTV",
                icon: LuLightbulb,
                type: "internal-link",
            },
            "notification-settings": {
                title: _(msg`Notification Settings`),
                icon: LuBellRing,
                to: "/notification-settings",
                type: "internal-link",
                eventName: "LNAV_PUSH_NOTIFICATIONS",
            },
            logout: {
                title: _(msg`Logout`),
                to: "/logout",
                icon: LuLogOut,
                type: "internal-link",
                eventName: "LNAV_LOGOUT",
                buttonProps: {
                    color: "red.400",
                },
                iconProps: { color: "red.400" },
            },
        };
    }, [
        _,
        canIReadSubscription,
        canIReadWebhook,
        chatWithUsURL,
        hideRaiseTicket,
        hideSupportMenu,
        isPartnerUser,
        isSandboxChannel,
        restrictIPConfigPage,
        hideConversationFields,
    ]);

    return { navigationItems };
};
