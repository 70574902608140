import { LabelValue, WATemplateValues, YupSchema } from "app/types";
import { PromptAction } from "app/types/bot";
import { TriggerConditionProperties } from "app/screens/Integration/types/woocommerce";
import * as yup from "yup";
import { financialStatusValues } from "./Shopify/CreateWorkFlow";
import { BodyFormValues } from "./components/MappingIntegrationTemplateCommon";
import { t } from "@lingui/macro";
import { buttonSchema } from "app/screens/Account/WATemplatesV2/SendWATemplates/EditTemplateVariables/helpers";
import { statusOptions } from "./constants";
import { WAITemplateMessage } from "app/types";
import { TemplateMessageFormValues } from "./CashFree/CashFreeWorkFlow";
import {
    deSanitizeBodyValuesToFormValues,
    deSanitizeWAITemplateToEditForm,
} from "app/screens/Account/WATemplatesV2/SendWATemplates/EditTemplateVariables/helpers";
import { CashFreeEventTriggers } from "./types/cashfree";
import { CreateCashFreeFlow } from "./CashFree/CashFreeWFForm";
import { ElementRef } from "react";
import TemplateManager from "./components/TemplateManager";
import { TemplateMessageCardFormValues } from "../Account/WATemplatesV2/SendWATemplates/EditTemplateVariables";
import { getMustacheVariables } from "../Account/WATemplatesV2/utilities/utils";
import { FILE_URL_REGEX } from "app/utils/validation";

export const sanitizeBodyFormValues = (data: BodyFormValues): WATemplateValues => {
    const { bodyValues: bv, buttonValues: btnValues, headerValues } = data;
    const bodyValues = Object.fromEntries(Object.entries(bv ?? {}).filter(([_, v]) => v != null));
    const isEmpty = Object.values(headerValues ?? {}).every((x) => x === null || x === "");
    const buttonValues = btnValues?.filter((v) => v.parameters[v.parameters.type]);

    const templateValues = {
        bodyValues,
        headerValues: isEmpty ? {} : headerValues,
        buttonValues,
    };
    return templateValues;
};

const sanitizeFormValuesToBodyValues = (bodyValues: Record<string, any>) => {
    return bodyValues?.reduce(
        (acc: Record<string, string>, bv: { variable: string; value: string }) => ({
            ...acc,
            [bv.variable]: bv.value,
        }),
        {}
    );
};

export const sanitizeFormValuesToWATemplateValues = (values: BodyFormValues): WATemplateValues => {
    const { buttonValues, bodyValues, headerValues, cardValues } = values;
    const formValues: WATemplateValues = {
        ...(buttonValues ? { buttonValues: buttonValues.filter((b) => Boolean(b.parameters[b.parameters.type])) } : {}),
        ...(Boolean(headerValues?.mediaUrl) ? { headerValues } : {}),
        ...(bodyValues
            ? {
                  bodyValues: bodyValues?.reduce(
                      (acc: Record<string, string>, bv: { variable: string; value: string }) => ({
                          ...acc,
                          [bv.variable]: bv.value,
                      }),
                      {}
                  ),
              }
            : {}),
        ...(cardValues
            ? {
                  cardValues: cardValues.map(({ bodyValues, buttonValues, headerValues }, index) => ({
                      index,
                      ...(bodyValues ? { bodyValues: sanitizeFormValuesToBodyValues(bodyValues) } : {}),
                      ...(Boolean(headerValues?.mediaUrl) ? { headerValues } : {}),
                      ...(buttonValues
                          ? { buttonValues: buttonValues.filter((b) => Boolean(b.parameters[b.parameters.type])) }
                          : {}),
                  })),
              }
            : {}),
    };
    return formValues;
};

export interface WebhookConditions {
    fact: string;
    operator: LabelValue;
    value?: string;
    selectValue?: LabelValue;
    path: {
        label: string;
        value: string;
        type?: string;
    };
    meta?: {
        pathName: string;
        operatorName: string;
    };
}

export type Conditions = "all" | "any";

export type CreateConditions = {
    [event in Conditions]: TriggerConditionProperties[];
};

export interface WorkflowConditions {
    condition: CreateConditions;
    actions?: PromptAction[];
}

export interface CreateWebHookFlowConditions {
    status?: string;
    filter?: LabelValue;
    workflowConditions?: WebhookConditions[];
    actions?: PromptAction[];
}

export const filterOptions: LabelValue[] = [
    {
        label: "ALL",
        value: "all",
    },
    {
        label: "ANY",
        value: "any",
    },
];

export const conditionalOperator: LabelValue[] = [
    { label: "Is Empty", value: "isEmpty" },
    { label: "Is Not Empty", value: "isNotEmpty" },
    { label: "Equal", value: "softEqual" },
    { label: "Not equal", value: "softNotEqual" },
];

const optionSchema = (msg?: string) => {
    return yup
        .object()
        .shape<YupSchema<LabelValue>>({
            label: yup.string().trim().required(msg),
            value: yup.string().trim().required(msg),
        })
        .nullable();
};

const webhookConditions = yup.object().shape<YupSchema<WebhookConditions>>({
    fact: yup.string().optional(),
    operator: optionSchema("Operator is Required").required("Operator is Required"),
    path: optionSchema("Comparator is Required").required("Comparator is Required"),
    value: yup.string().when(["path", "operator"], {
        is: (path: LabelValue, operator: LabelValue) =>
            !(path?.value === "status" || path?.value === "$.status") &&
            !(operator?.value === "isNotEmpty" || operator?.value === "isEmpty"),
        then: yup.string().required("Value is Required"),
        otherwise: yup.string().optional(),
    }),
    selectValue: yup.object().when(["path", "operator"], {
        is: (path: LabelValue, operator: LabelValue) =>
            (path?.value === "status" || path?.value === "$.status") &&
            !(operator?.value === "isNotEmpty" || operator?.value === "isEmpty"),
        then: optionSchema("Value is Required").required("Value is Required"),
        otherwise: yup.object().optional(),
    }),
    meta: yup.object().optional(),
});

const operationSchema = yup.array().of(webhookConditions).nullable();

export const conditionsSchema = (isNew: boolean): yup.AnyObjectSchema => {
    return yup.object().shape<YupSchema<Omit<CreateWebHookFlowConditions, "actions">>>({
        status: isNew ? yup.string().required("value is Required") : yup.string().optional(),
        filter: isNew ? optionSchema("Value is Required").required("Value is Required") : yup.object().optional(),
        workflowConditions: yup
            .mixed()
            .when("filter", {
                is: null,
                then: yup.mixed().strip(),
                otherwise: operationSchema,
            })
            .nullable(),
    });
};

const getFilterValues = (value: Conditions): LabelValue => {
    if (value === "all") {
        return { label: "ALL", value: "all" };
    } else if (value === "any") {
        return {
            label: "ANY",
            value: "any",
        };
    }

    return { label: "Select", value: "" };
};

export const comparatorOptions: LabelValue[] = [
    {
        label: "Status",
        value: "status",
    },
    {
        label: "Payment method",
        value: "payment_method",
    },
    {
        label: "Total Price",
        value: "total",
    },
];

const integrationVariant = ["woocommerce", "shopify", "genericwebhook"] as const;
export type IntegrationTypes = (typeof integrationVariant)[number];

const getProperConditionValue = (type: IntegrationTypes, data: WebhookConditions) => {
    if (
        type === "shopify" &&
        (data.path?.value?.includes("financial_status") || data.path?.value?.includes("shipment_status"))
    ) {
        return (data?.selectValue as unknown as LabelValue)?.value;
    }
    if (type === "woocommerce" && data.path?.value?.includes("status")) {
        return (data?.selectValue as unknown as LabelValue)?.value;
    }
    return data?.value;
};

const deSanitizeValues = (type: IntegrationTypes, data: TriggerConditionProperties) => {
    if (type === "shopify" && data?.meta?.pathName === "Financial Status") {
        return financialStatusValues.find((v) => v.value === data?.value);
    }

    if (type === "woocommerce" && data?.meta?.pathName === "Status") {
        return statusOptions.find((c) => c.value === data?.value);
    }

    return data?.value;
};

const getLabelValue = (type: IntegrationTypes, data: WebhookConditions) => {
    if (type === "shopify" && data?.path?.value?.includes("financial_status")) {
        return data?.selectValue?.label;
    }

    if (type === "woocommerce" && data?.path?.value?.includes("status")) {
        return data?.selectValue?.label;
    }
};

export const sanitizeConditionsFormData = (
    data: CreateWebHookFlowConditions,
    integrationType: IntegrationTypes
): WorkflowConditions => {
    const { workflowConditions, filter, actions } = data;
    const actionsInput = actions?.map((a) => {
        if (!a.actionValues?.operation) {
            return {
                actionItem: a.actionItem,
                actionValues: { ...a.actionValues, operation: a.actionItem.operations?.[0] },
            };
        }
        return a;
    });

    const validConditions: TriggerConditionProperties[] = (workflowConditions ?? [])?.map((v) => {
        const conditionValue = getProperConditionValue(integrationType, v);
        const labelValue = getLabelValue(integrationType, v);

        return {
            fact: integrationType,
            operator: v.operator.value,
            path: v.path.value.includes("$") ? v.path.value : `$.${v.path.value}`,
            value: conditionValue ?? "",
            meta: {
                pathName: v.path.label,
                operatorName: v.operator.label,
                valueLabel: labelValue as string,
            },
        };
    });

    return {
        condition: {
            [filter?.value as Conditions]: validConditions,
        } as CreateConditions,
        actions: actionsInput,
    };
};

export const pathValue = new Map<IntegrationTypes, string>([
    ["shopify", "Financial Status"],
    ["woocommerce", "Status"],
]);

export const deSanitizeConditionsData = (
    data: WorkflowConditions["condition"],
    integrationType: IntegrationTypes
): CreateWebHookFlowConditions => {
    const filterValue = Object.keys(data ?? {})[0];
    const filter = getFilterValues(filterValue as Conditions);

    const conditionsRules: TriggerConditionProperties[] =
        data?.[filter?.value as keyof WorkflowConditions["condition"]] ?? [];

    const workflowConditions: WebhookConditions[] = conditionsRules?.map((c) => {
        const conditionValues = deSanitizeValues(integrationType, c);
        const pathName = pathValue.get(integrationType);
        return {
            fact: integrationType,
            operator: {
                label: c.meta?.operatorName as string,
                value: c.operator,
            },
            path: {
                label: c.meta?.pathName as string,
                value: c.path,
            },
            value: c.meta?.pathName != pathName ? c.value : undefined,
            selectValue: c.meta?.pathName === pathName ? conditionValues : undefined,
        };
    });

    return {
        filter,
        workflowConditions,
    };
};

export interface ZohoBooksWorkFlowListProps {
    title: string;
    icon: string;
    description: string;
    topic: string;
}

export const createDefaultBodyValues = (templateBodyValues: WATemplateValues["bodyValues"]) => {
    const bodyValues = templateBodyValues ? {} : templateBodyValues;

    if (templateBodyValues && bodyValues) {
        Object.entries(templateBodyValues).forEach((each) => {
            Object.assign(bodyValues, { [each[1].variable]: each[1].value });
        });
    }

    return bodyValues;
};

type FormBodyValues = {
    variable: string;
    value: string;
};

const formBodyValuesSchema = () =>
    yup.array().of(
        yup.object().shape<YupSchema<FormBodyValues>>({
            variable: yup.string().required(t`Value is required`),
            value: yup.string().required(t`Value is required`),
        })
    );

const mediaUrlSchema = () =>
    yup
        .string()
        .required(t`MediaUrl is required`)
        .test("validLink", t`Invalid media url`, (v) => {
            if (!v) return true;
            const hasMustachedVariable = getMustacheVariables(v).length > 0;
            if (hasMustachedVariable) return true;
            return FILE_URL_REGEX.test(v);
        });

export const templateEditIntegrationFormSchema = () => {
    return yup.object().shape({
        bodyValues: formBodyValuesSchema(),
        headerValues: yup.lazy((value) => {
            if (value === undefined) {
                return yup.mixed().nullable();
            }
            return yup.object().shape({
                mediaName: yup.string().optional(),
                mediaUrl: yup.string().optional(),
            });
        }) as unknown as yup.AnySchema,
        buttonValues: yup.array().of(buttonSchema),
        cardValues: yup.array().of(
            yup.object().shape<YupSchema<TemplateMessageCardFormValues>>({
                index: yup.number().optional(),
                bodyValues: formBodyValuesSchema(),
                headerValues: yup.object().shape({
                    mediaName: yup.string().optional(),
                    mediaUrl: mediaUrlSchema(),
                }),
                buttonValues: yup.array().of(buttonSchema),
            })
        ),
        limitedTimeOfferValue: yup.lazy((value) => {
            if (value === undefined) {
                return yup.mixed().nullable();
            }
            return yup.object().shape({
                expiration_time_ms: yup.number().required(),
            });
        }) as unknown as yup.AnySchema,
    });
};

export const desanitizedTemplateValues = (
    template: WAITemplateMessage | undefined,
    templateValues: WATemplateValues
): TemplateMessageFormValues => {
    if (!template) return {};
    const { formValues } = deSanitizeWAITemplateToEditForm({ template });

    if (Boolean(Object.keys(templateValues).length)) {
        const { bodyValues, headerValues, buttonValues, cardValues, ...rest } = templateValues;

        return {
            ...rest,
            buttonValues: buttonValues?.length ? buttonValues : formValues.buttonValues,
            ...(!headerValues?.mediaUrl && formValues.headerValues?.mediaUrl
                ? {
                      headerValues: {
                          mediaName: formValues.headerValues?.mediaName,
                          mediaUrl: formValues.headerValues?.mediaUrl,
                      },
                  }
                : { headerValues }),
            bodyValues: deSanitizeBodyValuesToFormValues(bodyValues),
            cardValues: cardValues?.map((card, index) => {
                return {
                    index: index,
                    headerValues: {
                        mediaName: card.headerValues?.mediaName,
                        mediaUrl: card.headerValues?.mediaUrl,
                    },
                    bodyValues: deSanitizeBodyValuesToFormValues(card.bodyValues),
                    buttonValues: card.buttonValues,
                };
            }),
        };
    }

    return formValues;
};

export const deSanitizeWorkflowValues = (value: CashFreeEventTriggers, validTemplate?: WAITemplateMessage) => {
    const { templateValues } = value ?? {};

    const sendWATemplateMessage = {
        templateValues: desanitizedTemplateValues(validTemplate, templateValues),
    };

    const resValue: CreateCashFreeFlow = {
        sendWATemplateMessage: sendWATemplateMessage,
    };

    return resValue;
};

const templateValuesSchema = yup.object().shape({
    templateValues: templateEditIntegrationFormSchema(),
});

export const schema = yup.object().shape<YupSchema<CreateCashFreeFlow>>({
    sendWATemplateMessage: templateValuesSchema,
});

export type TemplateManagerHandle = ElementRef<typeof TemplateManager>;

export const templateMessageSchema = yup.object().shape({
    templateId: yup.string(),
    template: yup.object().optional(),
    templateValues: templateEditIntegrationFormSchema(),
    recipient: yup.object().shape({
        recipientName: yup.string().optional(),
        recipientNumber: yup.string().optional(),
    }),
});
