import { PopoverBody, PopoverContent } from "@chakra-ui/react";
import { IntegrationField } from "app/screens/Integration/types/integrationField";
import React, { FC } from "react";
import VariableListItem from "./IntegrationVariableListItem";

interface ExpressionListMenuProps {
    onSelect: (variable: string) => void;
    IntegrationFieldVariables?: Partial<IntegrationField>[];
    isLoading?: boolean;
}

const ExpressionListMenu: FC<ExpressionListMenuProps> = (props) => {
    const { onSelect, IntegrationFieldVariables, isLoading } = props;

    return (
        <PopoverContent boxShadow="xs">
            <PopoverBody h="full" maxH="150px" overflowY="auto" p={0}>
                <VariableListItem isLoading={isLoading} onSelect={onSelect} variables={IntegrationFieldVariables} />
            </PopoverBody>
        </PopoverContent>
    );
};

export default ExpressionListMenu;
