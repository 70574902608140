import { useToast } from "@chakra-ui/react";
import { getWebinarBanner } from "app/database/supabase/supabase.statics";
import { WebinarTableType } from "app/database/supabase/supabase.types";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { AccountOnboardingComplete, IAccountOnboarding } from "app/screens/Account/AccountOnboarding/getStarted.types";
import { OnboardingJourneySteps } from "app/screens/Onboarding/SelfServe/AccountInitialSetup/types";
import { User } from "app/types";
// import { Id, Team } from "app/screens/Account/MembersAndRoles/MemberTable/UserTeamsTable";
import {
    Account,
    BackStageJob,
    CreatableIP,
    CurrencyData,
    IP,
    IPConfig,
    Meta,
    OnboardingState,
    CreateAdminInvitePayload,
    REDIRECTION_SCREEN,
    Timezone,
    TourStep,
} from "app/types/account";
import { QueryKey, Timestamp, TQueryKey } from "app/types/common";
import { deleteJSON, fetcher, mapQueryParams, patchJSON, postJSON } from "app/utils/fetchUtils";
import { useAccountId } from "app/utils/react-helpers";
import { remove, update } from "app/utils/react-query";
import axios from "axios";
import Driver from "driver.js";
import { HOME_APP_URL_ENV } from "environment";
import Cookies from "js-cookie";
import {
    MutationFunction,
    QueryFunction,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from "react-query";

export type WebhookForm = Pick<Webhook, "events" | "requestUrl" | "secret"> & {
    requestHeaders?: Record<string, string>;
    headers?: { key: string; value: string }[];
};

export interface CreateApiKey {
    name: string;
    permissions: [
        {
            rules: {
                actions: string[];
                subjects: string[];
            }[];
            boundary: string;
        },
    ];
    secret?: string;
}

export interface GetAccount {
    //incoming type
    name?: string;
    country?: string;
    website?: string;
    size?: number;
    industry?: string;
    mobile?: string;
    tz?: Timezone;
    currency?: CurrencyData;
    logoUrl?: string;
    id?: string;
}

export interface UseGetApiKeyProps {
    accountId?: string;
    apiKeyId?: string;
}

export const permissionScopes = ["all", "read-only", "restricted"] as const;
export type PermissionScope = (typeof permissionScopes)[number];

export interface APIKey {
    id: string;
    accountId: string;
    name: string;
    secret: string;
    permissions: [
        {
            rules: {
                actions: string[];
                subjects: string[];
            }[];
            boundary: string;
        },
    ];
    createdAt: Date;
    updatedAt: Date;
    creatorId: string;
    creator?: User;
    permissionScope?: PermissionScope;
}

export interface UseGetBackStageJobProps {
    accountId: string;
    jobType?: string;
}

const PAGE_SIZE = 20;

const fetchApiKeyList: QueryFunction<APIKey[], TQueryKey> = ({ queryKey }) => {
    const [, { accountId }] = queryKey;
    return fetcher(`/api/accounts/${accountId}/apiKeys`);
};

export const useGetApiKeyList = (props: UseGetApiKeyProps): UseQueryResult<APIKey[], Error> => {
    const { accountId } = props;

    const toast = useToast();

    return useQuery<APIKey[], Error, APIKey[], TQueryKey>([QueryKey.APIKeysList, { accountId }], fetchApiKeyList, {
        enabled: Boolean(accountId),
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message,
            });
        },
    });
};

const fetchApiKey: QueryFunction<APIKey[], TQueryKey> = ({ queryKey }) => {
    const [, { accountId, apiKeyId }] = queryKey;
    return fetcher(`/api/accounts/${accountId}/apiKeys/${apiKeyId}`);
};

export const useGetApiKey = (props: UseGetApiKeyProps): UseQueryResult<APIKey[], Error> => {
    const { accountId, apiKeyId } = props;

    const toast = useToast();

    return useQuery<APIKey[], Error, APIKey[], TQueryKey>([QueryKey.APIKeys, { accountId, apiKeyId }], fetchApiKey, {
        enabled: Boolean(accountId),
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message,
            });
        },
    });
};

type UseApiKey<InputType> = UseMutationResult<CreateApiKey, Error, InputType>;

interface UseApiKeyProps {
    accountId: string;
    apiKeyId?: string;
    onSuccess?: (ApiKey: CreateApiKey) => void;
    showSuccessMessage?: boolean;
}

export const usePostApiKey = <InputType extends Partial<CreateApiKey>>(props: UseApiKeyProps): UseApiKey<InputType> => {
    const { accountId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.CreateAPIKey, { accountId }];

    const createApiKey = (profile: InputType) => {
        return postJSON<InputType>(`/api/accounts/${accountId}/apiKeys`, profile);
    };

    const mutationResult = useMutation<CreateApiKey, Error, InputType>(createApiKey, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (ApiKey) => {
            if (showSuccessMessage) {
                toast({
                    title: "API Key created",
                    status: "success",
                    description: "API Key created successfully!",
                });
            }
            onSuccess?.(ApiKey);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

export const useUpdateApiKey = <InputType extends Partial<CreateApiKey>>(
    props: UseApiKeyProps
): UseApiKey<InputType> => {
    const { accountId, apiKeyId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.UpdateAPIKey, { accountId, apiKeyId }];

    const updateApiKey = (profile: InputType) => {
        return postJSON<InputType>(`/api/accounts/${accountId}/apiKeys/${apiKeyId}`, profile);
    };

    const mutationResult = useMutation<CreateApiKey, Error, InputType>(updateApiKey, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (ApiKey) => {
            if (showSuccessMessage) {
                toast({
                    title: "API Key Updated",
                    status: "success",
                    description: "API Key updated successfully!",
                });
            }
            onSuccess?.(ApiKey);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

type UseDeleteApikey = UseMutationResult<APIKey, Error, { accountId: string; apiKeyId: string }>;

export const useDeleteApiKey = (accountId: string, apiKeyId: string, onSuccess?: () => void): UseDeleteApikey => {
    const queryClient = useQueryClient();
    const toast = useToast({ isClosable: true, duration: 2000 });

    const queryKey: string | unknown[] = [QueryKey.AdditionalFields, { accountId, apiKeyId }];

    const deleteApiKey: MutationFunction<APIKey, { accountId: string; apiKeyId: string }> = ({
        accountId,
        apiKeyId,
    }) => {
        return deleteJSON<APIKey>(`/api/accounts/${accountId}/apiKeys/${apiKeyId}`);
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const mutationResult = useMutation<APIKey, Error, { accountId: string; apiKeyId: string }>(deleteApiKey, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedField, context) => {
            queryClient.setQueryData(queryKey, context);
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while deleting field",
            });
        },
        onSuccess: () => {
            toast({
                title: "API key Deleted",
                status: "success",
                description: "API key deleted successfully!",
            });
            onSuccess?.();
        },

        onMutate: async ({ apiKeyId }) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);

            // Snapshot the previous value
            const previousApiKeys = queryClient.getQueryData<APIKey[]>(queryKey);

            remove<APIKey>(queryClient, queryKey, apiKeyId);

            // Optionally return a context containing data to use when for example rolling back
            return previousApiKeys ?? [[]];
        },

        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

type UseCreateAdminInviteResult = UseMutationResult<Invite, Error, CreateAdminInvitePayload>;
type CreateAdminInviteProps = UseMutationOptions<Invite, Error, CreateAdminInvitePayload>;
export const useCreateAdminInvite = (props: CreateAdminInviteProps): UseCreateAdminInviteResult => {
    const { onError, ...options } = props;
    const toast = useToast({ position: "bottom-right", isClosable: true, duration: 5000 });
    return useMutation<Invite, Error, CreateAdminInvitePayload>(
        (vars) => {
            let tapfiliateTrackingId: string | undefined;
            try {
                tapfiliateTrackingId = Cookies.get("tap_vid") || undefined;
            } catch {}
            return postJSON<CreateAdminInvitePayload>("/auth/admin/invite", { ...vars, tapfiliateTrackingId });
        },
        {
            onError: (...args) => {
                const [error] = args;
                onError?.(...args);
                toast({ title: "Error", status: "error", description: error.message });
            },
            ...options,
        }
    );
};

interface GetWebhookFilter {
    includeDisabledWebhooks?: boolean;
}

interface UseGetWebhookProps extends GetWebhookFilter {
    accountId: string;
    webhookId?: string;
    onSuccess?: (webhook: WebhookForm) => void;
    showSuccessMessage?: boolean;
}
interface Pagination {
    page: number;
    limit?: number;
}
export interface WebhookEvent {
    eventType: string;
    eventGroup: string[];
}

const fetchWebhookEvent: QueryFunction<WebhookEvent[], TQueryKey> = ({ queryKey }) => {
    const [, { accountId }] = queryKey;
    return fetcher(`/api/accounts/${accountId}/webhooks/events`);
};

export const useGetWebhookEvent = (props: UseGetWebhookProps): UseQueryResult<WebhookEvent[], Error> => {
    const { accountId } = props;

    const toast = useToast();

    return useQuery<WebhookEvent[], Error, WebhookEvent[], TQueryKey>(
        [QueryKey.WebhookEventList, { accountId }],
        fetchWebhookEvent,
        {
            enabled: Boolean(accountId),
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
        }
    );
};

export interface Webhook {
    events: string[];
    disabled: boolean;
    requestUrl: string;
    createdAt: Date;
    id: string;
    secret?: string;
    requestHeaders: Record<string, string>;
}
export interface GetWebhookResult {
    data: Webhook[];
    count: number;
}
const fetchWebhook: QueryFunction<GetWebhookResult, TQueryKey> = ({ queryKey }) => {
    const [, { accountId, page, limit, includeDisabledWebhooks }] = queryKey;
    const queryParams = mapQueryParams({
        page,
        limit,
        listAllWebhooks: true,
        includeDisabledWebhooks,
        includeDecryptedSecret: true,
    });
    return fetcher(`/api/accounts/${accountId}/webhooks?${queryParams}`);
};

export const useGetWebhook = (props: UseGetWebhookProps & Pagination): UseQueryResult<GetWebhookResult, Error> => {
    const { accountId, page, limit = PAGE_SIZE, includeDisabledWebhooks } = props;

    return useQuery<GetWebhookResult, Error, GetWebhookResult, TQueryKey>(
        [QueryKey.WebhookList, { accountId, page, limit, includeDisabledWebhooks }],
        fetchWebhook
    );
};

type UseWebhook<InputType> = UseMutationResult<WebhookForm, Error, InputType>;

export const usePostWebhook = <InputType extends Partial<WebhookForm>>(
    props: UseGetWebhookProps
): UseWebhook<InputType> => {
    const { accountId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.CreateWebhook, { accountId }];

    const createWebhook = (webhook: InputType) => {
        return postJSON<InputType>(`/api/accounts/${accountId}/webhooks`, webhook);
    };

    const mutationResult = useMutation<WebhookForm, Error, InputType>(createWebhook, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (webhook) => {
            if (showSuccessMessage) {
                toast({
                    title: "Webhook created",
                    status: "success",
                    description: "Webhook created successfully!",
                });
            }
            onSuccess?.(webhook);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

type UsePatchAccountDetail<InputType> = UseMutationResult<Account, Error, InputType>;

export const usePatchAccountDetail = <InputType extends Partial<Account>>({
    accountId,
    onSuccess,
}: {
    accountId: string;
    onSuccess: (data: Account) => void;
}): UsePatchAccountDetail<InputType> => {
    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.AccountDetailFetch, { accountId }];

    const updateContactImport = (data: InputType) => {
        return patchJSON<Account>(`api/account/${accountId}`, data);
    };

    const mutationResult = useMutation<Account, Error, InputType>(updateContactImport, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast.closeAll();
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (data) => {
            toast.closeAll();
            toast({
                title: "Account updated Successfully",
                status: "success",
                description: "",
            });
            onSuccess(data);
        },
        // When mutate is called:
        onMutate: async (updatedAccount) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);

            // Snapshot the previous value
            const previousDetails = queryClient.getQueryData<Account>(queryKey);

            if (!previousDetails) return {};

            const updated = { ...previousDetails, ...updatedAccount };

            queryClient.setQueryData<Account & InputType>(queryKey, updated);

            // Optionally return a context containing data to use when for example rolling back
            return { previousDetails };
        },
    });

    return mutationResult;
};

type WebhooktQueryContext = Webhook[];

// eslint-disable-next-line @typescript-eslint/ban-types
type UseUpdateWebhook = UseMutationResult<Webhook, Error, Partial<Webhook>, WebhooktQueryContext>;

export const useUpdateWebhook = (accountId: string, webhookId: string, onSuccess?: () => void): UseUpdateWebhook => {
    const queryClient = useQueryClient();
    const toast = useToast({ isClosable: true, duration: 2000 });
    const queryKey: string | unknown[] = [QueryKey.UpdateWebhook, { accountId, webhookId }];

    const updateWebhook: MutationFunction<Webhook, Partial<Webhook>> = async ({ ...data }) => {
        return await patchJSON<Webhook>(`/api/accounts/${accountId}/webhooks/${webhookId}`, data);
    };

    return useMutation(updateWebhook, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedWebhook, context) => {
            queryClient.setQueryData(queryKey, context);
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while updating webhook",
            });
        },

        onSuccess: () => {
            toast({
                title: "Webhook Updated",
                status: "success",
                description: "Webhook updated successfully!",
            });
            onSuccess?.();
        },

        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
};

// eslint-disable-next-line @typescript-eslint/ban-types
type UseActivateWebhook = UseMutationResult<Webhook, Error, void, WebhooktQueryContext>;

export const useActivateWebhook = (
    accountId: string,
    webhookId: string,
    onSuccess?: () => void
): UseActivateWebhook => {
    const queryClient = useQueryClient();
    const toast = useToast({ isClosable: true, duration: 2000 });
    const queryKey: string | unknown[] = [QueryKey.UpdateWebhook, { accountId, webhookId }];

    const activateWebhook: MutationFunction<Webhook, void> = async () => {
        return await patchJSON<Webhook>(`/api/accounts/${accountId}/webhooks/activateWebhook/${webhookId}`);
    };

    return useMutation(activateWebhook, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedWebhook, context) => {
            queryClient.setQueryData(queryKey, context);
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while activate webhook",
            });
        },

        onSuccess: () => {
            toast({
                title: "Webhook Activated",
                status: "success",
                description: "Webhook activated successfully!",
            });
            onSuccess?.();
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
};

const fetchAccountDetails: QueryFunction<Account, TQueryKey> = ({ queryKey }) => {
    const [, { accountId }] = queryKey;
    return fetcher(`/api/account/${accountId}`);
};

export const useGetAccountDetails = (props: UseGetApiKeyProps): UseQueryResult<Account, Error> => {
    const { accountId } = props;

    const toast = useToast();

    return useQuery<Account, Error, Account, TQueryKey>(
        [QueryKey.AccountDetailFetch, { accountId }],
        fetchAccountDetails,
        {
            enabled: Boolean(accountId),
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
        }
    );
};

type UseDeleteWebhook = UseMutationResult<WebhookForm, Error, { accountId: string; webhookId: string }, unknown>;

export const useDeleteWebhook = (accountId: string, webhookId: string, onSuccess?: () => void): UseDeleteWebhook => {
    const queryClient = useQueryClient();
    const toast = useToast({ isClosable: true, duration: 2000 });

    const queryKey: string | unknown[] = [QueryKey.DeleteWebhook, { accountId, webhookId }];

    const deleteWebhook: MutationFunction<WebhookForm, { accountId: string; webhookId: string }> = ({
        accountId,
        webhookId,
    }) => {
        return deleteJSON<WebhookForm>(`/api/accounts/${accountId}/webhooks/${webhookId}`);
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const mutationResult = useMutation<WebhookForm, Error, { accountId: string; webhookId: string }>(deleteWebhook, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedWebhook, context) => {
            queryClient.setQueryData(queryKey, context);
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while deleting webhook",
            });
        },
        onSuccess: () => {
            toast({
                title: "Webhook Deleted",
                status: "success",
                description: "Webhook deleted successfully!",
            });
            onSuccess?.();
        },

        onMutate: async ({ webhookId }) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);

            // Snapshot the previous value
            const previousApiKeys = queryClient.getQueryData<WebhookForm[]>(queryKey);

            remove<Webhook>(queryClient, queryKey, webhookId);

            // Optionally return a context containing data to use when for example rolling back
            return previousApiKeys ?? [[]];
        },

        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface RedirectionScreenContext {
    channelId?: string;
}

export interface AccountRedirection {
    webPage: REDIRECTION_SCREEN | OnboardingJourneySteps;
    context?: RedirectionScreenContext;
    onboarding?: Account["onboarding"];
}

type UseAccountRedirection = UseMutationResult<AccountRedirection, Error, void>;
interface UseAccountRedirectionProps {
    accountId: string;
    onSuccess?: (response: AccountRedirection) => void;
    onError?: (error: Error) => void;
}

export const usePostAccountRedirection = (props: UseAccountRedirectionProps): UseAccountRedirection => {
    const { accountId, onSuccess, onError } = props;

    const fetchAccountRedirection: MutationFunction<AccountRedirection, void> = () => {
        return postJSON(`/api/accounts/${accountId}/home`, {});
    };

    const mutationResult = useMutation<AccountRedirection, Error, void>(fetchAccountRedirection, {
        onError,
        onSuccess,
    });

    return mutationResult;
};

type BackStageJobListQueryOptions = UseInfiniteQueryOptions<
    BackStageJob[],
    Error,
    BackStageJob[],
    BackStageJob[],
    TQueryKey
>;

interface UseBackStageJobListProps extends BackStageJobListQueryOptions {
    accountId: string;
    jobType?: string;
    populatePath?: string[];
    backStagedId?: string;
}

export const useBackStagedJobList = (
    props: UseBackStageJobListProps
): UseInfiniteQueryResult<BackStageJob[], Error> => {
    const { accountId, jobType, ...options } = props;
    const infiniteQueryResult = useInfiniteQuery<BackStageJob[], Error, BackStageJob[], TQueryKey>(
        [QueryKey.BackStageJobList, { accountId, jobType }],
        ({ pageParam, queryKey }) => {
            const [, { accountId, jobType }] = queryKey;
            const queryParams = mapQueryParams({ type: jobType, since: pageParam, limit: PAGE_SIZE });
            const url = `/api/accounts/${accountId}/backstagejobs?${queryParams}&populatePaths=creator`;
            return fetcher(url);
        },
        {
            ...options,
            getNextPageParam: (lastPage) => {
                if (!lastPage?.length) return undefined;
                return lastPage.length === PAGE_SIZE ? [...lastPage].reverse()?.[0]?.id : undefined;
            },
        }
    );
    return infiniteQueryResult;
};

export const useBackStageRefresh = (
    props: UseBackStageJobListProps
): UseMutationResult<BackStageJob, Error, string> => {
    const { accountId, jobType } = props;
    const toast = useToast();

    const fetchBackStage: MutationFunction<BackStageJob, string> = (backStageId) => {
        return fetcher(`/api/accounts/${accountId}/backstagejobs/${backStageId}?type=${jobType}`);
    };
    const queryClient = useQueryClient();

    return useMutation<BackStageJob, Error, string>(fetchBackStage, {
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message,
            });
        },
        onSuccess: (updatedBackStage, backStagedId) => {
            const queryKey = [QueryKey.BackStageJobList, { accountId, jobType }];
            update<BackStageJob>(queryClient, queryKey, backStagedId, updatedBackStage);
        },
    });
};

export const useBackStageDelete = (props: UseBackStageJobListProps): UseMutationResult<BackStageJob, Error, string> => {
    const { accountId, jobType } = props;
    const toast = useToast();

    const fetchBackStage: MutationFunction<BackStageJob, string> = (backStageId) => {
        return deleteJSON(`/api/accounts/${accountId}/backstagejobs/${backStageId}?type=${jobType}`);
    };
    const queryClient = useQueryClient();

    return useMutation<BackStageJob, Error, string>(fetchBackStage, {
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message,
            });
        },
        onSuccess: (updatedBackStage, backStagedId) => {
            const queryKey = [QueryKey.BackStageJobList, { accountId, jobType }];
            remove<BackStageJob>(queryClient, queryKey, backStagedId);
        },
    });
};

type UseTourUpdate = UseMutationResult<Meta, Error, void>;
interface UseTourUpdateProps {
    accountId: string;
    tourLocation: TourStep[];
    onSuccess?: (response: Meta) => void;
    onError?: (error: Error) => void;
}

export const useUpdateTourStatus = (props: UseTourUpdateProps): UseTourUpdate => {
    const { accountId, tourLocation, onSuccess } = props;
    const toast = useToast();

    const updateTour: MutationFunction<Meta, void> = () => {
        return patchJSON(`/api/accounts/${accountId}/user/tour-completed`, { tourCompleted: tourLocation });
    };

    const mutationResult = useMutation<Meta, Error, void>(updateTour, {
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message,
            });
        },
        onSuccess,
    });

    return mutationResult;
};

interface OtpVerifyResponse {
    isValidOtp: boolean;
    inviteName: string;
    inviteCode: string;
    inviteEmail: string;
}

interface usePostAccountOtpVerifyProps {
    onSuccess?: (response: OtpVerifyResponse) => void;
    onError?: (error: Error) => void;
}

interface OtpVerifyInput {
    inviteEmail: string;
    otp: string;
}

type UsePostAccountOtpVerify = UseMutationResult<OtpVerifyResponse, Error, OtpVerifyInput>;

export const usePostAccountOtpVerify = (props: usePostAccountOtpVerifyProps): UsePostAccountOtpVerify => {
    const { onSuccess, onError } = props;
    const toast = useToast();
    const verifyOtp: MutationFunction<OtpVerifyResponse, OtpVerifyInput> = (data) => {
        return postJSON("/auth/verify/otp", data);
    };

    const mutationResult = useMutation<OtpVerifyResponse, Error, OtpVerifyInput>(verifyOtp, {
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message,
                isClosable: true,
                duration: 5000,
            });
            onError?.(error);
        },
        onSuccess: (res) => {
            if (!res.isValidOtp) {
                toast({
                    status: "error",
                    title: "Alert",
                    description: "Incorrect OTP. Please re-check and try again",
                    isClosable: true,
                    duration: 5000,
                });
                trackEvent({ event: "SIGNUP_VERIFIED", properties: { status: "failure" } });
            } else {
                toast({
                    title: "Verified",
                    status: "success",
                    description: "OTP verified successfully!",
                    isClosable: true,
                    duration: 5000,
                });
                trackEvent({ event: "SIGNUP_VERIFIED", properties: { status: "success" } });
            }
            onSuccess?.(res);
        },
    });
    return mutationResult;
};

type DriverType = {
    [key in TourStep]: Array<Driver.Step>;
};
const fetchDriverJson: QueryFunction<DriverType, TQueryKey> = ({ queryKey }) => {
    const [, { accountId }] = queryKey;
    return fetcher(`/api/accounts/${accountId}/users/drivejs`);
};
export const useGetDriverJson = (props: UseGetApiKeyProps): UseQueryResult<DriverType, Error> => {
    const { accountId } = props;
    return useQuery<DriverType, Error, DriverType, TQueryKey>([QueryKey.FetchDriver, { accountId }], fetchDriverJson, {
        enabled: Boolean(accountId),
    });
};

interface Invite {
    inviteCode: string;
    inviteName: string;
    inviteEmail: string;
    status: string;
    expiresOn: string;
    isInvitingPartnerUser?: boolean;
}

interface UseInviteProps {
    inviteId: string | null;
}

const fetchInviteDetails: QueryFunction<Invite, TQueryKey> = ({ queryKey }) => {
    const [, { inviteId }] = queryKey;
    return fetcher(`/auth/users/invites/${inviteId}`);
};

export const useGetInviteDetails = (props: UseInviteProps): UseQueryResult<Invite, Error> => {
    const { inviteId } = props;
    const toast = useToast({ duration: 2000, isClosable: true });
    return useQuery<Invite, Error, Invite, TQueryKey>([QueryKey.InviteDetailFetch, { inviteId }], fetchInviteDetails, {
        enabled: Boolean(inviteId),
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
    });
};
type UsePostAccountResendOtp = UseMutationResult<void, Error, { inviteEmail: string }>;

interface usePostAccountResendOtpProps {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
}

export const usePostAccountResendOtp = (props: usePostAccountResendOtpProps): UsePostAccountResendOtp => {
    const { onSuccess, onError } = props;
    const toast = useToast();
    const verifyOtp: MutationFunction<void, { inviteEmail: string }> = (data) => {
        return postJSON("/auth/resent/otp", data);
    };

    const mutationResult = useMutation<void, Error, { inviteEmail: string }>(verifyOtp, {
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message,
            });
            onError?.(error);
        },
        onSuccess: () => {
            toast({
                title: "Resent OTP",
                status: "success",
                description: "OTP resent successfully!",
            });
            onSuccess?.();
        },
    });

    return mutationResult;
};

export const WEBHOOK_CONTACT_EVENT_TYPE = ["Contact.Created", "Contact.Updated", "Contact.Deleted"] as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type WebhookContactEventType = (typeof WEBHOOK_CONTACT_EVENT_TYPE)[number];

export const WEBHOOK_TAG_EVENT_TYPE = ["Contact.Tag.Mapped", "Contact.Tag.Unmapped"] as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type WebhookTagEventType = (typeof WEBHOOK_TAG_EVENT_TYPE)[number];

export const WEBHOOK_CONVERSATION_EVENT_TYPE = [
    "Conversation.Create",
    "Conversation.Update",
    "Conversation.Resolve",
    "Conversation.Followup",
] as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type WebhookConversationEventType = (typeof WEBHOOK_CONVERSATION_EVENT_TYPE)[number];

export const WEBHOOK_MESSAGE_EVENT_TYPE = ["Message.Received", "Message.Flow.Received"] as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type WebhookMessageEventType = (typeof WEBHOOK_MESSAGE_EVENT_TYPE)[number];

export const WEBHOOK_MESSAGE_STATUS_EVENT_TYPE = [
    "Message.WA.Status.Received",
    "Message.WA.Payment.Status.Received",
] as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type WebhookMessageStatusEventType = (typeof WEBHOOK_MESSAGE_STATUS_EVENT_TYPE)[number];

export const WEBHOOK_MESSAGE_INTERACTION_EVENT_TYPE = ["Message.WA.Interaction.Received"] as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type WebhookMessageInteractionEventType = (typeof WEBHOOK_MESSAGE_INTERACTION_EVENT_TYPE)[number];

export const WEBHOOK_EVENT_TYPE = [
    ...WEBHOOK_CONVERSATION_EVENT_TYPE,
    ...WEBHOOK_TAG_EVENT_TYPE,
    ...WEBHOOK_CONTACT_EVENT_TYPE,
    ...WEBHOOK_MESSAGE_EVENT_TYPE,
    ...WEBHOOK_MESSAGE_STATUS_EVENT_TYPE,
    ...WEBHOOK_MESSAGE_INTERACTION_EVENT_TYPE,
] as const;
export type WebhookEventType = (typeof WEBHOOK_EVENT_TYPE)[number];

export const WebhookLogStatus = ["SUCCESS", "FAILED"] as const;
export type Status = (typeof WebhookLogStatus)[number];

export interface WebhookLog extends Pick<Timestamp, "createdAt"> {
    id: string;
    webhookId: string;
    accountId: string;
    event: WebhookEventType;
    payload: string;
    status: Status;
}

interface ListWebhookLogs {
    data: WebhookLog[];
    count: number;
}

interface UseGetWebhookLogProps {
    accountId: string;
    page: number;
    limit?: number;
}
const fetchWebhookLogs: QueryFunction<ListWebhookLogs, TQueryKey> = ({ queryKey }) => {
    const [, { accountId, page, limit }] = queryKey;
    const queryParams = mapQueryParams({ page, limit: limit ?? PAGE_SIZE });

    return fetcher(`/api/accounts/${accountId}/webhookLogs?${queryParams}`);
};
export const useGetWebhookLogs = (props: UseGetWebhookLogProps): UseQueryResult<ListWebhookLogs, Error> => {
    const { accountId, page, limit } = props;

    const toast = useToast();

    return useQuery<ListWebhookLogs, Error, ListWebhookLogs, TQueryKey>(
        [QueryKey.WebhookLogsList, { accountId, page, limit }],
        fetchWebhookLogs,
        {
            enabled: Boolean(accountId),
            refetchInterval: 120000,
            cacheTime: 1000 * 60 * 10,
            staleTime: 1000 * 60 * 10,
            keepPreviousData: true,
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
        }
    );
};
interface useUpgradeSandboxProps {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
}

export const useUpgradeSandbox = (props: useUpgradeSandboxProps): UseMutationResult<unknown, Error, void, unknown> => {
    const { onSuccess, onError } = props;

    const toast = useToast();
    const accountId = useAccountId();

    const upgradeSandBox = () => {
        return patchJSON(`/api/accounts/${accountId}/channels/sandbox/extend`);
    };
    const mutation = useMutation(upgradeSandBox, {
        onSuccess: () => {
            toast({
                title: "Extended",
                status: "success",
                description: "Your demo account got extended for 7 days",
            });
            onSuccess?.();
        },
        onError: (err: Error) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error occurred while extending sandbox",
            });
            onError?.(err);
        },
    });

    return mutation;
};

interface PatchAccountBasicDetailProps {
    accountId: string;
    onSuccess?: (data: Account) => void;
    onError?: (error: Error) => void;
}

export type AccountBasicDetailsInput = Partial<
    Pick<Account, "name" | "website" | "country" | "state" | "industry" | "additionalInfo">
> & {
    haveUsedWABusinessAPIBefore?: boolean;
    previousWABusinessProvider?: string;
};

export const usePatchAccountBasicDetail = (
    props: PatchAccountBasicDetailProps
): UseMutationResult<Account, Error, AccountBasicDetailsInput> => {
    const { accountId, onSuccess, onError } = props;
    const toast = useToast();

    const updateAccountBasicDetails = (data: AccountBasicDetailsInput) => {
        return patchJSON<Account>(`api/accounts/${accountId}/basicInfo`, data);
    };

    const mutation = useMutation<Account, Error, AccountBasicDetailsInput>(updateAccountBasicDetails, {
        onSuccess: (data) => {
            onSuccess?.(data);
        },
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err?.message ?? "Something went wrong while updating account info",
            });
            onError?.(err);
        },
    });

    return mutation;
};

interface UseUpdateAcceptedOnboardingStateProps extends UseMutationOptions<Account, Error, AcceptedOnboardingState> {
    accountId: string;
}

export type AcceptedOnboardingState = Extract<OnboardingState, "sandbox" | "trial">;

export const useUpdateAcceptedOnboardingState = (
    props: UseUpdateAcceptedOnboardingStateProps
): UseMutationResult<Account, Error, AcceptedOnboardingState> => {
    const { accountId, ...options } = props;
    const toast = useToast();

    const updateAccountBasicDetails: MutationFunction<Account, AcceptedOnboardingState> = (
        data: AcceptedOnboardingState
    ) => {
        return patchJSON<unknown, Account>(`/api/account/${accountId}`, {
            onboarding: { [data === "trial" ? "trialDetails" : "sandboxDetails"]: { acceptedOnboardingState: data } },
        });
    };

    const mutation = useMutation<Account, Error, AcceptedOnboardingState>(updateAccountBasicDetails, {
        onSuccess: options.onSuccess,
        onError: (err, ...errArgs) => {
            toast({
                title: "Error",
                status: "error",
                description: err?.message ?? "Something went wrong while updating account info",
            });
            options.onError?.(err, ...errArgs);
        },
    });

    return mutation;
};
interface AccountOnboarding extends UseQueryOptions<IAccountOnboarding, Error> {
    accountId: string;
}

export const useGetAccountOnboarding = (props: AccountOnboarding): UseQueryResult<IAccountOnboarding, Error> => {
    const { accountId, ...options } = props;
    const fetchAccountOnboardingData = () => {
        return fetcher(`/api/accounts/${accountId}/accountOnboarding`);
    };
    const toast = useToast({ duration: 2000, isClosable: true });
    return useQuery<IAccountOnboarding, Error>(
        [QueryKey.AccountOnboarding, { accountId }],
        fetchAccountOnboardingData,
        {
            ...options,
            onError: (error) => {
                toast({
                    title: "Error",
                    status: "error",
                    description: error.message,
                });
            },
        }
    );
};

interface UpdateAccountOnBoardingProps {
    accountId: string;
    onSettled?: () => void;
}

type AccountOnBoardingInput = UseMutationResult<IAccountOnboarding, Error, AccountOnboardingComplete>;

export const useUpdateAccountOnBoarding = (props: UpdateAccountOnBoardingProps): AccountOnBoardingInput => {
    const { accountId, onSettled } = props;
    const queryClient = useQueryClient();
    const updateAccountOnBoarding: MutationFunction<IAccountOnboarding, AccountOnboardingComplete> = (data) => {
        return postJSON(`api/accounts/${accountId}/accountOnboarding`, data);
    };
    const queryKey = [QueryKey.AccountOnboarding, { accountId }];
    const mutation = useMutation<IAccountOnboarding, Error, AccountOnboardingComplete>(updateAccountOnBoarding, {
        onSuccess: (data) => {
            queryClient.setQueryData(queryKey, data);
        },
        onSettled: () => {
            onSettled?.();
        },
    });

    return mutation;
};

///accounts/:accountId/subscriptions/extend-trial

interface UpdateExtendTrialProps extends UseMutationOptions<Account, Error, void> {
    accountId: string;
    onCompletion?: () => void;
}

type UseUpdateExtendTrialResult = UseMutationResult<Account, Error, void>;

export const useUpdateExtendTrial = (props: UpdateExtendTrialProps): UseUpdateExtendTrialResult => {
    const { accountId, onCompletion, ...options } = props;
    const toast = useToast();

    const postExtendTrial: MutationFunction<Account, void> = () => {
        return postJSON(`/api/accounts/${accountId}/subscriptions/extend-trial`, {});
    };
    const response = useMutation<Account, Error, void>(postExtendTrial, {
        onSuccess: () => {
            toast({
                title: "Success",
                status: "success",
                description: "Your Trial Extended",
            });
            onCompletion?.();
        },
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message ?? "Failed to Extend",
            });
        },
        ...options,
    });
    return response;
};

interface UseGetInstantMeetUrlProps {
    accountId: string;
    businessName: string;
    name: string;
    email: string;
    phone: string;
    onSuccess?: (data: UseGetInstantMeetUrlResult) => void;
}
interface UseGetInstantMeetUrlResult {
    meetUrl: string;
}
export const useGetInstantMeetUrl = (
    props: UseGetInstantMeetUrlProps
): UseMutationResult<UseGetInstantMeetUrlResult, Error, void> => {
    const { onSuccess, ...reqBody } = props;

    const toast = useToast();

    const fetchInstantMeetUrl: MutationFunction<UseGetInstantMeetUrlResult, void> = async () => {
        const hostUrl = HOME_APP_URL_ENV ?? "https://gallabox.com";
        const res = await axios.post(`${hostUrl}/api/v1/slack-onboarding-assistance`, reqBody);
        return res.data as UseGetInstantMeetUrlResult;
    };

    return useMutation<UseGetInstantMeetUrlResult, Error, void>(fetchInstantMeetUrl, {
        onSuccess: (data) => {
            onSuccess?.(data);
        },
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message,
            });
        },
    });
};

//IP-CONFIG

interface IpConfigListProps {
    accountId: string;
}

interface UseIpConfigListProps extends IpConfigListProps, UseQueryOptions<IPConfig, Error, IPConfig, TQueryKey> {}
type UseIpConfigListResult = UseQueryResult<IPConfig, Error>;

export const useIpConfigList = (props: UseIpConfigListProps): UseIpConfigListResult => {
    const { accountId, ...options } = props;
    const toast = useToast();
    const fetchMyIP: QueryFunction<IPConfig, TQueryKey> = ({ queryKey }) => {
        const [, { accountId }] = queryKey;
        return fetcher(`/api/accounts/${accountId}/ip-config`);
    };
    const query = useQuery<IPConfig, Error, IPConfig, TQueryKey>([QueryKey.IpConfigList, { accountId }], fetchMyIP, {
        ...options,

        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message ?? "Error while fetching User IP",
            });
        },
    });
    return query;
};

interface MyIPProps {
    accountId: string;
}

interface MyIP {
    ip: string;
}

interface UseMyIPProps extends MyIPProps, UseQueryOptions<MyIP, Error, MyIP, TQueryKey> {}
type UseMyIPResult = UseQueryResult<MyIP, Error>;

export const useMyIP = (props: UseMyIPProps): UseMyIPResult => {
    const { accountId, ...options } = props;
    const toast = useToast();

    const fetchMyIP: QueryFunction<MyIP, TQueryKey> = ({ queryKey }) => {
        const [, { accountId }] = queryKey;
        return fetcher(`/api/accounts/${accountId}/my-ip`);
    };
    const query = useQuery<MyIP, Error, MyIP, TQueryKey>([QueryKey.MyIP, { accountId }], fetchMyIP, {
        ...options,
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message ?? "Error while fetching User IP",
            });
        },
    });
    return query;
};

interface UseCreateIPConfigProps extends UseMutationOptions<IPConfig, Error, CreatableIP> {
    accountId: string;
}

type UseCreateIPConfigResult = UseMutationResult<IPConfig, Error, CreatableIP>;

export const useCreateIPConfig = (props: UseCreateIPConfigProps): UseCreateIPConfigResult => {
    const { accountId, onSuccess, onError, ...options } = props;
    const toast = useToast();
    const queryClient = useQueryClient();

    const createIPConfig: MutationFunction<IPConfig, CreatableIP> = (newIpConfig) => {
        return postJSON(`/api/accounts/${accountId}/ip-config`, newIpConfig);
    };
    const mutation = useMutation<IPConfig, Error, CreatableIP>(createIPConfig, {
        onSuccess: (...args) => {
            toast({
                title: "Success",
                status: "success",
                description: "IP address added",
            });
            queryClient.invalidateQueries([QueryKey.IpConfigList, { accountId }]);

            onSuccess?.(...args);
        },
        onError: (...args) => {
            const [error] = args;
            toast({
                title: "Error",
                status: "error",
                description: error.message ?? "Something went wrong on adding ip address",
            });
            onError?.(...args);
        },
        ...options,
    });

    return mutation;
};

interface UseUpdateIPConfigProps extends UseMutationOptions<IPConfig, Error, IP> {
    accountId: string;
}

type UseUpdateIPConfigResult = UseMutationResult<IPConfig, Error, IP>;

export const useUpdateIPConfig = (props: UseUpdateIPConfigProps): UseUpdateIPConfigResult => {
    const { accountId, onSuccess, onError, ...options } = props;
    const toast = useToast();

    const updateIPConfig: MutationFunction<IPConfig, IP> = (updateIpConfigData) => {
        return patchJSON(`/api/accounts/${accountId}/ip-config/${updateIpConfigData.id}`, updateIpConfigData);
    };
    const mutation = useMutation<IPConfig, Error, IP>(updateIPConfig, {
        onSuccess: (...args) => {
            toast({
                title: "Updated",
                status: "success",
                description: "IP address updated",
            });
            onSuccess?.(...args);
        },
        onError: (...args) => {
            const [error] = args;
            toast({
                title: "Error",
                status: "error",
                description: error.message ?? "Something went wrong on while updating ip address",
            });
            onError?.(...args);
        },
        ...options,
    });

    return mutation;
};

interface UseDeleteIPConfigProps extends UseMutationOptions<IPConfig, Error, string> {
    accountId: string;
}

type UseDeleteIPConfigResult = UseMutationResult<IPConfig, Error, string>;

export const useDeleteIPConfig = (props: UseDeleteIPConfigProps): UseDeleteIPConfigResult => {
    const { accountId, onSuccess, onError, ...options } = props;
    const toast = useToast();
    const queryClient = useQueryClient();

    const deleteIPConfig: MutationFunction<IPConfig, string> = (ipConfigId) => {
        return deleteJSON(`/api/accounts/${accountId}/ip-config/${ipConfigId}`);
    };

    const mutation = useMutation<IPConfig, Error, string>(deleteIPConfig, {
        onSuccess: (...args) => {
            toast({
                title: "Deleted",
                status: "success",
                description: "IP address deleted",
            });
            onSuccess?.(...args);
            queryClient.invalidateQueries([QueryKey.IpConfigList, { accountId }]);
        },
        onError: (...args) => {
            const [error] = args;
            toast({
                title: "Error",
                status: "error",
                description: error.message ?? "Something went wrong on while deleting ip address",
            });
            onError?.(...args);
        },
        ...options,
    });

    return mutation;
};
export const useGetAccountUsersCount = () => {
    const accountId = useAccountId();
    const query = useQuery<{ count: number }, Error>([QueryKey.GetAccountUsersCount], () => {
        return fetcher(`/api/accounts/${accountId}/users/count`);
    });
    return query;
};

interface UseWebinarDetailsBaseProps {
    accountId: string;
}
type UseWebinarDetailsProps = UseWebinarDetailsBaseProps & UseQueryOptions<WebinarTableType[], Error>;
type UseWebinarDetailsResult = UseQueryResult<WebinarTableType[], Error>;
export const useWebinarDetails = (props: UseWebinarDetailsProps): UseWebinarDetailsResult => {
    const { accountId, onError, ...options } = props;
    const toast = useToast();
    const query = useQuery<WebinarTableType[], Error>([QueryKey.WebinarDetails, { accountId }], getWebinarBanner, {
        ...options,
        onError: (...args) => {
            const [error] = args;
            toast({
                title: "Error",
                status: "error",
                description: error.message ?? "Something went wrong while fetching webinar data",
            });
            onError?.(...args);
        },
    });
    return query;
};

export interface OnboardingStepData {
    step: OnboardingJourneySteps;
    date?: Date;
    data?: Record<string, string | number>;
}

interface UseUpdateOnboardingStepsProps extends UseMutationOptions<null, Error, OnboardingStepData> {
    accountId: string;
}

export const useUpdateOnboardingSteps = (
    props: UseUpdateOnboardingStepsProps
): UseMutationResult<null, Error, OnboardingStepData> => {
    const { accountId, ...options } = props;

    const updateOnboardingSteps: MutationFunction<null, OnboardingStepData> = (data: OnboardingStepData) => {
        return postJSON(`/api/accounts/${accountId}/onboarding-step`, data);
    };

    const mutation = useMutation<null, Error, OnboardingStepData>(updateOnboardingSteps, {
        onSuccess: options.onSuccess,
        onError: (err, ...errArgs) => {
            options.onError?.(err, ...errArgs);
        },
    });

    return mutation;
};
