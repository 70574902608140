/* eslint-disable react/no-unescaped-entities */
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    HStack,
    Image,
    Link,
    Radio,
    Stack,
    Text,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useGetUserPref, useUpdateNotificationPref } from "app/fetchHooks/user";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { YupSchema } from "app/types";
import {
    notificationConversationPrefs,
    NotificationCustomPreference,
    notificationMessagePrefs,
    NotificationPreference,
    notificationPreferenceModes,
    NotificationPrefs,
} from "app/types/notifications";
import { Checkbox, RadioGroup } from "app/utils/formUtils";
import { useAccountId } from "app/utils/react-helpers";
import AppStoreImg from "assets/onboarding/appstore-icon.svg";
import GooglePlayStoreImg from "assets/onboarding/googleplaystore-icon.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

interface NotificationPreferenceYupSchema {
    mode: NotificationPreference["mode"];
    custom?: NotificationCustomPreference["custom"];
    sendToMobileOnlyIfInActive?: string;
}

const notificationSchema = yup.object().shape<YupSchema<NotificationPreferenceYupSchema>>({
    mode: yup
        .string()
        .oneOf([...notificationPreferenceModes])
        .required(msg`Notification mode is required`),
    custom: yup.object().when("mode", {
        is: "custom",
        then: (schema) =>
            schema.shape({
                message: yup
                    .object()
                    .shape({
                        unassignedMessages: yup.boolean().nullable(),
                        mineMessages: yup.boolean().nullable(),
                        mentionedMessages: yup.boolean().nullable(),
                        failedMessages: yup.boolean().nullable(),
                    })
                    .optional(),
                conversation: yup
                    .object()
                    .shape({
                        followup: yup.boolean().nullable(),
                        movesToUnassigned: yup.boolean().nullable(),
                        assignedToMe: yup.boolean().nullable(),
                    })
                    .optional(),
            }),
        otherwise: yup.object().optional(),
    }),
    sendToMobileOnlyIfInActive: yup.string().oneOf(["true", "false"]).optional(),
});

const NotificationSettings: React.FC = () => {
    const { _ } = useLingui();
    const accountId = useAccountId();
    const toast = useToast();

    const notificationPrefLabelMap: Record<NotificationPrefs, string> = useMemo(
        () => ({
            movesToUnassigned: _(msg`A conversation is unassigned.`),
            assignedToMe: _(msg`A conversation is assigned to me.`),
            followup: _(msg`Get notified about upcoming follow-up reminder.`),

            unassignedMessages: _(msg`A new message in an unassigned conversation.`),
            mineMessages: _(msg`A new message in a conversation assigned to me.`),
            mentionedMessages: _(msg`I’m mentioned in a conversation.`),
            failedMessages: _(msg`A message I tried to send in Inbox has failed.`),
        }),
        [_]
    );

    const { mutateAsync, isLoading: isSubmitting } = useUpdateNotificationPref({
        accountId,
        onSuccess: () => {
            toast({
                status: "success",
                title: "Notification preferences updated",
            });
        },
    });

    const { data: userPref, isLoading: isLoadingUserPref } = useGetUserPref({ accountId });
    const [showCustomNotificationError, setCustomNotificationError] = useState(false);

    const sanitize = (pref: NotificationPreference | undefined): NotificationPreferenceYupSchema | undefined => {
        if (!pref) return undefined;

        return {
            ...pref,
            sendToMobileOnlyIfInActive:
                (pref.mode === "all" || pref.mode === "custom") && pref.sendToMobileOnlyIfInActive ? "true" : "false",
        };
    };

    const deSanitize = (pref: NotificationPreferenceYupSchema): NotificationPreference | undefined => {
        if (pref.mode === "all") {
            return {
                mode: "all",
                sendToMobileOnlyIfInActive: pref.sendToMobileOnlyIfInActive === "true",
            };
        }

        if (pref.mode === "custom" && pref.custom) {
            return {
                mode: "custom",
                custom: pref.custom,
                sendToMobileOnlyIfInActive: pref.sendToMobileOnlyIfInActive === "true",
            };
        }

        return undefined;
    };

    useEffect(() => {
        if (isLoadingUserPref) return;

        reset(
            sanitize(userPref?.notificationPref) ?? {
                mode: "all",
            }
        );
    }, [userPref?.notificationPref, isLoadingUserPref]);

    const { control, watch, handleSubmit, reset, errors, formState } = useForm<NotificationPreferenceYupSchema>({
        resolver: yupResolver(notificationSchema),
        mode: "onChange",
    });

    const onSubmit = useCallback(
        async (data: NotificationPreferenceYupSchema) => {
            const payload = deSanitize(data);

            if (payload) {
                trackEvent({
                    event: "Notification Settings",
                    properties: { module: "Manage Notifications", notification: payload.mode },
                });
                await mutateAsync(payload);
            }
        },
        [mutateAsync]
    );

    const data = watch();

    useEffect(() => {
        if (data.mode === "custom") {
            if (!data.custom) {
                reset({
                    ...data,
                    mode: "custom",
                    custom: {
                        message: {
                            unassignedMessages: false,
                            mineMessages: false,
                            mentionedMessages: false,
                            failedMessages: false,
                        },
                        conversation: {
                            followup: false,
                            movesToUnassigned: false,
                            assignedToMe: false,
                        },
                    },
                });

                setCustomNotificationError(true);
            } else {
                const shouldShowCustomNotificationError = !(
                    Object.values(data.custom.message || {}).some((v) => v) ||
                    Object.values(data.custom.conversation || {}).some((v) => v)
                );
                setCustomNotificationError(shouldShowCustomNotificationError);
            }
        } else {
            setCustomNotificationError(false);
        }
    }, [data.mode, reset, data.custom]);

    return (
        <Stack bg="white" width="full" p={6} alignItems={"center"}>
            <Box maxWidth={"900"} as="form" minH={"100vh"} onSubmit={handleSubmit(onSubmit)}>
                <HStack justifyContent={"space-between"} width="full">
                    <VStack alignItems={"flex-start"} spacing={2}>
                        <Text fontSize={"lg"} fontWeight={"semibold"} color={"gray.800"}>
                            <Trans>Notification Settings</Trans>
                        </Text>

                        <Text fontSize={"sm"} fontWeight={"normal"} color={"gray.600"} maxWidth={"90%"}>
                            <Trans>
                                Changes you make here will apply to the user on all devices. No matter where you log in,
                                your notification preference will stay the same.
                            </Trans>
                        </Text>
                    </VStack>

                    {formState.isSubmitting ? (
                        <HStack w={"128px"} justifyContent={"flex-end"}>
                            <CircularProgress isIndeterminate size={5} color="brand.500" />
                        </HStack>
                    ) : (
                        <Button
                            size="medium"
                            variant="primary"
                            isDisabled={
                                !formState.isDirty ||
                                showCustomNotificationError ||
                                formState.isSubmitting ||
                                formState.isSubmitSuccessful
                            }
                            type="submit"
                        >
                            Save Changes
                        </Button>
                    )}
                </HStack>

                {isLoadingUserPref ? (
                    <Box h={"32px"} mt={4}>
                        <CircularProgress isIndeterminate size={5} color="brand.500" />
                    </Box>
                ) : (
                    <Box width={"full"} mt={4}>
                        <FormControl>
                            <RadioGroup control={control} name="mode">
                                <VStack spacing={4} alignItems={"flex-start"}>
                                    <Radio value="all" spacing={2}>
                                        <Trans>Receive all notifications</Trans>
                                    </Radio>
                                    <VStack w={"full"} spacing={4} alignItems={"flex-start"}>
                                        <Radio value="custom" spacing={2}>
                                            <Trans>Set custom notifications</Trans>
                                        </Radio>

                                        {data.mode === "custom" && (
                                            <VStack align="start" spacing={4} w="full" pl={6}>
                                                {showCustomNotificationError && (
                                                    <Text fontSize="sm" color="red.500" mt={-3}>
                                                        <Trans>Atleast one preference should be enabled</Trans>
                                                    </Text>
                                                )}

                                                {/* Conversation Preferences */}
                                                <Text fontSize="sm" color="gray.400">
                                                    <Trans>Conversation</Trans>
                                                </Text>
                                                {notificationConversationPrefs.map((pref) => (
                                                    <HStack key={pref} width={"full"}>
                                                        <Text fontSize={"sm"} color={"gray.800"} w="80%">
                                                            {notificationPrefLabelMap[pref]}
                                                        </Text>
                                                        <Checkbox
                                                            hidelabel
                                                            control={control}
                                                            isDisabled={isSubmitting}
                                                            name={`custom.conversation.${pref}`}
                                                            defaultValue={"false"}
                                                            size="md"
                                                            fieldContainerProps={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                            }}
                                                        />
                                                    </HStack>
                                                ))}

                                                <Divider />

                                                {/* Message Preferences */}
                                                <Text fontSize="sm" color="gray.400">
                                                    <Trans>Messages</Trans>
                                                </Text>
                                                {notificationMessagePrefs.map((pref) => (
                                                    <HStack key={pref} width={"full"}>
                                                        <Text fontSize={"sm"} color={"gray.800"} w="80%">
                                                            {notificationPrefLabelMap[pref]}
                                                        </Text>
                                                        <Checkbox
                                                            hidelabel
                                                            control={control}
                                                            isDisabled={isSubmitting}
                                                            name={`custom.message.${pref}`}
                                                            defaultValue={"false"}
                                                            fieldContainerProps={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                            }}
                                                        />
                                                    </HStack>
                                                ))}
                                            </VStack>
                                        )}
                                    </VStack>
                                </VStack>
                            </RadioGroup>

                            <RadioGroup
                                control={control}
                                name="sendToMobileOnlyIfInActive"
                                defaultValue={"true"}
                                mt={8}
                            >
                                <Text fontSize="md" color="gray.800">
                                    <Trans>When should we notify you on mobile?</Trans>
                                </Text>
                                <VStack spacing={3} alignItems={"flex-start"} mt={3}>
                                    <Radio value={"false"} size="md">
                                        <Trans>Always</Trans>
                                    </Radio>
                                    <Radio value={"true"} size="md">
                                        <Trans>Only when I'm not active on web</Trans>
                                    </Radio>
                                </VStack>
                            </RadioGroup>
                        </FormControl>
                    </Box>
                )}

                <HStack
                    mt={4}
                    p={4}
                    borderWidth={1}
                    borderRadius={8}
                    borderColor="gray.200"
                    width="full"
                    justifyContent={"space-between"}
                >
                    <VStack alignItems={"flex-start"} spacing={1}>
                        <Text fontSize="md" fontWeight="bold" color={"gray.600"}>
                            Gallabox Mobile App
                        </Text>
                        <Text fontSize="sm" color="gray.600">
                            <Trans>
                                Stay connected and manage all your conversations on the go with our mobile app.
                            </Trans>
                        </Text>
                    </VStack>
                    <HStack spacing={4}>
                        <Link href="https://apps.apple.com/in/app/gallabox/id1542957177" isExternal h="35px" maxW="50%">
                            <Image src={AppStoreImg} />
                        </Link>
                        <Link
                            href="https://play.google.com/store/apps/details?id=com.mangoleap.gallabox&hl=en_IN"
                            isExternal
                            h="35px"
                            maxW="50%"
                        >
                            <Image src={GooglePlayStoreImg} />
                        </Link>
                    </HStack>
                </HStack>
            </Box>
        </Stack>
    );
};

export default NotificationSettings;
