import { Link, useToast } from "@chakra-ui/react";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { QueryKey } from "app/types";
import { useAccountId } from "app/utils/react-helpers";
import { SocketEvents, useSocket } from "app/utils/use-socketio";
import React from "react";
import { useQueryClient } from "react-query";
import { Link as RouterLink } from "react-router-dom";

interface BotFlowImportSocketResult {
    status: "success" | "error";
    flow?: {
        id: string;
        botId: string;
        metadata: Record<string, any>;
    };
    message?: string;
}

const useListenBotFlowImport = () => {
    const { _ } = useLingui();

    const toast = useToast();
    const accountId = useAccountId();
    const queryClient = useQueryClient();

    useSocket(SocketEvents.BotFlowImport, (data: BotFlowImportSocketResult) => {
        const { status, message } = data;

        if (status === "success") {
            const botFlowQuery = [QueryKey.BotFlowBuilderList, { accountId, botId: data.flow?.botId }];
            queryClient.invalidateQueries(botFlowQuery);
            setTimeout(() => {
                toast({
                    title: (
                        <Link as={RouterLink} to={`/botv2/${data.flow?.botId}/flows/${data.flow?.id}`}>
                            <Trans>Bot Flow Import Completed</Trans>
                        </Link>
                    ),
                    status: "success",
                    description: _(msg`Your bot flow has been successfully imported. You can now review and use it.`),
                    position: "bottom-right",
                    isClosable: true,
                    duration: null,
                });
            }, 2000);
        }

        if (status === "error") {
            toast({ title: message ?? _(msg`Error while importing the bot flow`), status: "error" });
        }
    });
};

export default useListenBotFlowImport;
