import { Box, Divider, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { ExtractArrayType } from "app/types/type-utils";
import { callFnsInSequence } from "app/utils/common";
import React from "react";
import { SubNavigationGroup, SubNavigationItemType } from "../types";
import { getNavigationItemState, getSanitizedSubNavigationButtonProps } from "../utils";
import NavigationBadge from "./NavigationBadge";
import NavigationButton from "./NavigationButton";

interface SubNavgiationItemProps {
    navigationItem: ExtractArrayType<SubNavigationGroup["children"]>;
}

const SubNavgiationItem: React.FC<SubNavgiationItemProps> = (props) => {
    const { navigationItem } = props;

    const onClick = React.useCallback(() => {
        if (!navigationItem.eventName) return;
        trackEvent({
            event: navigationItem.eventName,
        });
    }, [navigationItem.eventName]);

    if (navigationItem.type === "divider") return <Divider alignSelf="center" borderColor="gray.300" />;

    const navigationItemState = getNavigationItemState({
        navigationItem,
    });

    const isActiveItem = navigationItemState.state === "active";

    return (
        <NavigationButton
            {...getSanitizedSubNavigationButtonProps(navigationItem, { isActive: isActiveItem })}
            fontSize="xs"
            size="medium"
            justifyContent="start"
            colorScheme="gray"
            fontWeight="normal"
            flexShrink={0}
            isNavigationExpanded={true}
            w="full"
            onClick={callFnsInSequence(onClick, navigationItem?.onClick)}
            titleComp={
                <HStack w="full">
                    <Text>{navigationItem.title}</Text>
                    <Spacer />
                    <NavigationBadge isExpanded={true} navigationItem={navigationItem} />
                </HStack>
            }
        />
    );
};

interface SubNavigationGroupItemProps {
    navigationGroup: SubNavigationGroup;
}
const SubNavigationGroupItem: React.FC<SubNavigationGroupItemProps> = (props) => {
    const { navigationGroup } = props;

    return (
        <VStack w="full" align="start" spacing={0} px={2} py={2}>
            {navigationGroup.title && (
                <Text color="gray.400" textTransform="uppercase" fontSize="xs" fontWeight="medium" pb={1}>
                    {navigationGroup.title}
                </Text>
            )}
            {navigationGroup.children.map((child, index) => {
                return <SubNavgiationItem navigationItem={child} key={index} />;
            })}
        </VStack>
    );
};

interface SubNavigationProps {
    subNav: SubNavigationItemType | null;
}
const SubNavigation: React.FC<SubNavigationProps> = (props) => {
    const { subNav: navItem } = props;

    if (!navItem) return null;

    return (
        <VStack bg="gray.50" w="208px" align="start" spacing={0} borderRight="1px solid" borderColor="gray.200">
            <Box px={4} pt={4} pb={2} w="full" borderBottom="1px solid" borderColor="gray.200">
                <Text color="gray.800" fontWeight="bold">
                    {navItem.title}
                </Text>
            </Box>
            <VStack
                h="calc(100% - 64px)"
                w="full"
                spacing={0}
                overflow="auto"
                css={{
                    scrollbarWidth: "none",
                }}
            >
                {navItem.subNavigation.children.map((group, index) => {
                    return <SubNavigationGroupItem navigationGroup={group} key={index} />;
                })}
            </VStack>
        </VStack>
    );
};

export default SubNavigation;
