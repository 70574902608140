import React, { useState } from "react";
import Select, { components, Props as ReactSelectProps } from "react-select";
import { CustomOption } from "./CustomOption";
import { getDefaultValue } from "./PopUpNavigator";
import { usePopNavigationSelectStyle } from "./styles";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { NavigationItem, RoutableNavigationItem } from "../types";
import { windowOpenNewTab } from "app/utils/common";
import { useHistory } from "react-router-dom";

interface IPopUpNavigationSelect extends ReactSelectProps<NavigationItem> {
    onClose: () => void;
    placeholderValue?: string;
}

export const PopUpNavigationSelect: React.FC<IPopUpNavigationSelect> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onClose, placeholderValue, onChange: oC, ...otherProps } = props;
    const [search, setSearch] = useState("");
    const selectStyles = usePopNavigationSelectStyle();
    const history = useHistory();

    const onChange: ReactSelectProps<NavigationItem>["onChange"] = (item) => {
        if (!item) return;
        trackEvent({
            event: "CTRL Search",
            properties: { search_string: search, module_name: (item as RoutableNavigationItem).title },
        });
        if (item.type === "internal-link") {
            history.push((item as RoutableNavigationItem).to);
        } else if (item.type === "external-link") {
            windowOpenNewTab(item.href);
        } else if (item.type === "click-action") {
            item.onClick?.();
        }
        onClose();
    };

    return (
        <Select<NavigationItem>
            {...otherProps}
            placeholder={placeholderValue ?? getDefaultValue()["placeholderValue"]}
            styles={selectStyles}
            menuIsOpen
            onChange={onChange}
            onInputChange={setSearch}
            getOptionLabel={(option) => {
                const { quickSearchTerms, title } = option;
                if (!quickSearchTerms || quickSearchTerms?.length < 1) return title;

                return title + quickSearchTerms.join(" ");
            }}
            components={{
                Option: CustomOption as typeof components.Option,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
            }}
        />
    );
};
