import { Button, ButtonProps, Image } from "@chakra-ui/react";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { Invite } from "app/types/invite";
import { mapQueryParams } from "app/utils/fetchUtils";
import GoogleIcon from "assets/onboarding/google.svg";
import MicrosoftIcon from "assets/onboarding/Microsoft.svg";
import { SERVER_URL_ENV } from "environment";
import React from "react";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { SocialLoginType } from "app/types/user";

declare global {
    interface Window {
        webkit?: {
            messageHandlers?: Record<string, unknown>;
        };
    }
}

type SocialLoginProps = {
    [key in SocialLoginType]: {
        icon: string;
    };
};

const socialLoginProps: SocialLoginProps = {
    google: {
        icon: GoogleIcon,
    },
    microsoft: {
        icon: MicrosoftIcon,
    },
};

interface SocialSignInBtnProps extends ButtonProps {
    btnText?: string;
    provider?: SocialLoginType;
    page?: "login" | "signup";
    dataCy?: string;
    amplitudeEventName?: string;
    signupParams?: {
        skipWorkEmail?: string | null;
        isSkipWorkEmailByCountry?: boolean;
        accountTier?: Invite["accountTier"];
        accountType?: Invite["type"];
        referralCode?: string;
    };
}

const SocialSignInBtn: React.FC<SocialSignInBtnProps> = (props) => {
    const { _ } = useLingui();

    const {
        btnText = _(msg`Sign in with Google`),
        provider = "google",
        page = "login",
        amplitudeEventName,
        dataCy,
        signupParams,
    } = props;
    const { skipWorkEmail, isSkipWorkEmailByCountry, accountTier, accountType, referralCode } = signupParams || {};

    const queryParams = mapQueryParams({
        type: page,
        version: "v2",
        platform: "web-app",
        // Signup
        referralCode,
        skipWorkEmail,
        isSkipWorkEmailByCountry,
        accountTier,
        accountType,
    });

    const authURL = `${SERVER_URL_ENV}/auth/${provider}?${queryParams}`;

    const isInAppBrowser = React.useMemo(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        let isIab = false;

        if (
            userAgent.includes("fban") ||
            userAgent.includes("fbav") ||
            userAgent.includes("instagram") ||
            userAgent.includes("twitter") ||
            userAgent.includes("linkedin")
        ) {
            isIab = true;
        }

        return isIab;
    }, []);

    const openInSystemBrowser = () => {
        if (amplitudeEventName) trackEvent({ event: amplitudeEventName });

        const userAgent = navigator.userAgent.toLowerCase();
        const isAndroid = userAgent.includes("android");
        const isIOS = /iphone|ipad|ipod/.test(userAgent);

        if (isInAppBrowser) {
            if (isAndroid) {
                window.location.href = `intent://${authURL.replace("https://", "")}#Intent;scheme=https;package=com.android.chrome;end;`;
            } else if (isIOS) {
                window.location.href = `x-safari-${authURL}`;
            }
        } else {
            window.open(authURL, "_self");
        }
    };

    return (
        <Button
            variant="outline"
            fontSize="sm"
            fontWeight={500}
            width="full"
            color="gray.600"
            height="32px"
            onClick={openInSystemBrowser}
            data-cy={dataCy || `sso-${page}-${provider}`}
        >
            <Image src={socialLoginProps[provider].icon} boxSize="4" mr="3" />
            {btnText}
        </Button>
    );
};

export default SocialSignInBtn;
