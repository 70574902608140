import { useToast } from "@chakra-ui/react";
import { WATemplateValues, QueryKey } from "app/types";
import { Integration } from "app/screens/Integration/types/integration";
import { CashFreeConnect, CashFreeEventType } from "app/screens/Integration/types/cashfree";
import { postJSON, putJSON } from "app/utils/fetchUtils";
import { UseMutationOptions, UseMutationResult, MutationFunction, useMutation, useQueryClient } from "react-query";

export interface UseConnectCashFreeIntegrationInput {
    brandName: string;
}

interface UseConnectCashFreeProps
    extends UseMutationOptions<CashFreeConnect, Error, UseConnectCashFreeIntegrationInput> {
    accountId: string;
    onSuccess?: (data: CashFreeConnect) => void;
}

type ConnectCashFreeIntegrationResponse = UseMutationResult<CashFreeConnect, Error, UseConnectCashFreeIntegrationInput>;

export const useConnectCashFreeIntegration = (props: UseConnectCashFreeProps): ConnectCashFreeIntegrationResponse => {
    const { accountId, onSuccess, ...options } = props;
    const toast = useToast();

    const connectCashfree: MutationFunction<CashFreeConnect, UseConnectCashFreeIntegrationInput> = (
        data: UseConnectCashFreeIntegrationInput
    ) => {
        return postJSON(`api/accounts/${accountId}/integrations/cashfree`, data);
    };
    const responseResult = useMutation<CashFreeConnect, Error, UseConnectCashFreeIntegrationInput>(connectCashfree, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message ?? "Error while Connecting Integration",
            });
        },
        onSuccess: (data) => {
            toast({
                title: "Integration  Connected",
                status: "success",
                description: "Integration Connected successfully!",
            });
            onSuccess?.(data);
        },
        ...options,
    });

    return responseResult;
};

//update LSQ configuration

interface UpdateTriggersInput {
    templateId: string;
    templateValues?: WATemplateValues;
    triggerKey: CashFreeEventType;
    isActive: boolean;
}

interface UseUpdateCashFreeTriggers extends UseMutationOptions<Integration, Error, UpdateTriggersInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

type UpdateCashFreeTriggersResponse = UseMutationResult<Integration, Error, UpdateTriggersInput>;

export const useUpdateCashFreeTriggers = (props: UseUpdateCashFreeTriggers): UpdateCashFreeTriggersResponse => {
    const { accountId, integrationId, onSuccess, ...rest } = props;
    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateCashFreeTriggers: MutationFunction<Integration, UpdateTriggersInput> = (data: UpdateTriggersInput) => {
        return putJSON(`/api/accounts/${accountId}/integrations/cashfree/${integrationId}`, data);
    };

    const responseUpdatedTriggers = useMutation<Integration, Error, UpdateTriggersInput>(updateCashFreeTriggers, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            toast({
                title: "Integration Registered successfully",
                status: "success",
                description: "Integration Registered successfully!",
            });
            onSuccess?.();
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
        ...rest,
    });

    return responseUpdatedTriggers;
};
