import { LabelValue, YupSchema } from "app/types";
import { PromptAction } from "app/types/bot";
import { Workflow } from "app/screens/Integration/types/integration";
import { IconType } from "react-icons";
import { LuBellRing, LuCalendarCheck, LuTimer } from "react-icons/lu";
import * as yup from "yup";

export interface CalendlyCreateWorkFlowFormData {
    eventTypeUri: LabelValue<string>;
    duration?: LabelValue<number | undefined>;
    actions?: PromptAction[];
}
export interface Profile {
    name: string;
    owner: string;
    type: string;
}

export interface UseCaseListProps {
    title: string;
    name: string;
    description: string;
    img: IconType;
}
export interface CustomQuestion {
    ["answer_choices"]: any[];
    enabled: boolean;
    ["include_other"]: boolean;
    name: string;
    position: number;
    required: boolean;
    type: string | "phone_number";
}

export interface CalendlyEvent {
    active: boolean;
    ["admin_managed"]: boolean;
    ["booking_method"]: string;
    color: string;
    ["created_at"]: string;
    ["custom_questions"]: CustomQuestion[];
    ["deleted_at"]: any;
    ["description_html"]: any;
    ["description_plain"]: any;
    duration: number;
    ["internal_note"]: any;
    kind: string;
    name: string;
    ["pooling_type"]?: string;
    profile?: Profile;
    ["scheduling_url"]: string;
    secret: boolean;
    slug?: string;
    type: string;
    ["updated_at"]: string;
    uri: string;
}

export interface Pagination {
    count: number;
    ["next_page"]: any;
    ["next_page_token"]: any;
    ["previous_page"]: any;
    ["previous_page_token"]: any;
}
export interface CalendlyEventList {
    collection: CalendlyEvent[];
    pagination: Pagination;
}

export const useCaseList: UseCaseListProps[] = [
    {
        title: "Confirmation",
        name: "Event Confirmation Notifications",
        description: "Keep invitees up-to-date with your events via WhatsApp",
        img: LuCalendarCheck,
    },
    {
        title: "Reminder",
        name: "Event Reminder Notifications",
        description: "Never miss an event — get automated WhatsApp reminders",
        img: LuTimer,
    },
    {
        title: "followup",
        name: "Event Follow-up Notifications",
        description: "Build relationships with a quick thanks and surveys",
        img: LuBellRing,
    },
];
export const calendlyFormData: CalendlyCreateWorkFlowFormData = {
    duration: { label: "Select...", value: undefined },
    eventTypeUri: { label: "Select...", value: "" },
    actions: [],
};

export const deSanitizeCalendlyData = (
    currentWorkFlow: Workflow,
    eventOption: LabelValue[]
): CalendlyCreateWorkFlowFormData => {
    const { workflowActions, calendly } = currentWorkFlow;
    const { eventTypeUri, duration: d = 1 } = calendly ?? {};
    const hour = d / 60;
    const isInteger = Number.isInteger(hour);
    const time = isInteger ? hour : hour * 60;
    const unit = isInteger ? "Hours" : "Minutes";

    const duration: LabelValue<number> = {
        label: `${time} ${unit}`,
        value: d,
    };

    const eventType = eventOption?.find((c) => c.value === eventTypeUri?.[0].value);

    return {
        duration,
        eventTypeUri: eventType as LabelValue<string>,
        actions: workflowActions,
    };
};

export const calendlySchema = (isNotConfirmationUseCase: boolean) => {
    return yup.object().shape<YupSchema<Omit<CalendlyCreateWorkFlowFormData, "actions">>>({
        duration: isNotConfirmationUseCase
            ? yup
                  .object()
                  .shape<YupSchema<LabelValue<number>>>({
                      label: yup.string().trim().required("Value is Required"),
                      value: yup.number().required("Value is Required"),
                  })
                  .required("Value is required")
            : yup.object().optional(),
        eventTypeUri: yup
            .object()
            .shape<YupSchema<LabelValue>>({
                label: yup.string().trim().required("Value is Required"),
                value: yup.string().trim().required("Value is Required"),
            })
            .required("Value is Required"),
    });
};

export const durationOptions: LabelValue<number>[] = [
    {
        label: "15 Minutes",
        value: 15,
    },
    {
        label: "30 minutes",
        value: 30,
    },
    {
        label: "45 minutes",
        value: 45,
    },
    {
        label: "1 hour",
        value: 60,
    },
    {
        label: "2 hours",
        value: 120,
    },
    {
        label: "3 hours",
        value: 180,
    },
    {
        label: "4 hours",
        value: 240,
    },
    {
        label: "5 hours",
        value: 300,
    },
];
