import { useToken } from "@chakra-ui/react";
import { StylesConfig } from "react-select";

export const usePopNavigationSelectStyle = (): StylesConfig<any, false> => {
    const [GRAY_500, GRAY_900, GRAY_100, GRAY_600] = useToken("colors", [
        "gray.500",
        "gray.900",
        "gray.100",
        "gray.600",
    ]);

    const [xl, xs, sm, pointFive] = useToken("fontSizes", ["xl", "xs", "sm", "0.5"]);

    /*** We are overriding the react select styles */
    const selectStyles: StylesConfig<any, false> = {
        container: (base) => ({
            ...base,
            borderRadius: "5px",
            width: "100%",
        }),
        control: (base) => ({
            ...base,
            margin: 1,
            border: 0,
            flex: 1,
            boxShadow: "0px",
            height: "65px",
            fontSize: xl,
            backgroundColor: GRAY_100,
            marginBottom: "10px",
            color: "#ffffff",
        }),
        input: (base) => ({
            ...base,
            color: GRAY_500,
        }),
        menu: () => ({ position: "static", minWidth: 80, marginTop: 0 }),
        menuList: (base) => ({ ...base, maxHeight: "250px", backgroundColor: "#ffffff" }),
        groupHeading: (base) => ({
            ...base,
            fontSize: xs,
            color: GRAY_900,
            fontWeight: "bold",
            padding: "0px",
            marginTop: pointFive,
        }),
        option: (base) => ({
            ...base,
            ":active": {
                backgroundColor: GRAY_100,
            },
            ":hover": {
                backgroundColor: GRAY_100,
                borderRadius: "4px",
            },
            fontSize: sm,
            color: GRAY_600,
            fontWeight: "lighter",
            padding: "0px",
            scrollBehavior: "smooth",
            textDecoration: "none",
            marginRight: pointFive,
        }),
    };

    return selectStyles;
};
