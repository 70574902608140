import { useAccountMasterHook } from "app/hooks/useAccountMasterHook";
import { useAccount, useUser } from "app/utils/react-helpers";
import dayjs from "dayjs";
import { USETIFUL_WORKSPACE_CODE } from "environment";
import React, { useEffect, useRef } from "react";
import { loadUsetifulScript, setUsetifulTags } from "usetiful-sdk";

const isOFC = (countryISO: string) => {
    //India, Saudi Arabia, United Arab Emirates, Brazil
    const featuredCountries = ["IND", "SAU", "ARE", "BRA"];
    return !featuredCountries.includes(countryISO);
};

loadUsetifulScript(USETIFUL_WORKSPACE_CODE, { identifyUser: true });

const GBProductTour = () => {
    const user = useUser();
    const account = useAccount();

    const { isSubscribed } = useAccountMasterHook();

    const initRef = useRef(false);

    const userCreatedDate = dayjs(user.createdAt);
    const accountSubscribedDate = dayjs(account?.onboarding?.trialDetails?.createdAt);
    const currentDate = dayjs();

    const daysSinceUserCreation = currentDate.diff(userCreatedDate, "day");
    const daysSinceSubscriptionStart = currentDate.diff(accountSubscribedDate, "day");

    const userInfo = React.useMemo(() => {
        if (!user || !Object.keys(user).length || !account) return;
        const { country, type, industry, onboardingState } = account;
        return {
            userId: user.id,
            email: user.email,
            firstname: user.name,
            npsScore: user.additionalInfo?.npsScore,
            isOFC: account?.countryIso2 ? isOFC(account.countryIso2?.iso) : false,
            daysSinceUserCreation,
            daysSinceSubscriptionStart,
            country,
            type,
            industry,
            onboardingState,
        };
    }, [account, daysSinceUserCreation, daysSinceSubscriptionStart, user]);

    const disableTour = userInfo?.userId && isSubscribed;

    useEffect(() => {
        if (disableTour && !initRef.current) {
            setUsetifulTags({ ...userInfo });
            initRef.current = true;
        }
    }, [disableTour, userInfo]);

    return null;
};

export default GBProductTour;
