import AppBreadCrumbs from "app/components/AppBreadCrumbs";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const createIntegrationDetailBreadCrumbData = (currentpath:string) => {
    return [
        {
            label: "Integration",
            breadCrumbLinkProps:{
                to: "/integration",
                as: RouterLink,
            },
            breadCrumbItemProps: {
                color: "gray.600"
            },
        },
        {
            label: currentpath,
            breadCrumbLinkProps:{
                href: "#",
                fontWeight: "medium"
            },
            breadCrumbItemProps: {
                isCurrentPage: true
            },
        },
    ]
}

const IntegrationDetailBreadCrumb = ({ currentpath }:{ currentpath:string }) => {
    return <AppBreadCrumbs paths={createIntegrationDetailBreadCrumbData(currentpath)} />
}

export default React.memo(IntegrationDetailBreadCrumb)