import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { checkOS } from "app/utils/common";

export const checkAndTogglePopUpNavigation = (
    e: KeyboardEvent,
    openPopUpNavigator: () => void,
    closePopUpNavigator: () => void,
    popUpNavigatorIsOpen: boolean
): void => {
    let openPopNavigation = false;
    const isEscape = e.key === "Escape" && popUpNavigatorIsOpen;
    const isMac = checkOS().includes("mac");
    if (isMac) {
        openPopNavigation = e?.metaKey && e?.key?.toLowerCase() === "k";
    } else {
        openPopNavigation = e?.ctrlKey && e?.key?.toLowerCase() === "k";
    }

    if (openPopNavigation) {
        e.preventDefault();
        openPopUpNavigator();
        trackEvent({ event: "LNAV_CTRL+K" });
    }

    if (isEscape) {
        e.preventDefault();
        closePopUpNavigator();
    }
};
