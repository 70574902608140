import React from "react";
import { components, OptionProps } from "react-select";
import NavigationButton, { NavigationButtonProps } from "../components/NavigationButton";
import { NavigationItem, RoutableNavigationItem } from "../types";

const popoverNavBtnProps: Partial<NavigationButtonProps> = {
    size: "sm",
    fontWeight: "normal",
    fontSize: "2xs",
    rounded: "none",
    justifyContent: "start",
    bg: "transparent",
    w: "full",
    color: "gray.600",
    iconProps: { color: "gray.700" },
};
export const CustomOption: React.ComponentType<OptionProps<NavigationItem, false>> = (innerProps) => {
    const data = innerProps.data as RoutableNavigationItem;

    return (
        <components.Option {...innerProps}>
            <NavigationButton
                showExternalLinkBtnLeftIcon
                {...popoverNavBtnProps}
                {...data}
                {...(data.buttonProps ?? {})}
                eventName={undefined}
                w="full"
                justifyContent="start"
            />
        </components.Option>
    );
};
