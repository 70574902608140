import { useUser } from "app/utils/react-helpers";
import { useRestrictFeature } from "./useRestrictFeature";

export const getWhatsAppURL = (phone: `91${number}`, customMsg = "I want to chat with the Support team") =>
    `https://wa.me/${phone}?text=${encodeURIComponent(customMsg)}`;

const useWhatsappSupportURL = (message = "I want to chat with the Support team") => {
    const user = useUser();
    const isPartnerUser = user.type === "partner";

    const {
        data: { pageAccess: accessPremiumSupport },
    } = useRestrictFeature("accounts_support", "restrict");

    if (isPartnerUser) return `https://wa.me/917825876244?text=${encodeURIComponent(message)}`;
    if (accessPremiumSupport) return `https://wa.me/917825810111?text=${encodeURIComponent(message)}`;
    return `https://wa.me/917825877797?text=${encodeURIComponent(message)}`;
};

export default useWhatsappSupportURL;
