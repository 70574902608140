import { Box, HStack, Spacer, useDisclosure, VStack } from "@chakra-ui/react";
import { useAccountEntitlementContext } from "app/AccountEntitlementContext/context";
import MultiAlert, { AlertBannerPropsWithVisibility } from "app/components/AlertBanner/MultiAlert";
import LoadingState from "app/components/LoadingState";
import { useGBMediaQuery } from "app/hooks/useGBMediaQuery";
import { useListenAccountEntitlement } from "app/hooks/useListenAccountEntitlement";
import useLocalStorage from "app/hooks/useLocalStorage";
import useNotifyShopifyVOneCustomers from "app/hooks/useNotifyShopifyV1Customers";
import { useShowTopAlertBanner } from "app/hooks/useShowTopAlertBanner";
import { useListenImportCompletion } from "app/screens/ImportContact/hooks/useListenImportCompletion";
import MobileAppDownloadView from "app/screens/Onboarding/Sandbox/MobileAppDownloadView";
import useCannyWidget from "app/screens/Widget/CannyWidget";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import MessageCreditsModal from "./components/MessageCreditsModal";
import NavigationFooter from "./components/NavigationFooter";
import NavigationHeader from "./components/NavigationHeader";
import NavigationItems from "./components/NavigationItems";
import NavigationItemWrapper from "./components/NavigationItemWrapper";
import NavigationWrapper from "./components/NavigationWrapper";
import QuickSearch from "./components/QuickSearch";
import useListenBotFlowImport from "./hooks/useListenBotFlowImport";
import SubNavigation from "./components/SubNavigation";
import { useNavigationList } from "./hooks/useNavigationList";
import { useNavigationShortCut } from "./hooks/useNavigationShortCut";
import { SubNavigationItemType } from "./types";
import { appLayoutStyles, validateNavigation } from "./utils";
import { useLocation } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);

interface MainComponentProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.ComponentType<any>;
}

const _MainComponent: React.FC<MainComponentProps> = (props) => {
    const { component: Component, ...rest } = props;
    return (
        <VStack w="full" maxW="full" overflow="auto" maxH="full" flex={1} align="stretch" sx={appLayoutStyles.content}>
            <Component {...rest} />
        </VStack>
    );
};

const MainComponent = React.memo(_MainComponent);

interface NavigationLocalStorageState {
    isPinned?: boolean;
}

type NavigationLayoutProps = MainComponentProps;

const NavigationLayout: React.FC<NavigationLayoutProps> = (props) => {
    const [alerts, setAlerts] = useState<AlertBannerPropsWithVisibility[]>([]);
    const [subNav, setSubNav] = useState<SubNavigationItemType | null>(null);
    const [navigationLocalState, setNavigationLocalState] = useLocalStorage<NavigationLocalStorageState | undefined>(
        "navigation-state",
        undefined,
        {}
    );

    const {
        isOpen: isExpanded,
        onToggle: onExpandToggle,
        onClose,
        onOpen,
    } = useDisclosure({
        defaultIsOpen: navigationLocalState?.isPinned,
    });

    const { isOpen: isPinned, onToggle: onTogglePin } = useDisclosure({
        defaultIsOpen: navigationLocalState?.isPinned,
    });

    const { navigationItems } = useNavigationList();
    const { multiAlertProps } = useShowTopAlertBanner();

    useCannyWidget({ isExpanded });
    useListenAccountEntitlement();
    useListenImportCompletion();
    useNotifyShopifyVOneCustomers();
    useListenBotFlowImport();

    const togglePin = React.useCallback(() => {
        setNavigationLocalState({
            ...navigationLocalState,
            isPinned: !isPinned,
        });
        onTogglePin();
    }, [isPinned, navigationLocalState, onTogglePin, setNavigationLocalState]);

    const { hideNavigation, hideSubNavigation } = React.useMemo(() => {
        return validateNavigation(navigationItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigationItems, location.pathname]);

    useEffect(() => {
        setAlerts(multiAlertProps);
    }, [multiAlertProps]);

    useNavigationShortCut({ onToggle: onExpandToggle });

    const setAlertsProp = React.useCallback((alerts: AlertBannerPropsWithVisibility[]) => {
        setAlerts(alerts);
    }, []);

    const setSubNavigation = React.useCallback((subNav: SubNavigationItemType | null) => {
        setSubNav(subNav);
    }, []);

    const collapseNavigation = React.useCallback(() => {
        if (isPinned) return;
        onClose();
    }, [isPinned, onClose]);

    const expandNavigation = React.useCallback(() => {
        if (isPinned) return;
        onOpen();
    }, [isPinned, onOpen]);

    const collapsibleProps = React.useMemo(() => {
        return {
            isExpanded,
            setSubNav: setSubNavigation,
            collapseNavigation,
        };
    }, [isExpanded, setSubNavigation, collapseNavigation]);

    return (
        <>
            <VStack sx={appLayoutStyles.container} justify="start" align="start" overflowY="hidden" spacing={0}>
                {multiAlertProps.length && (
                    <Box w="full">
                        <MultiAlert alerts={alerts} setAlerts={setAlertsProp} />
                    </Box>
                )}

                <HStack
                    w="full"
                    h={alerts.length > 0 ? "calc(100% - 32px)" : "full"}
                    minW="full"
                    justify="start"
                    align="initial"
                    overflow="hidden"
                    pos="relative"
                    spacing={0}
                >
                    <Box display={hideNavigation || isPinned ? "none" : "block"} w="48px" h="full" />

                    <NavigationWrapper
                        isExpanded={isExpanded}
                        hideNavigation={hideNavigation}
                        collapseNavigation={collapseNavigation}
                        onExpand={expandNavigation}
                        isPinned={isPinned}
                    >
                        <NavigationHeader {...collapsibleProps} togglePin={togglePin} isPinned={isPinned} />
                        <NavigationItemWrapper {...collapsibleProps}>
                            <NavigationItems {...collapsibleProps} />
                        </NavigationItemWrapper>
                        <Spacer />
                        <NavigationFooter {...collapsibleProps} />
                    </NavigationWrapper>
                    {subNav && !hideSubNavigation && <SubNavigation subNav={subNav} />}

                    <MainComponent {...props} />
                </HStack>
            </VStack>
            <MessageCreditsModal />
            <QuickSearch />
            <style>{`
                        .Canny_BadgeContainer .Canny_Badge {
                            position: absolute;
                            top: ${isExpanded ? "10px" : "4px"};
                            right: ${isExpanded ? "6px" : "10px"};
                            border-radius: 10px;
                            background-color: red;
                            padding: 4px;
                            border: 1px solid white;
                            background-color: #E53E3E;
                        }
                    `}</style>
        </>
    );
};

export type NavigationLayoutWrapperProps = NavigationLayoutProps;

const NavigationLayoutWrapper: React.FC<NavigationLayoutWrapperProps> = (props) => {
    const { component: Component } = props;
    const { isMobile } = useGBMediaQuery();
    const { isLoading } = useAccountEntitlementContext();
    const { pathname } = useLocation();

    if (isMobile) {
        if (pathname === "/mobile/instructions/connect-number" || pathname === "/mobile/instructions/upgrade-plan") {
            return <Component />;
        }

        return <MobileAppDownloadView />;
    }

    if (isLoading)
        return (
            <VStack w="full" h="100vh" bg="gray.50">
                <LoadingState isCentered title="Loading..." />
            </VStack>
        );

    return <NavigationLayout {...props} />;
};

export default NavigationLayoutWrapper;
