import { VStack, Text, Box, Grid, HStack, GridItem, Image, Skeleton } from "@chakra-ui/react";
import React from "react";

interface OverviewDetails {
    icon: string;
    text: string;
}

interface OverviewTabProps {
    details: OverviewDetails[];
    description: string;
    linkButton?: React.JSX.Element
}

export const Overview: React.FC<OverviewTabProps> = (props) => {
    const { details, description, linkButton } = props;
    return (
        <>
            <VStack w="full" alignItems="flex-start">
                <Text color="gray.500" fontSize="sm" fontStyle="normal">
                    {description}
                    {linkButton && linkButton}
                </Text>
            </VStack>
            { details.length ? 
            <Box paddingTop={6}>
                <Text paddingBottom={"25px"} textAlign={"left"} fontSize="18px" fontWeight="semibold" color="black">
                    What can I do?
                </Text>
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    {details.map((v, idx) => {
                        return (
                            <GridItem key={idx}>
                                <HStack>
                                    <Image src={v.icon} boxSize={8} fallback={<Skeleton boxSize={8} />} />
                                    <Text fontWeight="normal" fontSize="sm" color="gray.500">
                                        {v.text}
                                    </Text>
                                </HStack>
                            </GridItem>
                        );
                    })}
                </Grid>
            </Box> : null }
        </>
    );
};
