import { StylesObject } from "app/types";
import { StylesConfig } from "react-select";

export const IntegrationTabStyles: StylesObject = {
    tabList: {
        borderBottom: "1px solid",
        borderColor: "gray.100",
    },
    tabSelected: {
        color: "blue.500",
        fontSize: "md",
        fontWeight: "medium",
        borderBottom: "2px solid",
        borderColor: "brand.500",
    },
    tabPanel: {
        pb: 4,
        pt: 4,
        height: "full",
        width: "full",
        flex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
    },
};

export const integrationSelectStyle: StylesConfig<any, false> = {
    control: (base) => ({
        ...base,
        width: "200px",
        minHeight: "34px",
        height: "34px",
        borderRadius: "6px",
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none",
    }),
    container: (base) => ({
        ...base,
        height: "34px",
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: "13px",
        color: "#1A202C",
        paddingBottom: "4px",
    }),
    option: (base) => ({
        ...base,
        fontSize: "14px",
        padding: "5px",
    }),
    menuList: (base) => ({
        ...base,
        width: "200px",
        paddingLef: "10px",
    }),
    menu: (base) => ({
        ...base,
        width: "200px",
        borderRadius: "10px",
    }),
    singleValue: (base) => ({
        ...base,
        fontSize: "14px",
        paddingBottom: "12px",
    }),
    indicatorsContainer: (base) => ({
        ...base,
        paddingBottom: "6px",
    }),
};

export const integrationTabStyles: StylesObject = {
    tabList: {
        borderBottom: "1px solid",
        borderColor: "gray.100",
    },
    tabSelected: {
        color: "blue.500",
        fontSize: "md",
        fontWeight: "medium",
        borderBottom: "2px solid",
        borderColor: "brand.500",
    },
    tabPanel: {
        pb: 4,
        pt: 4,
        height: "full",
        width: "full",
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
};
