import React from "react";
import { NavigationItem } from "../types";
import { useGetUserNavItems } from "./useGetUserNavItems";
import { useNavigationList } from "./useNavigationList";

const useFlattenedNavigationItem = () => {
    const { navigationItems } = useNavigationList();
    const { getUserNavItems } = useGetUserNavItems();

    const flattendNavigationItems: NavigationItem[] = React.useMemo(() => {
        return Object.values(navigationItems).reduce((flattenedOptionAcc, item) => {
            const userNavItems = getUserNavItems(item);

            if (!userNavItems) return flattenedOptionAcc;

            if (item.type == "internal-link") {
                return [...flattenedOptionAcc, userNavItems];
            }
            if (userNavItems.type === "sub-navigation") {
                const subNavGroupedOptions = userNavItems.subNavigation.children.reduce((acc, child) => {
                    const searchableChildren = (child.children as NavigationItem[]).filter(
                        (ch) => ch.type === "internal-link" || ch.type === "external-link" || ch.type === "click-action"
                    );

                    const childrenWithGroupNameAsSearchKey = searchableChildren.map((searchableChild) => {
                        return {
                            ...searchableChild,
                            quickSearchTerms: [
                                ...(searchableChild.quickSearchTerms ?? []),
                                (child.title ?? "")?.toLocaleLowerCase(),
                            ],
                        };
                    }) as NavigationItem[];

                    return [...acc, ...childrenWithGroupNameAsSearchKey];
                }, [] as NavigationItem[]);
                return [...flattenedOptionAcc, ...subNavGroupedOptions];
            }
            return flattenedOptionAcc;
        }, [] as NavigationItem[]);
    }, [getUserNavItems, navigationItems]);

    return { flattendNavigationItems };
};

export default useFlattenedNavigationItem;
