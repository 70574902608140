import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
    Button,
    HStack,
    Icon,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Skeleton,
    Spacer,
    Switch,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import AlertDialog from "app/components/AlertDialog/AlertDialogNoTrigger";
import GallaboxLoadingState from "app/components/GBLoadingState";
import GoBackIcon from "app/components/GoBackIcon";
import { useDeleteCalendlyWorkFlow, useUpdateCalendlyWorkFlow } from "app/screens/Integration/fetchHooks/calendly";
import { CalendlyWorkflow, Integration, Workflow } from "app/screens/Integration/types/integration";
import { useQueryParam } from "app/utils/history";
import { useAccountId } from "app/utils/react-helpers";
import Calendly from "assets/Integration/Calendly.svg";
import { groupBy, startCase } from "lodash";
import React, { FC } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useHistory, useParams } from "react-router-dom";
import { durationOptions } from "./calendlyUtils";

interface CalendlyWorkflowListsProps {
    data: Integration | undefined;
    isLoading: boolean;
}

const CalendlyWorkflowLists: FC<CalendlyWorkflowListsProps> = ({ data, isLoading }) => {
    const param = useQueryParam();
    const selectedUseCase = param.get("usecase") as CalendlyWorkflow["useCase"];
    const groupedWorkflows = groupBy(data?.calendly?.workflows, "calendly.useCase");
    const workFlowsList = groupedWorkflows[selectedUseCase] ?? [];

    const history = useHistory();

    const handleBack = () => {
        history.push({
            pathname: history.location.pathname,
            search: "?tab=2",
        });
    };

    const handleWorkflow = () => {
        history.push({
            pathname: history.location.pathname,
            search: `?tab=2&usecase=${selectedUseCase}&state=new`,
        });
    };

    if (isLoading) return <GallaboxLoadingState />;

    return (
        <VStack width="full" height="full" spacing={6}>
            <HStack width="full">
                <IconButton
                    aria-label="back-btn"
                    size="sm"
                    bgColor="unset"
                    icon={<GoBackIcon />}
                    onClick={handleBack}
                />
                <Text fontSize="lg" fontWeight="bold">
                    {startCase(selectedUseCase)}
                </Text>
                <Spacer />
                <Button leftIcon={<AddIcon />} size="sm" colorScheme="brand" onClick={handleWorkflow}>
                    Create New WorkFlow
                </Button>
            </HStack>
            {workFlowsList?.map((c) => {
                return <WorkflowComponent key={c.id} data={c} />;
            })}
        </VStack>
    );
};

export default CalendlyWorkflowLists;

interface WorkflowComponentProps {
    data: Workflow;
}

const WorkflowComponent: FC<WorkflowComponentProps> = ({ data }) => {
    const { integrationId } = useParams<{ integrationId: string }>();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const history = useHistory();
    const accountId = useAccountId();
    const { id: workflowId = "", isActive, calendly } = data;
    const { mutate, isLoading: updateLoading } = useUpdateCalendlyWorkFlow({
        accountId: accountId as string,
        integrationId: integrationId as string,
    });

    const { mutate: deleteWorkFlow } = useDeleteCalendlyWorkFlow({ accountId, integrationId, workflowId });

    const handleEditWorkFlow = (value: Workflow) => {
        const { id, calendly } = value;
        history.push({
            pathname: history.location.pathname,
            search: `?tab=2&usecase=${calendly?.useCase}&workflowId=${id}`,
        });
    };

    const handleSwitch = (checked: boolean, c: Workflow) => {
        mutate({
            ...c,
            isActive: checked,
        });
    };

    const handleDelete = () => {
        deleteWorkFlow();
    };
    return (
        <VStack align="flex-start" w="full" border="1px" borderColor="gray.200" borderRadius="xl" p="5">
            <HStack w="full">
                <HStack>
                    <Image src={Calendly} boxSize={12} fallback={<Skeleton boxSize={12} />} />
                    <VStack align="flex-start" spacing={2}>
                        <Text fontSize="16px" color="black" fontWeight="semibold">
                            {`${calendly?.eventTypeUri?.[0]?.label ?? ""} - ${calendly?.useCase} ${
                                durationOptions.find((durationOption) => calendly?.duration === durationOption.value)
                                    ?.label ?? ""
                            }`}
                        </Text>
                    </VStack>
                </HStack>
                <Spacer />
                <HStack>
                    <Switch
                        colorScheme="green"
                        id="activeCase"
                        defaultChecked={isActive as boolean}
                        onChange={(e) => {
                            handleSwitch(e.target.checked, data);
                        }}
                        position="relative"
                    />
                    <IconButton
                        aria-label="edit-workflow"
                        onClick={() => handleEditWorkFlow(data)}
                        bgColor="transparent"
                        icon={<Icon as={AiOutlineEdit} boxSize={4} />}
                    />
                    <Menu placement="bottom">
                        <MenuButton
                            as={IconButton}
                            bgColor="transparent"
                            aria-label="delete-workflow"
                            icon={<BsThreeDotsVertical />}
                        />
                        <MenuList>
                            <MenuItem onClick={onOpen} icon={<DeleteIcon color="red.500" />}>
                                Delete
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </HStack>
            </HStack>
            <AlertDialog
                title={"Delete Workflow ?"}
                body={"Are you sure want to Delete ?"}
                onOk={handleDelete}
                primaryButtonText="Delete"
                secondaryButtonText="Close"
                primaryButtonProps={{ colorScheme: "red" }}
                isOpen={isOpen}
                onClose={onClose}
            />
        </VStack>
    );
};
