import { Button, HStack, Image, Skeleton, Text, VStack } from "@chakra-ui/react";
import GBLoadingCentered from "app/components/GBLoadingCentered";
import { useShopifyRegister } from "app/screens/Integration/fetchHooks/shopify";
import { Integration } from "app/screens/Integration/types/integration";
import { CustomTriggersData, IShopifyIntegration, IShopifyTriggers } from "app/screens/Integration/types/shopify";
import { useQueryParam } from "app/utils/history";
import { useAccountId } from "app/utils/react-helpers";
import CustomIcon from "assets/custom.png";
import OrderIcon from "assets/ordericon.png";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import WorkFlowInformationChips from "app/screens/Integration/components/WorkFlowInformationChip";
import { WorkFlowSwitch } from "app/screens/Integration/components/WorkFlowSwitch";
import CreateWorkFlow from "./CreateWorkFlow";
import { shopifyWorkFlowList } from "./utils";
import { handleNetworkError } from "app/utils/fetchUtils";

interface WorkFlowInformationProps {
    data: Integration | undefined;
    isLoading: boolean;
}

export const getWorkFlow = (value: string | undefined): string => {
    if (value === undefined) return "Create WorkFlow";
    let res;
    if (value === "orders/create") {
        res = "Order Create";
    } else if (value === "orders/fulfilled") {
        res = "Order Fulfilled";
    } else if (value === "checkouts/create") {
        res = "Cart Abandoned";
    } else if (value === "fulfillments/update") {
        res = "Fulfillment Update";
    } else {
        res = "Create WorkFlow";
    }
    return res;
};
type WorkFlowInformationType = IShopifyTriggers & CustomTriggersData;

export const WorkFlowInformation: React.FC<WorkFlowInformationProps> = (props) => {
    const { data, isLoading } = props;
    const [index, setIndex] = React.useState<number | null>(null);
    const [showCreateWorkFlow, setShowCreateWorkFlow] = React.useState<boolean>(false);
    const accountId = useAccountId();
    const integrationId = data?.id ?? "";
    const history = useHistory();
    const query = useQueryParam();
    const topic = query.get("topic") ?? undefined;
    const workflowId = query.get("workflowId") ?? undefined;
    const isCustom = query.get("isCustom") === "true" ? true : false;
    const { mutateAsync: shopifyRegister, isLoading: shopifyLoading } = useShopifyRegister({
        accountId,
        integrationId,
        workflowId: workflowId,
        showSuccessMessage: false,
        isCustom,
        isNewWorkFlow: showCreateWorkFlow,
    });
    const modifiedWorkFlowList = useMemo(() => {
        const triggersDetails = data?.shopify?.triggers ?? [];
        return shopifyWorkFlowList.map((item1) => {
            const matchIndex = triggersDetails.findIndex((item2) => {
                return item2.name === item1.title;
            });
            return matchIndex !== -1 ? { ...item1, ...triggersDetails[matchIndex] } : item1;
        });
    }, [data]);

    const isCreateWorkFlowDisabled = (data?.shopify?.customTriggers?.length ?? 0) >= 3;
    const workFlowList = useMemo(() => {
        const customTriggerDetails = data?.shopify?.customTriggers?.map((a) => ({ ...a, isCustom: true })) ?? [];
        return [...modifiedWorkFlowList, ...customTriggerDetails] as unknown as WorkFlowInformationType[];
    }, [data?.shopify?.customTriggers, modifiedWorkFlowList]);

    React.useEffect(() => {
        if (topic || workflowId) {
            setShowCreateWorkFlow(true);
        } else {
            setShowCreateWorkFlow(false);
        }
    }, [workflowId, topic]);

    const handleCreateWorkFlow = () => {
        history.push(`/integration/shopify/edit/${integrationId}?topic=customs&isCustom=true`);
    };

    const handlePush = (value: WorkFlowInformationType) => {
        if (value?.id) {
            history.push({
                pathname: history.location.pathname,
                search: `?workflowId=${value?.id}&isCustom=${Boolean(value?.isCustom)}&topic=${value.topic}&title=${
                    value.title ?? `Custom-${getWorkFlow(value?.topic)}`
                }`,
            });
        } else {
            history.push({
                pathname: history.location.pathname,
                search: `?topic=${value.topic}&title=${value.title}&isCustom=${Boolean(value?.isCustom)}`,
            });
        }
    };

    if (isLoading) return <GBLoadingCentered title="Loading..." spinnerSize={30} />;

    if (showCreateWorkFlow) {
        return (
            <CreateWorkFlow
                topic={topic}
                integrationData={
                    {
                        ...data,
                        shopify: {
                            ...data?.shopify,
                            triggers: modifiedWorkFlowList as IShopifyTriggers[],
                        } as IShopifyIntegration,
                    } as Integration
                }
                workflowId={workflowId}
                isCustom={isCustom}
            />
        );
    }

    return (
        <VStack spacing="5">
            {workFlowList.map((item, idx) => {
                return (
                    <VStack
                        key={`${item.templateId}-${idx}`}
                        align="flex-start"
                        spacing={8}
                        w="full"
                        border="1px"
                        borderColor="gray.200"
                        borderRadius="xl"
                        p="5"
                    >
                        <HStack justify="space-between" w="full">
                            <HStack>
                                <Image
                                    src={item?.isCustom ? CustomIcon : OrderIcon}
                                    boxSize={12}
                                    fallback={<Skeleton boxSize={12} />}
                                />
                                <VStack align="flex-start" spacing={0}>
                                    <Text fontSize="md" color="black" fontWeight="semibold">
                                        {item.title ?? `Custom-${getWorkFlow(item?.topic)}`}
                                    </Text>
                                    <Text fontSize="sm" color="gray.500">
                                        {item.description ?? <WorkFlowInformationChips condition={item.condition} />}
                                    </Text>
                                </VStack>
                            </HStack>
                            <WorkFlowSwitch
                                showSwitch={item.templateId}
                                isActive={Boolean(item.isActive)}
                                switchLoading={idx === index && shopifyLoading}
                                onActive={(isActive) => {
                                    setIndex(idx);
                                    shopifyRegister({
                                        isActive,
                                        topic: item.topic,
                                        isCustom: item.isCustom,
                                        workflowId: item.id,

                                        name: item.title ?? `Custom-${getWorkFlow(item?.topic)}`,
                                    }).catch(handleNetworkError);
                                }}
                                onClick={() => handlePush(item)}
                            />
                        </HStack>
                    </VStack>
                );
            })}
            <VStack spacing={1}>
                <Button
                    size="sm"
                    borderRadius="6px"
                    isDisabled={isCreateWorkFlowDisabled}
                    onClick={handleCreateWorkFlow}
                    colorScheme="brand"
                >
                    Create New WorkFlow
                </Button>
                <Text fontSize="xs" color="gray.500">
                    Max. 3 Workflows allowed
                </Text>
            </VStack>
        </VStack>
    );
};
