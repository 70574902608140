import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { callFnsInSequence } from "app/utils/common";
import React from "react";
import { NavigationItem } from "../types";
import { PopUpNavigationSelect } from "./PopUpNavigationSelect";

export interface IPopUpNavigator {
    isOpen: boolean;
    onClose: () => void;
    placeholderValue?: string;
    options?: NavigationItem[];
}

export const getDefaultValue = () => {
    return {
        placeholderValue: t`Where would you like to go?`,
    };
};

export const PopUpNavigator: React.FC<IPopUpNavigator> = (props) => {
    const { isOpen, onClose, placeholderValue, options } = props;

    if (!options) {
        console.log("Please provide an options or load options(ASYNC)");
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={callFnsInSequence(onClose, () => trackEvent({ event: "CTRL Escape Clicked" }))}
            size={"xl"}
        >
            <ModalOverlay backgroundColor={"blackAlpha.700"} />
            <ModalContent p={4}>
                <PopUpNavigationSelect onClose={onClose} placeholderValue={placeholderValue} options={options ?? []} />
            </ModalContent>
        </Modal>
    );
};
