import { Breadcrumb, BreadcrumbItem, BreadcrumbItemProps, BreadcrumbLink, BreadcrumbLinkProps } from "@chakra-ui/react";
import React from "react";
import { HiOutlineChevronRight } from "react-icons/hi";

interface BreadCrumbPath {
    label: string;
    breadCrumbLinkProps: BreadcrumbLinkProps;
    breadCrumbItemProps: BreadcrumbItemProps;
}
interface AppBreadCrumbsProps {
    paths: BreadCrumbPath[];
}

const AppBreadCrumbs = ({ paths }: AppBreadCrumbsProps) => {
    return (
        <Breadcrumb p={5} fontSize="sm" separator={<HiOutlineChevronRight color="gray.500" />} w="full">
            {paths.map((eachItem) => {
                return (
                    <BreadcrumbItem {...eachItem.breadCrumbItemProps} key={eachItem.label}>
                        <BreadcrumbLink _hover={{ textDecoration: "none" }} {...eachItem.breadCrumbLinkProps}>
                            {eachItem.label}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    );
};
export default AppBreadCrumbs;
