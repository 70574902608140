//connect stripe integration

import { useToast } from "@chakra-ui/react";
import { QueryKey } from "app/types";
import { Integration, Workflow } from "app/screens/Integration/types/integration";
import { ConnectStripe, ConnectStripeIntegrationInput, UpdateStripeConfigInput } from "app/screens/Integration/types/stripe";
import { postJSON, patchJSON, deleteJSON } from "app/utils/fetchUtils";
import { UseMutationOptions, UseMutationResult, MutationFunction, useMutation, useQueryClient } from "react-query";

interface ConnectStripeIntegrationProps
    extends UseMutationOptions<ConnectStripe, Error, ConnectStripeIntegrationInput> {
    accountId: string;
    onSuccess?: (data: ConnectStripe) => void;
}

type ConnectStripeIntegrationResponse = UseMutationResult<ConnectStripe, Error, ConnectStripeIntegrationInput>;

export const useConnectStripeIntegration = (props: ConnectStripeIntegrationProps): ConnectStripeIntegrationResponse => {
    const { accountId, onSuccess, ...options } = props;
    const toast = useToast();

    const connectStripe: MutationFunction<ConnectStripe, ConnectStripeIntegrationInput> = (
        data: ConnectStripeIntegrationInput
    ) => {
        return postJSON(`api/accounts/${accountId}/integrations/stripe/create`, data);
    };

    const mutationResult = useMutation<ConnectStripe, Error, ConnectStripeIntegrationInput>(connectStripe, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while Connecting Integration",
            });
        },
        onSuccess: (data) => {
            toast({
                title: "Success",
                status: "success",
                description: "Integration Created",
            });
            onSuccess?.(data);
        },
        ...options,
    });

    return mutationResult;
};

//update Stripe configuration

interface UpdateStripeConfigurationProps extends UseMutationOptions<Integration, Error, UpdateStripeConfigInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

type UpdateStripeResponse = UseMutationResult<Integration, Error, UpdateStripeConfigInput>;

export const useUpdateStripeConfiguration = (props: UpdateStripeConfigurationProps): UpdateStripeResponse => {
    const { integrationId, accountId, onSuccess, ...options } = props;
    const queryClient = useQueryClient();
    const toast = useToast();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateStripeConfig: MutationFunction<Integration, UpdateStripeConfigInput> = (
        data: UpdateStripeConfigInput
    ) => {
        return patchJSON(`api/accounts/${accountId}/integrations/${integrationId}/stripe?populatePaths=channel`, data);
    };

    const response = useMutation<Integration, Error, UpdateStripeConfigInput>(updateStripeConfig, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while updating  Integration",
            });
        },
        onSuccess: (data) => {
            queryClient.setQueryData<Integration>(queryKey, data);
            toast({
                title: "Integration  Updated",
                status: "success",
                description: "Integration Updated successfully!",
            });
            onSuccess?.();
        },

        ...options,
    });

    return response;
};

interface UseDeleteStripeIntegrationProps extends UseMutationOptions<Integration, Error, void> {
    accountId: string;
    integrationId: string;
}

export const useDeleteStripeIntegration = (props: UseDeleteStripeIntegrationProps) => {
    const { accountId, integrationId, ...rest } = props;
    const toast = useToast();

    const deleteStripeIntegration: MutationFunction<Integration, void> = () => {
        return deleteJSON(`/api/accounts/${accountId}/integrations/${integrationId}/stripe`);
    };

    const deleteResult = useMutation<Integration, Error, void>(deleteStripeIntegration, {
        onError: (err) => {
            toast({
                status: "error",
                title: "Error",
                description: err.message,
            });
        },
        onSuccess: () => {
            toast({
                status: "success",
                title: "Deleted Successfully",
                description: "Stripe integration Deleted Successfully",
            });
        },
        ...rest,
    });
    return deleteResult;
};

type CreateWorkFlowInput = Omit<Workflow, "id" | "recipient">;


interface UseCreateStripeWorkflow extends UseMutationOptions<Workflow[], Error, CreateWorkFlowInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

type CreateStripeWorkflowResponse = UseMutationResult<Workflow[], Error, CreateWorkFlowInput>;

export const useCreateStripeWorkflow = (props: UseCreateStripeWorkflow): CreateStripeWorkflowResponse => {
    const { accountId, integrationId, onSuccess, ...rest } = props;
    const toast = useToast();

    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.IntegrationWorkFlow, { accountId, integrationId }];

    const createStripeWorkflow: MutationFunction<Workflow[], CreateWorkFlowInput> = (data: CreateWorkFlowInput) => {
        return postJSON(`/api/accounts/${accountId}/integrations/${integrationId}/stripe/workflow`, data);
    };

    const responseCreateWorkflow = useMutation<Workflow[], Error, CreateWorkFlowInput>(createStripeWorkflow, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            toast({
                status: "success",
                description: "Workflow created successfully!",
            });
            queryClient.invalidateQueries(queryKey);
            onSuccess?.();
        },

        ...rest,
    });

    return responseCreateWorkflow;
};

type UpdateStripeWorkFlowInput = Partial<
    Pick<Workflow, "isActive" | "templateId" | "templateValues" | "workflowActions" | "stripe">
> & {
    currentWorkflowId?: string;
};

interface UseUpdateStripeWorkflow extends UseMutationOptions<Workflow[], Error, UpdateStripeWorkFlowInput> {
    accountId: string;
    integrationId: string;
    workflowId: string;
    onSuccess?: () => void;
}

type UpdateStripeWorkflowResponse = UseMutationResult<Workflow[], Error, UpdateStripeWorkFlowInput>;

export const useUpdateStripeWorkflow = (props: UseUpdateStripeWorkflow): UpdateStripeWorkflowResponse => {
    const { accountId, integrationId, workflowId, onSuccess, ...rest } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.IntegrationWorkFlow, { accountId, integrationId }];

    const createStripeWorkflow: MutationFunction<Workflow[], UpdateStripeWorkFlowInput> = (
        data: UpdateStripeWorkFlowInput
    ) => {
        const { currentWorkflowId, ...rest } = data;
        return patchJSON(
            `/api/accounts/${accountId}/integrations/${integrationId}/stripe/workflow/${
                currentWorkflowId || workflowId
            }`,
            { ...rest }
        );
    };

    const responseUpdateWorkflow = useMutation<Workflow[], Error, UpdateStripeWorkFlowInput>(createStripeWorkflow, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            toast({
                status: "success",
                description: "Workflow update successfully!",
            });
            queryClient.invalidateQueries(queryKey);
            onSuccess?.();
        },

        ...rest,
    });

    return responseUpdateWorkflow;
};
